import styled, { css } from "styled-components";
import { DataFailed, Empty, LockContent, StopWatch } from "../../illustrations";
import media from "../../theme/media";
import Text from "../Text/Text";

type PlaceholderCardVariant = "border" | "default" | "flat";

type PlaceholderCardSize = "sm" | "md";

const variantStyles: Partial<
  Record<PlaceholderCardVariant, ReturnType<typeof css>>
> = {
  // Ideally there would only be one of either border or flat. However in some
  // places like map drawers, the cards have no border. In other places, like
  // the social dashboard and social feed, cards do have a border but no shadow
  border: css`
    padding: 3rem 1rem;
    background: ${(p) => p.theme.colors.neutrals.surface};
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
    border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

    @media ${media.lg} {
      padding: 3rem 2.5rem;
    }
  `,
  flat: css`
    padding: 2rem 1rem;

    @media ${media.lg} {
      padding: 2rem 2.5rem;
    }
  `,
  default: css`
    padding: 3rem 1rem;
    background: ${(p) => p.theme.colors.neutrals.surface};
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
    border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
    box-shadow: ${(p) => p.theme.boxShadows.cardWeak};

    @media ${media.lg} {
      padding: 3rem 2.5rem;
    }
  `,
};

interface StyledPlaceholderCardProps {
  center?: boolean;
  flex?: boolean;
  size?: PlaceholderCardSize;
  variant: PlaceholderCardVariant;
}

const StyledPlaceholderCard = styled.div<StyledPlaceholderCardProps>`
  ${(p) =>
    p.flex &&
    css`
      flex: 1;
    `}
  ${(p) =>
    p.center &&
    css`
      align-self: center;
    `}
  display: grid;
  padding: 3rem 1rem;
  place-content: center;
  text-align: center;
  gap: 1rem;
  ${(p) => variantStyles[p.variant]}
`;

const StyledIllustration = styled.div`
  margin: 0 auto;
`;

const StyledContent = styled.div`
  display: grid;
  text-align: center;
`;

type PlaceholderCardStatus = "empty" | "error" | "inProgress" | "lock";

const illustrations: Record<PlaceholderCardStatus, ReactSVGComponent> = {
  empty: Empty,
  error: DataFailed,
  inProgress: StopWatch,
  lock: LockContent,
};

export interface PlaceholderCardProps {
  center?: boolean;
  cta?: React.ReactNode;
  flex?: boolean;
  "data-testid"?: string;
  size?: PlaceholderCardSize;
  status: PlaceholderCardStatus;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  variant?: PlaceholderCardVariant;
}

const PlaceholderCard = ({
  center,
  cta,
  "data-testid": dataTestId,
  flex,
  subtitle,
  title,
  size,
  status,
  variant = "default",
}: PlaceholderCardProps) => {
  const Illustration = illustrations[status];

  return (
    <StyledPlaceholderCard
      center={center}
      data-testid={dataTestId}
      flex={flex}
      variant={variant}
    >
      <StyledIllustration>
        <Illustration
          data-testid={dataTestId && `${dataTestId}-${status}`}
          height={128}
        />
      </StyledIllustration>
      <StyledContent>
        <Text size={size === "sm" ? "subtitleMd" : "subtitleLg"}>{title}</Text>
        {subtitle && (
          <Text size={size === "sm" ? "bodyDefault" : "bodyLg"} variant="weak">
            {subtitle}
          </Text>
        )}
      </StyledContent>
      {cta && (
        <Text size={size === "sm" ? "bodyDefault" : "bodyLg"} variant="weak">
          <div>{cta}</div>
        </Text>
      )}
    </StyledPlaceholderCard>
  );
};

export default PlaceholderCard;
