import range from "lodash/range";
import styled from "styled-components";
import Skeleton from "../Skeleton/Skeleton";
import {
  StyledDataTable,
  StyledHeader,
  StyledTD,
  StyledTH,
  StyledTR,
  type TableVariant,
} from "./styles";

const StyledSkeletonDataTable = styled(StyledDataTable)`
  pointer-events: none;
  line-height: 1;
`;

const StyledSkeletonHeader = styled(StyledHeader)`
  display: block;
  font-size: 1rem;
`;

const StyledSkeletonCell = styled(Skeleton)`
  margin: 0.5rem 0;
  font-size: 1rem;
`;

interface SkeletonDataTableProps {
  "data-testid"?: string;
  perPage?: number;
  variant?: TableVariant;
}

const SkeletonDataTable = ({
  "data-testid": dataTestId,
  perPage = 10,
  variant = "primary",
}: SkeletonDataTableProps) => {
  return (
    <StyledSkeletonDataTable data-testid={dataTestId} variant={variant}>
      <StyledTR variant={variant}>
        <StyledTH variant={variant}>
          <StyledSkeletonHeader>
            <Skeleton />
          </StyledSkeletonHeader>
        </StyledTH>
      </StyledTR>
      <div>
        {range(perPage).map((index) => (
          <StyledTR variant={variant} key={index}>
            <StyledTD variant={variant}>
              <StyledSkeletonCell key={index} flex />
            </StyledTD>
          </StyledTR>
        ))}
      </div>
    </StyledSkeletonDataTable>
  );
};

export default SkeletonDataTable;
