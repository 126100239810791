import React, {
  forwardRef,
  useState,
  type ForwardRefRenderFunction,
} from "react";
import styled from "styled-components";
import { Add } from "../../icons";
import RemovableChip from "../Chip/RemovableChip";
import IconButton from "../IconButton/IconButton";
import type { ValidationStatus } from "../InputContainer/InputContainer";
import TextButton from "../TextButton/TextButton";
import TextInput from "../TextInput/TextInput";

const StyledChipInput = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledInput = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 0.5rem;
`;

const StyledChips = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

const StyledClear = styled.div`
  padding-left: 0.25rem;
`;

interface ChipInputProps {
  clearLabel: string;
  "data-testid"?: string;
  disabled?: boolean;
  id: string;
  onChange: (value: string[]) => void;
  placeholder?: string;
  size?: "sm" | "md";
  value: string[];
  validationStatus?: ValidationStatus;
}

const ChipInput: ForwardRefRenderFunction<HTMLInputElement, ChipInputProps> = (
  {
    clearLabel,
    "data-testid": dataTestId,
    disabled,
    id,
    onChange,
    placeholder,
    size,
    value,
    validationStatus,
    ...htmlProps
  }: ChipInputProps,
  ref,
) => {
  const [inputValue, setInputValue] = useState<string>("");

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const addChip = () => {
    const newInputValue = inputValue.trim();
    if (newInputValue === "") return;

    onChange([...value, newInputValue]);
    setInputValue("");
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addChip();
    }
  };

  const onAddClick = () => {
    addChip();
  };

  const onRemove = (removeChip: string) => () => {
    onChange(value.filter((chip) => chip !== removeChip));
  };

  return (
    <StyledChipInput data-testid={dataTestId}>
      <StyledInput>
        <TextInput
          data-testid={dataTestId && `${dataTestId}-text-input`}
          disabled={disabled}
          id={id}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={ref}
          size={size}
          value={inputValue}
          validationStatus={validationStatus}
          {...htmlProps}
        />
        <IconButton
          data-testid={dataTestId && `${dataTestId}-button`}
          icon={Add}
          label="Add"
          onClick={onAddClick}
          size={size}
          variant="secondary"
          disabled={disabled || !inputValue}
        />
      </StyledInput>
      {value.length > 0 && (
        <StyledChips>
          {value.map((chip) => {
            return (
              <RemovableChip
                data-testid={dataTestId && `${dataTestId}-chip`}
                key={chip}
                onRemove={onRemove(chip)}
                disabled={disabled}
              >
                {chip}
              </RemovableChip>
            );
          })}
          <StyledClear>
            <TextButton
              data-testid={dataTestId && `${dataTestId}-clear-button`}
              type="button"
              onClick={() => onChange([])}
              disabled={disabled}
            >
              {clearLabel}
            </TextButton>
          </StyledClear>
        </StyledChips>
      )}
    </StyledChipInput>
  );
};

export default forwardRef(ChipInput);
