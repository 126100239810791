import { mergeRefs } from "@kablamo/kerosene-ui";
import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import { useDropzone, type Accept } from "react-dropzone";
import styled, { css } from "styled-components";
import { weakSurfaceOverrides } from "../../theme/colorsLight";
import Button from "../Button/Button";
import {
  commonInputStyles,
  getInputSizeProperties,
  inputValidationStyles,
  type ValidationStatus,
} from "../InputContainer/InputContainer";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";

const StyledLabel = styled.div`
  flex: 1;
  ${(p) => p.theme.typography.variants.bodyDefault}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledButton = styled.div`
  ${weakSurfaceOverrides}
`;

const StyledFileInput = styled.div`
  overflow: hidden;
  ${getInputSizeProperties({
    gap: 4,
    padding: [3, 12],
    svgSize: 16,
    typographySize: "bodyDefault",
  })}
`;

interface StyledInputProps {
  isDragActive: boolean;
  validationStatus?: ValidationStatus;
}

const StyledInput = styled.label<StyledInputProps>`
  ${commonInputStyles}
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0.1875rem;
  gap: 0.25rem;

  &:focus-within,
  &:focus-visible {
    outline: none;
  }

  &:is([data-empty="true"]) {
    ${StyledLabel} {
      color: ${(p) => p.theme.colors.neutrals.textPlaceholder};
    }
  }

  ${(p) =>
    p.isDragActive &&
    css`
      background-color: ${p.theme.colors.neutrals.backgroundWeak};
    `}

  ${(p) => p.validationStatus && inputValidationStyles[p.validationStatus]}
`;

interface FileInputProps {
  accept?: Accept | undefined;
  "data-testid"?: string;
  disabled?: boolean;
  id?: string;
  isLoading?: boolean;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (files: File[]) => void;
  placeholder?: string;
  validationStatus?: ValidationStatus;
  value?: File[];
}

const FileInput: ForwardRefRenderFunction<HTMLInputElement, FileInputProps> = (
  {
    accept,
    "data-testid": dataTestId,
    disabled,
    id,
    isLoading,
    name,
    placeholder = "No file",
    onBlur,
    onChange,
    validationStatus,
    value = [],
  }: FileInputProps,
  ref,
) => {
  const { getRootProps, getInputProps, isDragActive, open, inputRef } =
    useDropzone({
      accept,
      disabled,
      noClick: true,
      noKeyboard: true,
      onDrop: onChange,
    });
  const setRef = mergeRefs(ref, inputRef);

  const inputProps = getInputProps();

  const clear = () => {
    onChange?.([]);
  };

  const hasValue = !!value.length;

  return (
    <StyledFileInput>
      <StyledInput
        {...getRootProps()}
        data-disabled={disabled}
        data-empty={!value.length}
        isDragActive={isDragActive}
        validationStatus={validationStatus}
        data-testid={dataTestId}
      >
        <StyledLabel data-testid={dataTestId && `${dataTestId}-label`}>
          {value.length ? (
            <>{value.map((file) => file.name).join(", ")}</>
          ) : (
            placeholder
          )}
        </StyledLabel>
        <StyledHiddenInput
          {...getInputProps({ disabled })}
          id={id}
          name={name}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            inputProps.onBlur?.(event);
            onBlur?.(event);
          }}
          ref={setRef}
          data-testid={dataTestId && `${dataTestId}-input`}
        />
        <StyledButton>
          <Button
            disabled={disabled}
            isLoading={isLoading}
            onClick={hasValue ? clear : open}
            size="sm"
            type="button"
            variant="secondary"
            data-testid={dataTestId && `${dataTestId}-button`}
          >
            {hasValue ? "Clear" : "Choose file"}
          </Button>
        </StyledButton>
      </StyledInput>
    </StyledFileInput>
  );
};

export default forwardRef(FileInput);
