import {
  type OverlayTriggerAria,
  useOverlayTrigger,
} from "@react-aria/overlays";
import {
  type OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import React, { createContext, useContext, useMemo, useRef } from "react";

interface NavDrawerContextValue {
  overlayProps: OverlayTriggerAria["overlayProps"];
  overlayTriggerState: OverlayTriggerState;
  triggerProps: OverlayTriggerAria["triggerProps"];
  triggerRef: React.RefObject<HTMLButtonElement>;
}

const NavDrawerContext = createContext<NavDrawerContextValue | undefined>(
  undefined,
);

export interface NavDrawerProviderProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const NavDrawerProvider = ({
  children,
  isOpen,
  onOpenChange,
}: NavDrawerProviderProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);

  const overlayTriggerState = useOverlayTriggerState({ isOpen, onOpenChange });

  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: "dialog" },
    overlayTriggerState,
    triggerRef,
  );

  const value = useMemo<NavDrawerContextValue>(
    () => ({
      overlayProps,
      overlayTriggerState,
      triggerProps,
      triggerRef,
    }),
    [overlayProps, overlayTriggerState, triggerProps],
  );

  return (
    <NavDrawerContext.Provider value={value}>
      {children}
    </NavDrawerContext.Provider>
  );
};

export default NavDrawerProvider;

export const useNavDrawer = () => {
  const context = useContext(NavDrawerContext);

  if (!context) {
    throw new Error("useNavDrawer must be used inside an NavDrawerProvider");
  }

  return context;
};
