import styled from "styled-components";
import StatusIcon from "../StatusIcon/StatusIcon";
import Text from "../Text/Text";

const StyledSeverityCardSubMetric = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`;

const StyledLabel = styled.span`
  ${(p) => p.theme.typography.variants.bodyDefaultStrong}
  color: ${(p) => p.theme.colors.neutrals.text};
`;

export type SeverityCardSubMetricProps = {
  hasWarning?: boolean;
  children: React.ReactNode;
  label: string;
};

/**
 * A sub metric provided to the `footer` prop of the SeverityCard.
 */
const SeverityCardSubMetric = ({
  hasWarning = false,
  children,
  label,
}: SeverityCardSubMetricProps) => {
  return (
    <StyledSeverityCardSubMetric>
      {hasWarning && (
        <StatusIcon
          data-testid="SeverityCardSubMetric-warning-icon"
          status="warning"
          size="sm"
        />
      )}
      <Text size="bodyDefault" variant="weak">
        <StyledLabel>{label}:</StyledLabel> {children}
      </Text>
    </StyledSeverityCardSubMetric>
  );
};

export default SeverityCardSubMetric;
