import { forwardRef, type ForwardRefRenderFunction } from "react";
import { AttachFile } from "../../icons";
import FileDisplay from "./FileDisplay";

interface FileDisplayInputProps {
  "data-testid"?: string;
  icon?: ReactSVGComponent;
  value?: File[] | string;
}

const FileDisplayInput: ForwardRefRenderFunction<
  HTMLDivElement,
  FileDisplayInputProps
> = (
  {
    "data-testid": dataTestId,
    icon = AttachFile,
    value,
  }: FileDisplayInputProps,
  ref,
) => {
  let label: string;
  if (typeof value === "string") {
    label = value;
  } else {
    label = value?.length
      ? value.map((file) => file.name).join(", ")
      : "No files";
  }
  return (
    <FileDisplay icon={icon} ref={ref} data-testid={dataTestId}>
      {label}
    </FileDisplay>
  );
};

export default forwardRef(FileDisplayInput);
