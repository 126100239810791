import { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { Collapse, Expand } from "../../icons";
import IconButton from "../IconButton/IconButton";
import Sidebar, {
  type SidebarProps,
  StyledSidebarContainer,
  StyledSidebarContent,
} from "./Sidebar/Sidebar";

export const StyledSideSheet = styled(Sidebar)`
  ${StyledSidebarContainer} {
    height: calc(100vh - var(--app-header-height, 0px));
  }

  ${StyledSidebarContent} {
    ${(p) =>
      !p.isCollapsed &&
      css`
        scrollbar-gutter: stable;
      `}
  }
`;

interface StyledNavDrawerHeaderProps {
  isCollapsed?: boolean;
}

const collapsedHeaderStyles = css`
  flex-direction: column;
  justify-content: start;
  gap: 24px;
  padding: 24px 16px 16px;
`;

const openHeaderStyles = css`
  justify-content: end;
  padding: 16px 16px 0;
  gap: 16px;
`;

export const StyledHeader = styled.div<StyledNavDrawerHeaderProps>`
  display: flex;
  align-items: center;

  ${(p) => (p.isCollapsed ? collapsedHeaderStyles : openHeaderStyles)}
`;

const StyledTitle = styled.h2`
  flex: 1;
  ${(p) => p.theme.typography.variants.h3}
`;

export interface SideSheetProps extends SidebarProps {
  isCollapsible?: boolean;
  title?: string;
}

const SideSheet: ForwardRefRenderFunction<HTMLDivElement, SideSheetProps> = (
  {
    children,
    isCollapsible = true,
    position = "end",
    title,
    ...props
  }: SideSheetProps,
  ref,
) => {
  const { isCollapsed, toggleIsCollapsed } = props;
  const toggleIcon = isCollapsed ? Expand : Collapse;

  const onToggleClick = () => toggleIsCollapsed();

  const hasTitleOrToggle = isCollapsible || !!title;
  const showToggle = isCollapsible;
  const showTitle = !isCollapsed && !!title;

  return (
    <StyledSideSheet position={position} {...props} ref={ref}>
      {hasTitleOrToggle && (
        <StyledHeader isCollapsed={isCollapsed}>
          {showTitle && <StyledTitle>{title}</StyledTitle>}
          {showToggle && (
            <IconButton
              icon={toggleIcon}
              label="Toggle sidebar"
              onClick={onToggleClick}
              size="md"
              variant="secondary"
              data-testid={`mapsidebarCollapsed${isCollapsed}`}
            />
          )}
        </StyledHeader>
      )}
      {children}
    </StyledSideSheet>
  );
};

export default forwardRef(SideSheet);
