/* istanbul ignore file */
import { SECOND } from "@kablamo/kerosene";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useInterval } from "usehooks-ts";

const getFormattedTime = (): string => {
  const date = new Date();
  return new Intl.DateTimeFormat("en-AU", {
    timeStyle: "long",
    hourCycle: "h23",
  }).format(date);
};

const StyledClock = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowLg}
  font-variant: tabular-nums;
`;

interface ClockProps {
  "data-testid"?: string;
}

const Clock = ({ "data-testid": dataTestId }: ClockProps) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    setFormattedTime(getFormattedTime());
  }, []);

  useInterval(() => {
    const refreshClock = () => {
      setFormattedTime(getFormattedTime());
    };

    refreshClock();
  }, SECOND);

  return <StyledClock data-testid={dataTestId}>{formattedTime}</StyledClock>;
};

export default Clock;
