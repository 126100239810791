import styled, { css } from "styled-components";
import fadeUpStyles from "../../lib/styled/fadeUpStyles";
import underlayStyles, {
  strongUnderlayStyles,
} from "../../lib/styled/underlayStyles";
import media from "../../theme/media";

export const MODAL_SIZES = ["xs", "sm", "md", "lg"] as const;
export type ModalSize = (typeof MODAL_SIZES)[number];

const modalSizeStyles: Record<ModalSize, ReturnType<typeof css>> = {
  xs: css`
    @media ${media.md} {
      max-width: 25rem;
    }
  `,
  sm: css`
    @media ${media.md} {
      max-width: 30rem;
    }
  `,
  md: css`
    @media ${media.md} {
      max-width: 36rem;
    }
  `,
  lg: css`
    @media ${media.md} {
      max-width: 60rem;
    }
  `,
};

interface StyledOverlayProps {
  size: ModalSize;
}

export const StyledOverlay = styled.div<StyledOverlayProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;
  min-height: calc(100% - 32px);
  width: auto;

  @media ${media.md} {
    margin: 2rem auto;
    min-height: calc(100% - 4rem);
  }

  ${(p) => modalSizeStyles[p.size]}
`;

export const MODAL_DIALOG_PADDING_X_VAR = "--modal-padding-x";

export const StyledDialog = styled.div`
  ${MODAL_DIALOG_PADDING_X_VAR}: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem var(${MODAL_DIALOG_PADDING_X_VAR});
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  background: ${(p) => p.theme.colors.neutrals.background};
  color: ${(p) => p.theme.colors.neutrals.text};
  ${fadeUpStyles}

  &:focus {
    outline: none;
  }
`;

export type UnderlayVariant = "default" | "strong";

const underlayVariantStyles: Record<UnderlayVariant, ReturnType<typeof css>> = {
  default: underlayStyles,
  strong: strongUnderlayStyles,
};

interface StyledUnderlayProps {
  variant: UnderlayVariant;
}

export const StyledUnderlay = styled.div<StyledUnderlayProps>`
  z-index: ${(p) => p.theme.zIndexes.modal};
  ${(p) => underlayVariantStyles[p.variant]}
`;

interface StyledMainProps {
  hasHighlight?: boolean;
}

export const StyledMain = styled.div<StyledMainProps>`
  display: grid;
  gap: 0.5rem;
  ${(p) =>
    p.hasHighlight &&
    css`
      gap: 0.75rem;
    `}
`;

export const StyledBody = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: start;
  gap: 0.5rem;
`;

export const StyledActions = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: -0.25rem;
  margin-right: -0.25rem;
  align-self: start;
  flex-direction: row;
  gap: 0.125rem;
`;

export const StyledFooter = styled.div`
  display: grid;
  gap: 0.5rem;

  @media ${media.lg} {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const StyledReset = styled.div`
  margin: 0.5rem auto 0.75rem;

  @media ${media.lg} {
    margin: 0 auto 0 0;
  }
`;

export const StyledFooterButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column-reverse;

  @media ${media.lg} {
    flex-direction: row;
  }
`;
