import { useCollapse } from "react-collapsed";
import styled, { useTheme } from "styled-components";
import { ExpandLess, ExpandMore } from "../../icons";
import IconButton from "../IconButton/IconButton";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

const StyledAccordion = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  box-shadow: ${(p) => p.theme.boxShadows.cardWeak};
`;

const StyledSummary = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  padding: 16px;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
`;

const StyledLabel = styled.div`
  display: flex;
  gap: 4px;
  align-items: inherit;
  ${(p) => p.theme.typography.variants.bodyLg}
`;

const StyledPanel = styled.div``;

const StyledPanelInner = styled.div`
  padding: 0 16px 16px;
`;

export interface SectionAccordionProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  icon?: ReactSVGComponent;
  title: React.ReactNode;
}

const SectionAccordion = ({
  children,
  "data-testid": dataTestId,
  title,
  icon,
}: SectionAccordionProps) => {
  const theme = useTheme();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
  });

  return (
    <StyledAccordion data-testid={dataTestId && `${dataTestId}`}>
      <StyledSummary>
        <StyledLabel>
          {icon && (
            <StaticIconWrapper
              data-testid={dataTestId && `${dataTestId}-icon-wrapper`}
              icon={icon}
              size="sm"
            />
          )}
          {title}
        </StyledLabel>
        <IconButton
          {...getToggleProps()}
          icon={isExpanded ? ExpandLess : ExpandMore}
          label={isExpanded ? "Collapse" : "Expand"}
          size="sm"
          variant="secondary"
          data-testid={dataTestId && `${dataTestId}-trigger`}
        />
      </StyledSummary>
      <StyledPanel {...getCollapseProps()}>
        <StyledPanelInner>{children}</StyledPanelInner>
      </StyledPanel>
    </StyledAccordion>
  );
};

export default SectionAccordion;
