import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import getConfig from "next/config";
import { useEffect, useState } from "react";

const { publicRuntimeConfig } = getConfig();

const useSetupAuthInterceptor = () => {
  const { REST_API_URL } = publicRuntimeConfig;
  const { oktaAuth } = useOktaAuth();
  const [requestInterceptor] = useState(() => {
    return axios.interceptors.request.use((config) => {
      const accessToken = oktaAuth.getAccessToken();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;

      // eslint-disable-next-line no-param-reassign
      config.baseURL = REST_API_URL;

      return config;
    });
  });

  const [responseInterceptor] = useState(() => {
    return axios.interceptors.response.use(
      (response) => response,
      (error) => {
        return Promise.reject(error);
      },
    );
  });

  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [requestInterceptor, responseInterceptor]);
};

export default useSetupAuthInterceptor;
