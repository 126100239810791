import { css } from "styled-components";

export type BadgeVariant =
  | "automated"
  | "disabled"
  | "endorsed"
  | "error"
  | "info"
  | "inverse"
  | "inverseDisabled"
  | "manual"
  | "medium"
  | "neutral"
  | "new"
  | "notRequired"
  | "redMap"
  | "required"
  | "unvalidated"
  | "validated"
  | "warning"
  | "weak";

export type BadgeTheme = Record<BadgeVariant, ReturnType<typeof css>>;

export const classicBadgeStyles: BadgeTheme = {
  automated: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.decorative.automatedBackground};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.automatedBackgroundHover};
    }
  `,
  disabled: css`
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  `,
  endorsed: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.decorative.endorsedBackground};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.endorsedBackgroundHover};
    }
  `,
  error: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.error.backgroundStrong};

    &:hover {
      background-color: ${(p) => p.theme.colors.error.backgroundStrongHover};
    }
  `,
  info: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.informative.backgroundWeak};
  `,
  inverse: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  `,
  inverseDisabled: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  `,
  manual: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.decorative.manualBackground};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.manualBackgroundHover};
    }
  `,
  medium: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
    border-color: ${(p) => p.theme.colors.neutrals.borderWeak};

    &:hover {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakHover};
    }
  `,
  neutral: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.decorative.neutralBackground};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.neutralBackgroundHover};
    }
  `,
  new: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.primary.backgroundWeak};
  `,
  notRequired: css`
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  `,
  redMap: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.fireStatus.going.background};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.fireStatus.going.backgroundSelected};
    }
  `,
  required: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.informative.backgroundStrong};
  `,
  unvalidated: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border: 1px dashed ${(p) => p.theme.colors.neutrals.borderSelected};
  `,
  validated: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border: 1px solid ${(p) => p.theme.colors.neutrals.borderSelected};
  `,
  warning: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.warning.backgroundWeak};
    border: 1px solid ${(p) => p.theme.colors.warning.border};

    svg {
      color: ${(p) => p.theme.colors.warning.icon};
    }
  `,
  weak: css`
    color: ${(p) => p.theme.colors.neutrals.textWeak};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  `,
};

export const modernBadgeStyles: BadgeTheme = {
  automated: css`
    color: ${(p) => p.theme.colors.decorative.automatedText};
    background-color: ${(p) =>
      p.theme.colors.decorative.automatedBackgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.automatedBackgroundWeakHover};
    }
  `,
  disabled: css`
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  `,
  endorsed: css`
    color: ${(p) => p.theme.colors.success.textHover};
    background-color: ${(p) => p.theme.colors.success.backgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.success.backgroundWeakHover};
    }
  `,
  error: css`
    color: ${(p) => p.theme.colors.error.text};
    background-color: ${(p) => p.theme.colors.error.backgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.error.backgroundWeakHover};
    }
  `,
  info: css`
    color: ${(p) => p.theme.colors.informative.textHover};
    background-color: ${(p) => p.theme.colors.informative.backgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) =>
        p.theme.colors.informative.backgroundWeakHover};
    }
  `,
  inverse: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  `,
  inverseDisabled: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  `,
  manual: css`
    color: ${(p) => p.theme.colors.decorative.manualText};
    background-color: ${(p) => p.theme.colors.decorative.manualBackgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) =>
        p.theme.colors.decorative.manualBackgroundWeakHover};
    }
  `,
  medium: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
    border-color: ${(p) => p.theme.colors.neutrals.borderWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakHover};
    }
  `,
  neutral: css`
    color: ${(p) => p.theme.colors.neutrals.textWeak};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    }
  `,
  new: css`
    color: ${(p) => p.theme.colors.success.textHover};
    background-color: ${(p) => p.theme.colors.success.backgroundWeak};
  `,
  notRequired: css`
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  `,
  redMap: css`
    color: ${(p) => p.theme.colors.error.text};
    background-color: ${(p) => p.theme.colors.error.backgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.error.backgroundWeakHover};
    }
  `,
  required: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    background-color: ${(p) => p.theme.colors.informative.backgroundStrong};
  `,
  unvalidated: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border: 1px dashed ${(p) => p.theme.colors.neutrals.borderSelected};
  `,
  validated: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border: 1px solid ${(p) => p.theme.colors.neutrals.borderSelected};
  `,
  warning: css`
    color: ${(p) => p.theme.colors.warning.textHover};
    background-color: ${(p) => p.theme.colors.warning.backgroundWeak};

    &[data-has-tooltip="true"]:hover {
      background-color: ${(p) => p.theme.colors.warning.backgroundWeakHover};
    }
  `,
  weak: css`
    color: ${(p) => p.theme.colors.neutrals.textWeak};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  `,
};
