import { useOverlayTrigger } from "@react-aria/overlays";
import {
  useOverlayTriggerState,
  type OverlayTriggerState,
} from "@react-stately/overlays";
import React, { useRef } from "react";
import { ArrowDropDown } from "../../icons";
import Popover from "../Popover/Popover";
import type { PopoverSize } from "../Popover/PopoverOverlay";
import ButtonChip from "./ButtonChip";

interface DropdownChipProps {
  checked?: boolean;
  children?: (state: OverlayTriggerState) => React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
  label: React.ReactNode;
  popoverSize?: PopoverSize;
  "data-testid"?: string;
}

const DropdownChip = ({
  checked,
  children,
  disabled,
  isOpen,
  label,
  popoverSize,
  "data-testid": dataTestId,
}: DropdownChipProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const triggerState = useOverlayTriggerState({ isOpen });

  const {
    overlayProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Using onPress is clicking checkboxes in the open modal when you release https://github.com/adobe/react-spectrum/issues/1513
    triggerProps: { onPress, ...triggerProps },
  } = useOverlayTrigger(
    {
      type: "dialog",
    },
    triggerState,
    triggerRef,
  );

  return (
    <>
      <ButtonChip
        {...triggerProps}
        // Use this intentionally instead of `onPress` due to above mentioned bug
        onClick={triggerState.open}
        checked={checked}
        disabled={disabled}
        icon={ArrowDropDown}
        ref={triggerRef}
        data-testid={dataTestId && `${dataTestId}-trigger`}
      >
        {label}
      </ButtonChip>
      <Popover
        {...overlayProps}
        isModalOnMobile
        maxHeight={480}
        size={popoverSize}
        state={triggerState}
        triggerRef={triggerRef}
        data-testid={dataTestId && `${dataTestId}-popover`}
      >
        {children?.(triggerState)}
      </Popover>
    </>
  );
};

export default DropdownChip;
