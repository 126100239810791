import {
  ToastContainer as ReactToastContainer,
  toast,
  type CloseButtonProps,
  type TypeOptions,
} from "react-toastify";
import styled from "styled-components";
import { Close, Done, ErrorOutline, Info, Warning } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { ThemeProvider } from "../../theme";
import IconButton from "../IconButton/IconButton";
import Text from "../Text/Text";

export const TOAST_VARIANTS = [
  "info",
  "success",
  "warning",
  "error",
  "default",
] as const;
export type ToastVariant = (typeof TOAST_VARIANTS)[number];

export const DEFAULT_ERROR_MESSAGE =
  "Please wait and try again. If your problem persists contact ICT Service Desk.";

const StyledToastContainer = styled(ReactToastContainer)`
  &&&.Toastify__toast-container {
    width: 350px;
    padding: 0;
  }

  @media only screen and (max-width: 480px) {
    &&&.Toastify__toast-container {
      top: 1em;
      right: 1em;
      left: auto;
    }
  }

  .Toastify__toast {
    margin-bottom: 16px;
    box-shadow: none;
    padding: 12px;
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
    color: ${(p) => p.theme.colors.neutrals.text};

    border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;

    svg {
      height: ${(p) => p.theme.typography.variants.subtitleSm["line-height"]};
      width: ${(p) => p.theme.typography.variants.subtitleSm["line-height"]};
    }
  }

  .Toastify__toast-body {
    padding: 0;
    align-items: start;
  }

  .Toastify__toast-icon {
    width: unset;
    margin-right: 8px;
  }

  .Toastify__toast--error {
    background-color: ${(p) => p.theme.colors.error.backgroundWeak};
    border-color: ${(p) => p.theme.colors.error.borderHover};
  }
  .Toastify__toast--error .Toastify__toast-icon {
    color: ${(p) => p.theme.colors.error.iconHover};
  }

  .Toastify__toast--success {
    background-color: ${(p) => p.theme.colors.success.backgroundWeak};
    border-color: ${(p) => p.theme.colors.success.border};
  }
  .Toastify__toast--success .Toastify__toast-icon {
    color: ${(p) => p.theme.colors.success.icon};
  }

  .Toastify__toast--warning {
    background-color: ${(p) => p.theme.colors.warning.backgroundWeak};
    border-color: ${(p) => p.theme.colors.warning.border};
  }
  .Toastify__toast--warning .Toastify__toast-icon {
    color: ${(p) => p.theme.colors.warning.icon};
  }
`;

const CloseButton = ({ closeToast, ariaLabel }: CloseButtonProps) => {
  return (
    <IconButton
      onClick={closeToast}
      icon={Close}
      label={ariaLabel ?? "Close"}
      variant="ghost"
      size="sm"
      type="button"
    />
  );
};

export const ToastContainer = () => {
  return (
    <ThemeProvider>
      <StyledToastContainer
        autoClose={3000}
        closeButton={CloseButton}
        draggable={false}
        hideProgressBar
        newestOnTop
        position="top-right"
      />
    </ThemeProvider>
  );
};

const StyledToastMessage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${(p) => p.theme.typography.variants.bodyDefault}

  color: ${(p) => p.theme.colors.neutrals.text};
`;

const StyledRevertButton = styled.button`
  ${buttonReset}
  ${(p) => p.theme.typography.variants.bodyDefaultStrong}
  margin-top: 0.25rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  text-transform: ${(p) => p.theme.fontCases.uppercase};

  &:hover {
    text-decoration: underline;
  }
`;

interface ToastMessageProps {
  message?: string;
  onRevert?: () => void;
  title: string;
}

const ToastMessage = ({ message, onRevert, title }: ToastMessageProps) => (
  <StyledToastMessage>
    <Text size="subtitleSm">{title}</Text>
    {message}
    {onRevert && (
      <StyledRevertButton onClick={onRevert}>Undo</StyledRevertButton>
    )}
  </StyledToastMessage>
);

const toastIcon = (variant: TypeOptions) => {
  switch (variant) {
    case "default":
      return Info;
    case "error":
      return ErrorOutline;
    case "success":
      return Done;
    case "warning":
      return Warning;
    default:
      return null;
  }
};

const toastMessage = (variant: TypeOptions, message?: string) => {
  if (variant === "error") {
    return message || DEFAULT_ERROR_MESSAGE;
  }
  return message;
};

interface ShowToastParams {
  id?: string;
  message?: string;
  onRevert?: () => void;
  title: string;
  variant: ToastVariant;
}

export const showToast = ({
  id,
  message,
  onRevert,
  title,
  variant,
}: ShowToastParams) => {
  const toastVariant = variant as TypeOptions;

  return toast(
    <ToastMessage
      message={toastMessage(toastVariant, message)}
      onRevert={onRevert}
      title={title}
    />,
    {
      type: toastVariant,
      icon: toastIcon(toastVariant),
      closeOnClick: false,
      ...(id && { toastId: id }),
    },
  );
};
