import styled from "styled-components";
import media from "../../theme/media";
import RadioButton, { type RadioButtonProps } from "../RadioButton/RadioButton";

interface StyledImageRadioButtonProps {
  backgroundImage: string;
}

const StyledImageRadioButton = styled.label<StyledImageRadioButtonProps>`
  position: relative;
  display: flex;
  align-items: start;
  justify-content: end;
  padding: 0.125rem;
  height: 4.5rem;
  width: 5.5rem;
  max-width: 100%;

  background-size: cover;
  background-image: ${(p) => `url(${p.backgroundImage})`};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  cursor: pointer;

  @media ${media.lg} {
    height: 2rem;
    width: 2rem;
  }
`;

export interface ImageRadioButtonProps
  extends Omit<RadioButtonProps, "label" | "size"> {
  "data-testid"?: string;
  src: string;
}

const ImageRadioButton = ({
  "data-testid": dataTestId,
  src,
  ...props
}: ImageRadioButtonProps) => {
  return (
    <StyledImageRadioButton backgroundImage={src} data-testid={dataTestId}>
      <RadioButton
        label={null}
        data-testid={dataTestId && `${dataTestId}-radio-button`}
        {...props}
      />
    </StyledImageRadioButton>
  );
};

export default ImageRadioButton;
