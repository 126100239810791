import {
  FieldRow,
  RadioButtonGroup,
  Switch,
  type BadgeStyle,
  FieldGroup,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type {
  DeveloperOptions,
  ResponseSuggestionFireSizeKpiTreatment,
  ResponseSuggestionFireSizePlacement,
} from "./DeveloperOptionsProvider";

const StyledForm = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledTabPanel = styled.div`
  padding: 1rem 0 0;
`;

export interface DeveloperOptionsFormValues {
  badgeStyle: BadgeStyle;
  isEnvironmentIndicatorEnabled: boolean;
  isMapRailEnabled: boolean;
  riskResponse: {
    fireSizeKpiTreatment: ResponseSuggestionFireSizeKpiTreatment;
    fireSizePlacement: ResponseSuggestionFireSizePlacement;
    isExpiredSuggestionsEnabled: boolean;
    isOverdueEnabled: boolean;
  };
}

export const getDefaultDeveloperOptionsFormValues = (
  developerOptions: DeveloperOptions,
): DeveloperOptionsFormValues => {
  return {
    ...developerOptions.options,
    riskResponse: {
      ...developerOptions.options.riskResponse,
    },
  };
};

interface DeveloperOptionsFormProps {
  onSelect: (selectedIndex: number) => void;
  selectedIndex: number;
}

const DeveloperOptionsForm = ({
  onSelect,
  selectedIndex,
}: DeveloperOptionsFormProps) => {
  const { control } = useFormContext<DeveloperOptionsFormValues>();

  return (
    <Tabs onSelect={onSelect} selectedIndex={selectedIndex}>
      <TabList>
        <Tab flex>Visual style</Tab>
        <Tab flex>Risk response</Tab>
      </TabList>
      <TabPanel>
        <StyledTabPanel>
          <StyledForm>
            <FieldGroup>
              <Controller
                control={control}
                name="badgeStyle"
                render={({ field, fieldState }) => {
                  return (
                    <RadioButtonGroup
                      error={fieldState.error}
                      label="Badge style"
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                    >
                      <RadioButtonGroup.Item label="Classic" value="classic" />
                      <RadioButtonGroup.Item label="Modern" value="modern" />
                    </RadioButtonGroup>
                  );
                }}
              />
              <Controller
                control={control}
                name="isEnvironmentIndicatorEnabled"
                render={({ field }) => {
                  return (
                    <FieldRow
                      htmlFor="isEnvironmentIndicatorEnabled"
                      id="isEnvironmentIndicatorEnabledLabel"
                      label="Show environment indicator"
                    >
                      <Switch
                        aria-labelledby="isEnvironmentIndicatorEnabledLabel"
                        id="isEnvironmentIndicatorEnabled"
                        isSelected={field.value}
                        onChange={field.onChange}
                      />
                    </FieldRow>
                  );
                }}
              />
              <Controller
                control={control}
                name="isMapRailEnabled"
                render={({ field }) => {
                  return (
                    <FieldRow
                      htmlFor="isMapRailEnabled"
                      id="isMapRailEnabledLabel"
                      label="Use map rail layout"
                    >
                      <Switch
                        aria-labelledby="isMapRailEnabledLabel"
                        id="isMapRailEnabled"
                        isSelected={field.value}
                        onChange={field.onChange}
                      />
                    </FieldRow>
                  );
                }}
              />
            </FieldGroup>
          </StyledForm>
        </StyledTabPanel>
      </TabPanel>
      <TabPanel>
        <StyledTabPanel>
          <StyledForm>
            <FieldGroup title="UI experiments">
              <Controller
                control={control}
                name="riskResponse.fireSizePlacement"
                render={({ field, fieldState }) => {
                  return (
                    <RadioButtonGroup
                      error={fieldState.error}
                      label="Fire size placement"
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                    >
                      <RadioButtonGroup.Item
                        label="With time"
                        value="withTime"
                      />
                      <RadioButtonGroup.Item
                        label="With counts"
                        value="withCounts"
                      />
                    </RadioButtonGroup>
                  );
                }}
              />
              <Controller
                control={control}
                name="riskResponse.fireSizeKpiTreatment"
                render={({ field, fieldState }) => {
                  return (
                    <RadioButtonGroup
                      error={fieldState.error}
                      label="Fire size KPI treatment"
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                    >
                      <RadioButtonGroup.Item label="As icon" value="icon" />
                      <RadioButtonGroup.Item label="As badge" value="badge" />
                    </RadioButtonGroup>
                  );
                }}
              />
              <Controller
                control={control}
                name="riskResponse.isOverdueEnabled"
                render={({ field }) => {
                  return (
                    <FieldRow
                      htmlFor="isOverdueSuggestionsEnabled"
                      id="isOverdueSuggestionsEnabledLabel"
                      isInline
                      label="Overdue suggestions"
                    >
                      <Switch
                        aria-labelledby="isOverdueSuggestionsEnabledLabel"
                        id="isOverdueSuggestionsEnabled"
                        isSelected={field.value}
                        onChange={field.onChange}
                      />
                    </FieldRow>
                  );
                }}
              />
            </FieldGroup>
            <FieldGroup title="Debug options">
              <Controller
                control={control}
                name="riskResponse.isExpiredSuggestionsEnabled"
                render={({ field }) => {
                  return (
                    <FieldRow
                      htmlFor="isExpiredSuggestionsEnabled"
                      id="isExpiredSuggestionsEnabledLabel"
                      isInline
                      label="Bypass suggestion expiry"
                    >
                      <Switch
                        aria-labelledby="isExpiredSuggestionsEnabledLabel"
                        id="isExpiredSuggestionsEnabled"
                        isSelected={field.value}
                        onChange={field.onChange}
                      />
                    </FieldRow>
                  );
                }}
              />
            </FieldGroup>
          </StyledForm>
        </StyledTabPanel>
      </TabPanel>
    </Tabs>
  );
};

export default DeveloperOptionsForm;
