import styled from "styled-components";
import { Play } from "../../icons";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

const StyledVideoThumbnail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 16 / 9;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  border-radius: ${(p) => p.theme.borderRadiuses.md}px;

  svg {
    margin: auto;
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    opacity: 0.5;
  }
`;

interface VideoThumbnailProps {
  children?: React.ReactNode;
  "data-testid"?: string;
}

const VideoThumbnail = ({
  children,
  "data-testid": dataTestId,
}: VideoThumbnailProps) => {
  return (
    <StyledVideoThumbnail data-testid={dataTestId}>
      <StaticIconWrapper icon={Play} size="xxl" />
      {children}
    </StyledVideoThumbnail>
  );
};

export default VideoThumbnail;
