import { useMenu } from "@react-aria/menu";
import { useTreeState } from "@react-stately/tree";
import type { AriaMenuProps } from "@react-types/menu";
import { useRef } from "react";
import styled from "styled-components";
import { MenuItem } from "./MenuItem";

const StyledUl = styled.ul`
  display: grid;
  padding: 0.25rem;
  outline: none;
`;

interface MenuProps extends AriaMenuProps<NonNullable<unknown>> {
  "data-testid"?: string;
}

const Menu = ({ "data-testid": dataTestId, ...props }: MenuProps) => {
  const state = useTreeState(props);

  const ref = useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <StyledUl
      {...menuProps}
      ref={ref}
      data-testid={dataTestId && `${dataTestId}-menu`}
    >
      {[...state.collection].map((item) => (
        <MenuItem
          key={item.key}
          item={item}
          state={state}
          data-testid={dataTestId && `${dataTestId}-menu-item`}
        />
      ))}
    </StyledUl>
  );
};

export default Menu;
