import { ExpandMore, ExpandLess } from "../../icons";
import DataTableHeaderIconWrapper from "./DataTableHeaderIconWrapper";

interface SortArrowProps {
  "data-testid"?: string;
  isDesc?: boolean;
}

const SortArrow = ({ "data-testid": dataTestId, isDesc }: SortArrowProps) => {
  return (
    <DataTableHeaderIconWrapper
      data-testid={dataTestId}
      icon={isDesc ? ExpandMore : ExpandLess}
      size="sm"
    />
  );
};

export default SortArrow;
