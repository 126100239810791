import pluralize from "pluralize";
import type { FormControlOption } from "../../types/design-system";
import CheckboxGroupDialog from "./CheckboxGroupDialog";
import DropdownChip from "./DropdownChip";

interface FormatLabelParams {
  itemType: string;
  label: string;
  selectedCount: number;
}

const formatLabel = ({ itemType, label, selectedCount }: FormatLabelParams) => {
  if (!selectedCount) {
    return label;
  }

  return `${selectedCount} ${pluralize(itemType, selectedCount)}`;
};

interface CheckboxGroupDropdownChipProps<T extends string> {
  disabled?: boolean;
  isSearchable?: boolean;
  items: FormControlOption<T>[];
  itemType: string;
  label: string;
  onChange: (value: T[]) => void;
  value: T[];
  "data-testid"?: string;
}

const CheckboxGroupDropdownChip = <T extends string>({
  disabled,
  isSearchable,
  items,
  itemType,
  label,
  onChange,
  value,
  "data-testid": dataTestId,
}: CheckboxGroupDropdownChipProps<T>) => {
  const checked = !!value.length;

  const formattedLabel = formatLabel({
    itemType,
    label,
    selectedCount: value.length,
  });

  return (
    <DropdownChip
      checked={checked}
      disabled={disabled}
      label={formattedLabel}
      data-testid={dataTestId}
    >
      {(state) => (
        <CheckboxGroupDialog
          isSearchable={isSearchable}
          items={items}
          onChange={onChange}
          onClose={state.close}
          value={value}
        />
      )}
    </DropdownChip>
  );
};

export default CheckboxGroupDropdownChip;
