import type { StatusVariant } from "../../types/design-system";
import { statusIconMap } from "../Alert/Alert";
import StaticIconWrapper, {
  type IconVariant,
  type StaticIconWrapperSize,
} from "../StaticIconWrapper/StaticIconWrapper";
import { Tooltip } from "../Tooltip/Tooltip";

export interface StatusIconProps {
  "data-testid"?: string;
  size: StaticIconWrapperSize;
  status: StatusVariant;
  tooltip?: React.ReactNode;
  variant?: IconVariant;
}

const StatusIcon = ({
  "data-testid": dataTestId,
  size,
  status,
  tooltip,
  variant = status,
}: StatusIconProps) => {
  const icon = statusIconMap[status];

  if (!tooltip) {
    return (
      <StaticIconWrapper
        data-testid={dataTestId && `${dataTestId}-static-icon-wrapper`}
        icon={icon}
        size={size}
        variant={variant}
      />
    );
  }

  return (
    <Tooltip
      data-testid={dataTestId && `${dataTestId}-tooltip`}
      message={tooltip}
    >
      <div>
        <StaticIconWrapper
          data-testid={dataTestId && `${dataTestId}-static-icon-wrapper`}
          icon={icon}
          size={size}
          variant={variant}
        />
      </div>
    </Tooltip>
  );
};

export default StatusIcon;
