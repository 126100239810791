import ReactSkeleton, {
  type SkeletonProps as ReactSkeletonProps,
} from "react-loading-skeleton";
import styled from "styled-components";

const StyledFlexSkeleton = styled.span`
  flex-grow: 1;
`;

const StyledSkeleton = styled(ReactSkeleton)`
  z-index: auto;
`;

export interface SkeletonProps extends ReactSkeletonProps {
  "data-testid"?: string;
  flex?: boolean;
}

const Skeleton = ({
  "data-testid": dataTestId,
  flex,
  ...props
}: SkeletonProps) => {
  if (flex) {
    return (
      <StyledFlexSkeleton data-testid={dataTestId && `flex-${dataTestId}`}>
        <StyledSkeleton {...props} containerTestId={dataTestId} />
      </StyledFlexSkeleton>
    );
  }

  return <StyledSkeleton {...props} containerTestId={dataTestId} />;
};

export default Skeleton;
