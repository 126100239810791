import styled from "styled-components";
import type { TypographySize } from "../../theme";
import {
  type IconVariant,
  getIconVariantColor,
} from "../StaticIconWrapper/StaticIconWrapper";

interface StyledTypographyIconWrapperProps {
  size: TypographySize;
  variant?: IconVariant;
}

const StyledTypographyIconWrapper = styled.div<StyledTypographyIconWrapperProps>`
  color: ${(p) => getIconVariantColor({ theme: p.theme, variant: p.variant })};

  svg {
    width: auto;
    height: ${(p) => p.theme.typography.variants[p.size]["line-height"]};
  }
`;

interface TypographyIconWrapperProps {
  icon: ReactSVGComponent;
  size?: TypographySize;
  variant?: IconVariant;
  "data-testid"?: string;
}

const TypographyIconWrapper = ({
  icon: Icon,
  size = "bodyDefault",
  variant,
  "data-testid": dataTestId,
}: TypographyIconWrapperProps) => {
  return (
    <StyledTypographyIconWrapper
      size={size}
      variant={variant}
      data-testid={dataTestId}
    >
      <Icon />
    </StyledTypographyIconWrapper>
  );
};

export default TypographyIconWrapper;
