import { forwardRef } from "react";
import type { ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import { focusStyles, weakStyles } from "../Button/states";

type FooterButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButton = styled.button`
  ${buttonReset}
  padding: 0px 8px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 4px;

  color: ${(p) => p.theme.colors.neutrals.textWeak};

  &:hover {
    color: ${(p) => p.theme.colors.neutrals.text};
    text-decoration: underline;
  }

  &:active {
    color: ${(p) => p.theme.colors.neutrals.text};
    text-decoration: underline;
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledLabel = styled.p`
  ${(p) => p.theme.typography.variants.bodyDefault}
  padding: 8px 0px;
`;

const FooterButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  FooterButtonProps
> = ({ children, ...props }: FooterButtonProps, ref) => {
  return (
    <StyledButton ref={ref} {...props}>
      <StyledLabel>{children}</StyledLabel>
    </StyledButton>
  );
};

export default forwardRef(FooterButton);
