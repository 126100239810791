import { useDialog } from "@react-aria/dialog";
import { useRef, useState } from "react";
import { Search } from "../../icons";
import type { FormControlOption } from "../../types/design-system";
import Checkbox from "../Checkbox/Checkbox";
import Divider from "../Divider/Divider";
import TextInput from "../TextInput/TextInput";
import CheckboxGroupDialogList from "./CheckboxGroupDialogList";
import DropdownChipDialogFooter from "./DropdownChipDialogFooter";
import { StyledDialog, StyledDialogBody } from "./styled";

interface CheckboxGroupDialogProps<T extends string> {
  isSearchable?: boolean;
  items: FormControlOption<T>[];
  onChange: (value: T[]) => void;
  onClose: () => void;
  value: T[];
}

const CheckboxGroupDialog = <T extends string>({
  isSearchable,
  items,
  onChange: _onChange,
  onClose,
  value: _value,
}: CheckboxGroupDialogProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps } = useDialog({}, ref);

  const [value, setValue] = useState<T[]>(_value);

  const [search, setSearch] = useState<string>("");

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  if (!isSearchable && !!search) {
    setSearch("");
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      setValue(items.map((item) => item.value));
    } else {
      setValue([]);
    }
  };

  const onChange = (newValue: T[]) => {
    setValue(newValue);
  };

  const onCancel = () => {
    onClose();
  };

  const onApply = () => {
    onClose();
    _onChange(value);
  };

  return (
    <StyledDialog {...dialogProps} ref={ref}>
      <StyledDialogBody>
        {isSearchable && (
          <TextInput
            iconStart={Search}
            onChange={onSearchChange}
            placeholder="Search for..."
            size="sm"
            value={search}
          />
        )}
        {!search && (
          <Checkbox
            checked={items.every((item) => value.includes(item.value))}
            label="Select all"
            onChange={onSelectAll}
          />
        )}
        <Divider />
        <CheckboxGroupDialogList
          items={items.filter((item) =>
            item.label.toLowerCase().includes(search.toLowerCase()),
          )}
          onChange={onChange}
          value={value}
        />
      </StyledDialogBody>
      <DropdownChipDialogFooter onApply={onApply} onCancel={onCancel} />
    </StyledDialog>
  );
};

export default CheckboxGroupDialog;
