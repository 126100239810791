import styled, { css } from "styled-components";

const StyledSkeletonBlockWrapper = styled.div<SkeletonBlockWrapperProps>`
  ${(p) =>
    p.flex &&
    css`
      flex: 1;
    `}
  line-height: 1;
`;

export interface SkeletonBlockWrapperProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  flex?: boolean;
}

const SkeletonBlockWrapper = ({
  children,
  "data-testid": dataTestId,
  flex,
}: SkeletonBlockWrapperProps) => {
  return (
    <StyledSkeletonBlockWrapper data-testid={dataTestId} flex={flex}>
      {children}
    </StyledSkeletonBlockWrapper>
  );
};

export default SkeletonBlockWrapper;
