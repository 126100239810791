import styled, { css } from "styled-components";
import type { StatusVariant } from "../../types/design-system";
import { statusIconMap } from "../Alert/Alert";
import { Tooltip } from "../Tooltip/Tooltip";

export const statusVariantStyleMap: Record<
  StatusVariant,
  ReturnType<typeof css>
> = {
  danger: css`
    color: ${(p) => p.theme.colors.danger.textOnBackground};

    svg {
      color: ${(p) => p.theme.colors.danger.icon};
    }
  `,
  error: css`
    color: ${(p) => p.theme.colors.error.text};

    svg {
      color: ${(p) => p.theme.colors.error.icon};
    }
  `,
  info: css`
    color: ${(p) => p.theme.colors.neutrals.text};

    svg {
      color: ${(p) => p.theme.colors.informative.icon};
    }
  `,
  progress: css`
    color: ${(p) => p.theme.colors.informative.text};

    svg {
      color: ${(p) => p.theme.colors.informative.icon};
    }
  `,
  success: css`
    color: ${(p) => p.theme.colors.success.text};

    svg {
      color: ${(p) => p.theme.colors.success.icon};
    }
  `,
  warning: css`
    color: ${(p) => p.theme.colors.warning.text};

    svg {
      color: ${(p) => p.theme.colors.warning.icon};
    }
  `,
};

interface StyledStatusMessageProps {
  variant: StatusVariant;
}

const StyledStatusMessage = styled.div<StyledStatusMessageProps>`
  display: flex;
  align-items: start;
  gap: 4px;

  ${(p) => statusVariantStyleMap[p.variant]}
  ${(p) => p.theme.typography.variants.bodyDefault}

  svg {
    width: ${(p) => p.theme.typography.variants.bodyDefault["line-height"]};
  }
`;

export interface StatusMessageProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  tooltip?: React.ReactNode;
  variant: StatusVariant;
}

const StatusMessage = ({
  children,
  "data-testid": dataTestId,
  tooltip,
  variant,
}: StatusMessageProps) => {
  const Icon = statusIconMap[variant];

  const content = (
    <StyledStatusMessage variant={variant}>
      <Icon />
      <div data-testid={dataTestId}>{children}</div>
    </StyledStatusMessage>
  );

  if (tooltip) {
    return <Tooltip message={tooltip}>{content}</Tooltip>;
  }

  return content;
};

export default StatusMessage;
