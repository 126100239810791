import { css } from "styled-components";
import { StyledSpinner } from "./Spinner";

export const spinnerStyles = (sizePx: number, isLoading?: boolean) => `
    ${
      isLoading &&
      css`
        span {
          visibility: hidden;
        }
      `
    }

    ${StyledSpinner} {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;

      width: ${sizePx / 16}rem;
      height: ${sizePx / 16}rem;
    }
  `;
