import { useButton } from "@react-aria/button";
import { useMenuTrigger } from "@react-aria/menu";
import { useMenuTriggerState } from "@react-stately/menu";
import type { CollectionChildren } from "@react-types/shared";
import React, { useRef, type Key } from "react";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";
import { ArrowDropDown } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { useTheme } from "../../theme";
import { focusStyles, mediumStyles, weakStyles } from "../Button/states";
import Popover from "../Popover/Popover";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";
import LinkDropdownMenu, {
  type LinkDropdownItemProps,
} from "./LinkDropdownMenu";

const StyledLinkDropdown = styled.div``;

const StyledTrigger = styled.button`
  ${buttonReset}
  display: flex;
  align-items: center;
  width: 100%;
  padding: calc(0.5rem - 1px) 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  ${mediumStyles.border.base}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  ${(p) => p.theme.typography.variants.buttonLg};

  &:hover {
    ${weakStyles.background.hover}
    ${mediumStyles.border.hover}
  }

  &:active {
    ${weakStyles.background.active}
    ${mediumStyles.border.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledLabel = styled.div`
  display: flex;
  flex: 1;
  gap: 0.5rem;
  align-items: center;
`;

export interface LinkDropdownProps {
  "data-testid"?: string;
  children: CollectionChildren<LinkDropdownItemProps>;
  label: React.ReactNode;
  icon: ReactSVGComponent;
  onAction?: (key: Key) => void;
  selectedKey?: string;
}

const LinkDropdown = ({
  "data-testid": dataTestId,
  children,
  icon,
  label,
  onAction,
  selectedKey,
}: LinkDropdownProps) => {
  const theme = useTheme();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const state = useMenuTriggerState({});

  const { menuTriggerProps, menuProps } = useMenuTrigger<LinkDropdownItemProps>(
    { isDisabled: false },
    state,
    triggerRef,
  );

  const { buttonProps } = useButton(menuTriggerProps, triggerRef);

  const { observe, width } = useDimensions();

  return (
    <>
      <StyledLinkDropdown data-testid={dataTestId} ref={observe}>
        <StyledTrigger
          {...buttonProps}
          data-testid={dataTestId && `${dataTestId}-trigger`}
          ref={triggerRef}
          type="button"
        >
          <StyledLabel data-testid={dataTestId && `${dataTestId}-label`}>
            <StaticIconWrapper data-testid={`${dataTestId}-icon`} icon={icon} />
            {label ?? "None selected"}
          </StyledLabel>
          <StaticIconWrapper
            data-testid={dataTestId && `${dataTestId}-chevron-icon`}
            icon={ArrowDropDown}
            size="md"
          />
        </StyledTrigger>
      </StyledLinkDropdown>
      <Popover
        data-testid={dataTestId && `${dataTestId}-popover`}
        state={state}
        triggerRef={triggerRef}
        width={width}
      >
        <LinkDropdownMenu
          {...menuProps}
          onAction={(key) => {
            if (!onAction) {
              return;
            }
            // There is some kind of bug where a TreeWalker error happens when
            // using the react-aria focus hooks with a transition. Adding this
            // timeout to the onAction seems to avoid it.
            // @see https://github.com/adobe/react-spectrum/issues/4514#issuecomment-1546076837
            setTimeout(() => {
              onAction(key);
            }, theme.anim.duration.sm + 50);
          }}
          selectedKeys={selectedKey && [selectedKey]}
          selectionMode="single"
          data-testid={dataTestId && `${dataTestId}-menu`}
        >
          {children}
        </LinkDropdownMenu>
      </Popover>
    </>
  );
};

export default LinkDropdown;
