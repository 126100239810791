import { lightColors } from "./colorsLight";
import {
  anim,
  badges,
  borderRadiuses,
  boxShadows,
  breakpoints,
  fontCases,
  fontStyles,
  fontTracking,
  fontWeights,
  typographyCSSProperties,
  typographySizes,
  type TypographyCSSProperty,
  type TypographySize,
} from "./constants";
import { colorsToProperties } from "./transformColors";

const colors = colorsToProperties(lightColors);

const getVariantProperties = (variant: TypographySize) => {
  const properties: Record<TypographyCSSProperty, string> =
    typographyCSSProperties
      .map((property: TypographyCSSProperty) => {
        return { [property]: `var(--${variant}-${property})` } as Record<
          TypographyCSSProperty,
          string
        >;
      })
      .reduce(
        (allProperties, newProperty) => ({ ...allProperties, ...newProperty }),
        {} as Record<TypographyCSSProperty, string>,
      );
  return properties;
};

const variants: Record<
  TypographySize,
  Record<TypographyCSSProperty, string>
> = typographySizes
  .map((variant: TypographySize) => {
    return { [variant]: getVariantProperties(variant) } as Record<
      TypographySize,
      Record<TypographyCSSProperty, string>
    >;
  })
  .reduce(
    (allVariants, newVariant) => ({ ...allVariants, ...newVariant }),
    {} as Record<TypographySize, Record<TypographyCSSProperty, string>>,
  );

export const theme = {
  anim,
  badges,
  borderRadiuses,
  boxShadows,
  breakpoints,
  colors,
  fontWeights,
  fontStyles,
  fontCases,
  fontTracking,
  isLeanBack: false,
  typography: { variants },
  zIndexes: {
    hoverMapItem: 600,
    mapItem: 601,
    appHeader: 700,
    bottomSheet: 710,
    overlayDrawer: 720,
    navDrawer: 800,
    popover: 900,
    modal: 1000,
  },
};

export type Theme = typeof theme;
