import { FocusScope } from "@react-aria/focus";
import { useOverlay } from "@react-aria/overlays";
import { Item } from "@react-stately/collections";
import { useSingleSelectListState } from "@react-stately/list";
import type { CollectionChildren } from "@react-types/shared";
import React, { useRef, type Key } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { useTheme } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import underlayStyles from "../../lib/styled/underlayStyles";
import BottomSheet from "../BottomSheet/BottomSheet";
import {
  BottomSheetActionType,
  type BottomSheetMode,
  type BottomSheetPosition,
  type UseBottomSheetResult,
} from "../BottomSheet/useBottomSheet";
import { focusStyles } from "../Button/states";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

const StyledUnderlay = styled.div`
  ${underlayStyles}
  z-index: ${(p) => p.theme.zIndexes.bottomSheet};
`;

export const StyledBottomTabs = styled.div`
  position: fixed;
  inset: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  pointer-events: none;
  z-index: ${(p) => p.theme.zIndexes.bottomSheet};

  & > * {
    pointer-events: auto;
  }
`;

const StyledBottomTabList = styled.div`
  position: relative;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-top: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
`;

// `position: absolute;` removes this container from the flow of the document
// and prevents it from taking up height, which would break the layout of the
// sibling BottomSheet. The `calc` in the`bottom` value shifts the adornment to
// 1px above the `BottomTabList` parent, as though it was sitting above it in
// normal flow. This allows us to have the adornment sit above the tab list
// without affecting any other layout.
//
// `z-index: -1` ensures that the adornment sits below the BottomSheet when
// it's shifted into active positions.
const StyledAdornment = styled.div`
  position: absolute;
  bottom: calc(100% + 1px);
  width: 100%;
  z-index: -1;

  &.enter {
    transform: translateY(100%);
  }

  &.enter-active {
    transform: translateY(0);
    transition: transform
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit {
    transform: translateY(0);
  }

  &.exit-active {
    transform: translateY(100%);
    transition: transform
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }
`;

const StyledBottomTab = styled.button`
  ${buttonReset}
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 0.125rem;
  padding: 0.375rem 0;
  ${(p) => p.theme.typography.variants.footnote};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  color: ${(p) => p.theme.colors.neutrals.textWeak};

  &:focus-visible {
    ${focusStyles("border")}
  }

  &[data-selected="true"] {
    color: ${(p) => p.theme.colors.neutrals.text};
  }
`;

export const BottomTabItem = Item as (props: BottomTabItemProps) => JSX.Element;

interface BottomTabItemProps {
  children?: React.ReactNode;
  icon: ReactSVGComponent;
  iconOn: ReactSVGComponent;
  id: string;
  isHidden?: boolean;
  mode?: BottomSheetMode;
  textValue: string;
}

interface BottomTabsProps {
  adornment?: React.ReactNode;
  bottomSheet: UseBottomSheetResult;
  children: CollectionChildren<BottomTabItemProps>;
  isAdornmentActive?: boolean;
}

const BottomTabs = ({
  adornment,
  bottomSheet,
  isAdornmentActive,
  ...props
}: BottomTabsProps) => {
  const theme = useTheme();
  const underlayRef = useRef<HTMLDivElement>(null);
  const adornmentRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const state = useSingleSelectListState(props);

  const onTabClick = (key: Key, mode: BottomSheetMode) => () => {
    // @ts-expect-error @react-aria types need to be updated to reflect bigint being included in @types/react
    state.setSelectedKey(key);
    bottomSheet.dispatch({ type: BottomSheetActionType.REVEAL, mode });
  };

  if (!state.selectedItem && bottomSheet.position !== "down") {
    bottomSheet.dispatch({
      type: BottomSheetActionType.SET_POSITION,
      position: "down",
    });
  }

  const onTransitionEnd = (position: BottomSheetPosition) => {
    if (state.selectedItem && position === "down") {
      state.setSelectedKey("");
    }
  };

  const onClose = () => {
    bottomSheet.dispatch({
      position: "down",
      type: BottomSheetActionType.SET_POSITION,
    });
  };

  const isOpen = bottomSheet.position !== "down";

  const { overlayProps, underlayProps } = useOverlay(
    {
      onClose,
      isOpen,
      isDismissable: true,
    },
    ref,
  );

  return (
    <>
      <CSSTransition
        appear
        in={isOpen}
        mountOnEnter
        nodeRef={underlayRef}
        timeout={{
          enter: theme.anim.duration.sm,
          exit: theme.anim.duration.sm,
        }}
        unmountOnExit
      >
        <StyledUnderlay {...underlayProps} ref={underlayRef} />
      </CSSTransition>
      <FocusScope contain={isOpen} restoreFocus>
        <StyledBottomTabs ref={ref}>
          <BottomSheet
            {...bottomSheet}
            {...overlayProps}
            onTransitionEnd={onTransitionEnd}
          >
            {state.selectedItem?.rendered}
          </BottomSheet>
          <StyledBottomTabList>
            {[...state.collection].map((item) => {
              const itemProps = item.props as BottomTabItemProps;

              if (itemProps.isHidden) return null;

              const isSelected =
                bottomSheet.position !== "down" &&
                state.selectedKey === item.key;

              return (
                <StyledBottomTab
                  data-selected={isSelected}
                  key={item.key}
                  onClick={onTabClick(item.key, itemProps.mode ?? "default")}
                >
                  <StaticIconWrapper
                    icon={isSelected ? itemProps.iconOn : itemProps.icon}
                    size="lg"
                    variant={isSelected ? "primary" : "neutral"}
                  />
                  {itemProps.textValue}
                </StyledBottomTab>
              );
            })}
            <CSSTransition
              in={isAdornmentActive}
              mountOnEnter
              nodeRef={adornmentRef}
              timeout={{
                enter: theme.anim.duration.sm,
                exit: theme.anim.duration.sm,
              }}
              unmountOnExit
            >
              <StyledAdornment ref={adornmentRef}>{adornment}</StyledAdornment>
            </CSSTransition>
          </StyledBottomTabList>
        </StyledBottomTabs>
      </FocusScope>
    </>
  );
};

export default BottomTabs;
