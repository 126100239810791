import type { BottomSheetPosition } from "./useBottomSheet";

export const BOTTOM_SHEET_TRANSFORM_POSITIONS: Record<
  BottomSheetPosition,
  number
> = {
  docked: 256,
  down: 0,
  up: 0,
};

export const LARGE_SWIPE_DELTA_Y_THRESHOLD = 100;
