import type React from "react";
import styled, { css } from "styled-components";
import type { TypographySize } from "../../theme";
import { focusStyles } from "../Button/states";

export type TextVariant =
  | "text"
  | "disabled"
  | "error"
  | "inherit"
  | "info"
  | "inverse"
  | "manual"
  | "success"
  | "warning"
  | "weak";

export const textVariantStyles: Record<TextVariant, ReturnType<typeof css>> = {
  text: css`
    color: ${(p) => p.theme.colors.neutrals.text};
  `,
  disabled: css`
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  `,
  error: css`
    color: ${(p) => p.theme.colors.error.text};
  `,
  info: css`
    color: ${(p) => p.theme.colors.informative.text};
  `,
  inherit: css`
    color: inherit;
  `,
  inverse: css`
    color: ${(p) => p.theme.colors.neutrals.textInverse};
  `,
  manual: css`
    color: ${(p) => p.theme.colors.decorative.manualText};
  `,
  success: css`
    color: ${(p) => p.theme.colors.success.text};
  `,
  warning: css`
    color: ${(p) => p.theme.colors.warning.text};
  `,
  weak: css`
    color: ${(p) => p.theme.colors.neutrals.textWeak};

    &[data-has-tooltip="true"] {
      &:hover {
        color: ${(p) => p.theme.colors.neutrals.text};
      }
    }

    a:hover {
      color: ${(p) => p.theme.colors.neutrals.text};
    }
  `,
};

interface StyledTextProps {
  fontStyle?: React.CSSProperties["fontStyle"];
  fontVariant?: React.CSSProperties["fontVariant"];
  fontWeight?: React.CSSProperties["fontWeight"];
  isInteractive?: boolean;
  size?: TypographySize;
  textDecoration?: React.CSSProperties["textDecoration"];
  variant?: TextVariant;
}

/**
 * Try not to use this component when there are other styles that need to be
 * applied to your element, as it adds more indirection than it's worth. If you
 * need to extend styles beyond what's applied by a typography size or variant,
 * just use the `p.theme.typography.variants[TypographySize]` utility function
 * in a separate styled-component.
 *
 * In other words, use this when all you need is an element with a size or
 * variant applied.
 */
const Text = styled.div<StyledTextProps>`
  ${(p) => p.variant && textVariantStyles[p.variant]}
  ${(p) => p.size && p.theme.typography.variants[p.size]}
  ${(p) =>
    p.textDecoration &&
    css`
      text-decoration: ${p.textDecoration};
    `}
  ${(p) =>
    p.fontVariant &&
    css`
      font-variant: ${p.fontVariant};
    `}
  ${(p) =>
    p.fontWeight &&
    css`
      font-weight: ${p.fontWeight};
    `}
  ${(p) =>
    p.fontStyle &&
    css`
      font-style: ${p.fontStyle};
    `}

  transition: color ${(p) =>
    `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  > a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  > a:focus-visible {
    ${focusStyles("ring")}
  }

  &[data-has-tooltip="true"] {
    cursor: default;
  }
`;

export default Text;
