import styled from "styled-components";
import Button from "../Button/Button";

const StyledFooter = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  padding: 12px;
  gap: 8px;
  justify-content: end;
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

interface DropdownChipDialogFooterProps {
  onApply: () => void;
  onCancel: () => void;
}

const DropdownChipDialogFooter = ({
  onApply,
  onCancel,
}: DropdownChipDialogFooterProps) => {
  return (
    <StyledFooter>
      <Button onClick={onCancel} size="sm" variant="ghost">
        Cancel
      </Button>
      <Button onClick={onApply} size="sm" variant="primary">
        Apply
      </Button>
    </StyledFooter>
  );
};

export default DropdownChipDialogFooter;
