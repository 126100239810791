import { forwardRef, type ForwardRefRenderFunction } from "react";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";
import Text from "../Text/Text";
import {
  StyledChecked,
  StyledContent,
  StyledControl,
  StyledLabel,
  StyledRadioButton,
  StyledText,
  StyledUnchecked,
} from "./styled";

export type RadioButtonProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "type"
> & {
  "data-testid"?: string;
  hint?: React.ReactNode;
  label: React.ReactNode;
};

const RadioButton: ForwardRefRenderFunction<
  HTMLInputElement,
  RadioButtonProps
> = (
  { "data-testid": dataTestId, hint, label, ...props }: RadioButtonProps,
  ref,
) => (
  <StyledRadioButton data-testid={dataTestId} htmlFor={props.id}>
    <StyledHiddenInput
      {...props}
      ref={ref}
      type="radio"
      data-testid={dataTestId && `${dataTestId}-input`}
    />
    <StyledContent>
      <StyledControl data-testid={dataTestId && `${dataTestId}-control`}>
        <StyledChecked />
        <StyledUnchecked />
      </StyledControl>
      {(label || hint) && (
        <StyledText>
          {label && (
            <StyledLabel data-testid={dataTestId && `${dataTestId}-label`}>
              {label}
            </StyledLabel>
          )}
          {hint && (
            <Text size="footnote" variant="weak">
              {hint}
            </Text>
          )}
        </StyledText>
      )}
    </StyledContent>
  </StyledRadioButton>
);

export default Object.assign(forwardRef(RadioButton), {
  StyledChecked,
  StyledUnchecked,
});
