import styled from "styled-components";

const StyledSkeletonFlexWrapper = styled.div`
  flex: 1;
`;

interface SkeletonFlexWrapperProps {
  children?: React.ReactNode;
}

const SkeletonFlexWrapper = ({ children }: SkeletonFlexWrapperProps) => {
  return <StyledSkeletonFlexWrapper>{children}</StyledSkeletonFlexWrapper>;
};

export default SkeletonFlexWrapper;
