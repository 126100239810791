import { useEffect } from "react";

interface UseSyncCSSPropertyProps {
  name: string;
  ref?: React.RefObject<HTMLElement>;
  value?: string;
}

const useSyncCSSProperty = ({ name, ref, value }: UseSyncCSSPropertyProps) => {
  useEffect(() => {
    const element = ref?.current ?? document.documentElement;
    if (value) {
      element.style.setProperty(name, value);
    } else {
      element.style.removeProperty(name);
    }
    return () => {
      element.style.removeProperty(name);
    };
  }, [name, ref, value]);
};

export default useSyncCSSProperty;
