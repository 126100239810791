import styled, { css } from "styled-components";
import { Check } from "../../icons";
import fadeDownStyles from "../../lib/styled/fadeDownStyles";
import { mediumStyles } from "../Button/states";

export const StyledCheck = styled(Check)`
  visibility: hidden;
  height: 1.25rem;
  width: 1.25rem;
  margin-left: auto;
`;

export const StyledDropdownOption = styled.li`
  display: grid;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: start;
  text-align: left;
  gap: 0.375rem;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  cursor: default;

  &[data-focused="true"] {
    ${mediumStyles.background.hover}
  }

  &[data-selected="true"] {
    ${StyledCheck} {
      visibility: visible;
    }
  }

  &:not([data-disabled="true"]):active {
    ${mediumStyles.background.active}
  }

  &[data-disabled="true"] {
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
    cursor: not-allowed;
  }
`;

interface StyledMenuProps {
  isOpen: boolean;
  width: number;
}

const menuTransitionStyles = css`
  display: none;

  &.enter,
  &.enter-active,
  &.enter-done,
  &.exit {
    display: grid;
  }

  ${fadeDownStyles}
`;

export const StyledMenu = styled.ul<StyledMenuProps>`
  display: grid;
  padding: 0.25rem 0.25rem;

  ${(p) => p.theme.typography.variants.bodyDefault}
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  box-shadow:
    0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);

  overflow: auto;
  min-width: ${(p) => `max(${p.width}px, 6rem)`};

  ${menuTransitionStyles}
`;

interface StyledDropdownProps {
  isOpen: boolean;
}

export const StyledDropdown = styled.div<StyledDropdownProps>`
  position: relative;
  min-width: 0;
  width: 100%;
`;

export const StyledOptionChipGroup = styled.div`
  display: flex;
  padding-bottom: 0.25rem;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
