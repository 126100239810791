import styled from "styled-components";
import Spinner, { type SpinnerProps } from "../Spinner/Spinner";

const StyledFullScreenSpinner = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  color: ${(p) => p.theme.colors.neutrals.text};
`;

export interface FullScreenFlexSpinnerProps extends SpinnerProps {
  "data-testid"?: string;
}

const FullScreenSpinner = ({
  "data-testid": dataTestId,
  ...spinnerProps
}: FullScreenFlexSpinnerProps) => (
  <StyledFullScreenSpinner data-testid={dataTestId}>
    <Spinner
      {...spinnerProps}
      data-testid={dataTestId && `${dataTestId}-spinner`}
    />
  </StyledFullScreenSpinner>
);

export default FullScreenSpinner;
