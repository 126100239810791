import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import InputContainer, {
  type CommonInputStylesProps,
  type InputContainerProps,
  commonInputStyles,
} from "../InputContainer/InputContainer";

interface StyledButtonInputProps extends CommonInputStylesProps {
  isPlaceholder?: boolean;
}

export const StyledButtonInput = styled.button<StyledButtonInputProps>`
  ${buttonReset}
  ${commonInputStyles}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(p) =>
    p.isPlaceholder &&
    css`
      color: ${p.theme.colors.neutrals.textPlaceholder};
    `};

  cursor: default;
`;

type ButtonInputProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  InputContainerProps & { isPlaceholder?: boolean };

const ButtonInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonInputProps
> = (
  {
    children,
    "data-testid": dataTestId,
    disabled,
    iconEnd,
    iconStart,
    isPlaceholder,
    size,
    type = "button",
    validationStatus,
    ...htmlProps
  }: ButtonInputProps,
  ref,
) => {
  return (
    <InputContainer
      data-testid={dataTestId && `${dataTestId}-input-container`}
      disabled={disabled}
      iconEnd={iconEnd}
      iconStart={iconStart}
      size={size}
      validationStatus={validationStatus}
    >
      <StyledButtonInput
        {...htmlProps}
        data-testid={dataTestId && `${dataTestId}-button-input`}
        disabled={disabled}
        hasIconEnd={!!iconEnd}
        hasIconStart={!!iconStart}
        ref={ref}
        isPlaceholder={isPlaceholder}
        type={type}
        validationStatus={validationStatus}
      >
        {children || "\u200B"}
      </StyledButtonInput>
    </InputContainer>
  );
};

export default forwardRef(ButtonInput);
