import styled, { css } from "styled-components";
import { focusStyles } from "../Button/states";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";

const radioButtonSizeStyles = css`
  margin-top: 2px;
  width: 1rem;
  height: 1rem;
`;

export const StyledChecked = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderSelected};
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  color: ${(p) => p.theme.colors.neutrals.borderSelected};

  transition:
    border-color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0.5625rem;
    width: 0.5625rem;
    background-color: ${(p) => p.theme.colors.neutrals.borderSelected};
    border-radius: 50%;
  }
`;

export const StyledUnchecked = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};

  transition:
    border-color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
`;

export const StyledRadioButton = styled.label`
  display: block;
`;

export const StyledControl = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  ${radioButtonSizeStyles}
`;

export const StyledText = styled.div`
  display: grid;
  gap: 0.125rem;
  width: 100%;
`;

export const StyledLabel = styled.div`
  word-break: break-word;
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

export const StyledContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: start;

  ${StyledChecked} {
    display: none;
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:hover {
    ${StyledUnchecked},
    ${StyledChecked} {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    }

    ${StyledUnchecked} {
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumHover};
    }
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:active {
    ${StyledUnchecked} {
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundMediumActive};
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumActive};
    }
  }

  ${StyledHiddenInput}:disabled ~ & {
    cursor: not-allowed;

    ${StyledUnchecked} {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
      border-color: ${(p) => p.theme.colors.neutrals.borderDisabled};
    }
  }

  ${StyledHiddenInput}:focus-visible ~ & ${StyledControl} {
    ${focusStyles("ring")}
  }

  ${StyledHiddenInput}:checked ~ & {
    color: ${(p) => p.theme.colors.neutrals.borderSelected};

    ${StyledChecked} {
      display: flex;
    }

    ${StyledUnchecked} {
      display: none;
    }
  }

  ${StyledHiddenInput}:disabled ~ & {
    ${StyledChecked}, ${StyledUnchecked} {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
      border-color: ${(p) => p.theme.colors.neutrals.borderDisabled};
    }

    ${StyledLabel} {
      color: ${(p) => p.theme.colors.neutrals.textDisabled};
    }
  }
`;
