import type { OverlayTriggerState } from "@react-stately/overlays";
import type { Placement } from "@react-types/overlays";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import useIsMinWidth from "../../hooks/useIsMinWidth";
import fadeDownStyles from "../../lib/styled/fadeDownStyles";
import { useTheme } from "../../theme";
import { focusStyles } from "../Button/states";
import ModalOverlay from "../Modal/ModalOverlay";
import PopoverOverlay, { type PopoverSize } from "./PopoverOverlay";

const StyledPopoverModal = styled.div`
  position: relative;
  display: grid;
  min-width: 10rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  /* Make the contents of popovers scrollable */
  overflow: auto;

  ${fadeDownStyles}

  &:focus-visible {
    ${focusStyles("ring")}
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  }
`;

interface PopoverProps {
  children: React.ReactNode;
  "data-testid"?: string;
  isModalOnMobile?: boolean;
  maxHeight?: number;
  offset?: number;
  placement?: Placement;
  size?: PopoverSize;
  state: OverlayTriggerState;
  triggerRef: React.RefObject<HTMLButtonElement>;
  width?: React.CSSProperties["width"];
}

const Popover = ({
  children,
  "data-testid": dataTestId,
  isModalOnMobile,
  maxHeight,
  offset,
  placement = "bottom left",
  size = "md",
  state,
  triggerRef,
  width,
}: PopoverProps) => {
  const theme = useTheme();
  const popoverRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  if (!isTabletLandscapeAndAbove && isModalOnMobile) {
    return (
      <ModalOverlay
        data-testid={dataTestId}
        isDismissable
        isOpen={state.isOpen}
        onClose={state.close}
        size="md"
      >
        <CSSTransition
          appear
          in={state.isOpen}
          nodeRef={modalRef}
          timeout={theme.anim.duration.sm}
        >
          <StyledPopoverModal ref={modalRef}>{children}</StyledPopoverModal>
        </CSSTransition>
      </ModalOverlay>
    );
  }

  return (
    <CSSTransition
      appear
      in={state.isOpen}
      mountOnEnter
      nodeRef={popoverRef}
      timeout={theme.anim.duration.sm}
      unmountOnExit
    >
      <PopoverOverlay
        data-testid={dataTestId}
        maxHeight={maxHeight}
        offset={offset}
        placement={placement}
        ref={popoverRef}
        size={isTabletLandscapeAndAbove ? size : "md"}
        state={state}
        triggerRef={triggerRef}
        width={width}
      >
        {children}
      </PopoverOverlay>
    </CSSTransition>
  );
};

export default Popover;
