import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import StyledHiddenInput from "../../StyledHiddenInput/StyledHiddenInput";
import {
  type CheckboxSize,
  StyledCheckbox,
  StyledCheckboxChecked,
  StyledCheckboxUnchecked,
  StyledIconCheckbox,
} from "./styled";

export type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "type"
> & {
  "aria-label"?: string;
  "data-testid"?: string;
  label?: string;
  size?: CheckboxSize;
};

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  {
    "aria-label": ariaLabel,
    "data-testid": dataTestId,
    id,
    label,
    size = "md",
    ...props
  }: CheckboxProps,
  ref,
) => {
  return (
    <StyledIconCheckbox
      data-testid={dataTestId && `${dataTestId}`}
      title={label}
    >
      <StyledHiddenInput
        {...props}
        data-testid={dataTestId && `${dataTestId}-input`}
        id={id}
        ref={ref}
        type="checkbox"
        aria-label={ariaLabel}
      />
      <StyledCheckbox
        data-testid={dataTestId && `${dataTestId}-control`}
        size={size}
      >
        <StyledCheckboxChecked />
        <StyledCheckboxUnchecked />
      </StyledCheckbox>
    </StyledIconCheckbox>
  );
};

export default forwardRef(Checkbox);
