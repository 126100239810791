import { createContext, useContext, useMemo } from "react";
import type { UseBottomSheetResult } from "../../components/BottomSheet/useBottomSheet";
import type { UseSidebarResult } from "../../components/SideSheet/Sidebar/useSidebar";

interface SheetContextValue {
  bottomSheetProps: UseBottomSheetResult;
  sideSheetProps: UseSidebarResult;
}

const SheetContext = createContext<SheetContextValue | undefined>(undefined);

interface SheetProviderProps extends SheetContextValue {
  children?: React.ReactNode;
}

export const SheetProvider = ({
  bottomSheetProps,
  children,
  sideSheetProps,
}: SheetProviderProps) => {
  const sheetContextProviderValue = useMemo(
    () => ({ bottomSheetProps, sideSheetProps }),
    [bottomSheetProps, sideSheetProps],
  );

  return (
    <SheetContext.Provider value={sheetContextProviderValue}>
      {children}
    </SheetContext.Provider>
  );
};

export const useSheet = () => {
  const context = useContext(SheetContext);
  if (!context) {
    throw new Error(
      "useSheetContext must be used inside of a SheetContextProvider",
    );
  }

  return context;
};
