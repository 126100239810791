import React from "react";
import { useCollapse } from "react-collapsed";
import styled from "styled-components";
import { ExpandLess, ExpandMore } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { useTheme } from "../../theme";
import media from "../../theme/media";
import { focusStyles, weakStyles } from "../Button/states";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

const StyledLabel = styled.button`
  ${buttonReset}
  position: absolute;
  top: -0.625rem;
  left: 0;
  display: flex;
  margin-inline: -0.125rem;
  padding: 0 0.125rem 0 0.375rem;
  align-items: center;
  gap: 0.125rem;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.text};
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.md}ms ${p.theme.anim.curve}`},
    transform ${(p) => `${p.theme.anim.duration.md}ms ${p.theme.anim.curve}`};

  &:hover {
    ${weakStyles.background.hover}
  }

  &:active {
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledCollapsibleDrawerMenuSection = styled.div`
  position: relative;
  margin-top: 0.75rem;
  min-height: 0.875rem;
  border: 1px solid transparent;
  transition: border-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &[data-expanded="true"] {
    border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceDivider};
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;

    ${StyledLabel} {
      transform: translateX(0.5rem);
    }
  }
`;

const StyledPanel = styled.div`
  padding: 1.25rem 1rem 1rem 1rem;

  @media ${media.lg} {
    padding: 1.125rem 0.75rem 0.75rem 0.75rem;
  }
`;

interface CollapsibleMenuSectionProps {
  children?: React.ReactNode;
  label?: React.ReactNode;
}

const CollapsibleMenuSection = ({
  children,
  label,
}: CollapsibleMenuSectionProps) => {
  const theme = useTheme();

  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
  });

  return (
    <StyledCollapsibleDrawerMenuSection data-expanded={isExpanded}>
      {label && (
        <StyledLabel {...getToggleProps()}>
          {label}{" "}
          <TypographyIconWrapper
            icon={isExpanded ? ExpandLess : ExpandMore}
            size="caption"
            variant="neutral"
          />
        </StyledLabel>
      )}
      <div {...getCollapseProps()}>
        <StyledPanel>{children}</StyledPanel>
      </div>
    </StyledCollapsibleDrawerMenuSection>
  );
};

export default CollapsibleMenuSection;
