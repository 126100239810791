import { usePreventScroll } from "@react-aria/overlays";
import {
  type Dispatch,
  type RefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useBoolean, useOnClickOutside } from "usehooks-ts";
import useIsMinWidth from "../../../hooks/useIsMinWidth";

export const SIDEBAR_MODES = ["offscreen", "persistent"] as const;
export type SidebarMode = (typeof SIDEBAR_MODES)[number];

export type UseSidebarProps = {
  isCollapsed?: boolean;
  isOnscreen?: boolean;
  mode: SidebarMode;
};

export interface UseSidebarResult {
  isCollapsed: boolean;
  isOnscreen: boolean;
  mode: SidebarMode;
  ref: RefObject<HTMLDivElement>;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsOnscreen: Dispatch<SetStateAction<boolean>>;
  toggleIsCollapsed: () => void;
  toggleIsOnscreen: () => void;
}

const useSidebar = ({
  isCollapsed: defaultIsCollapsed,
  isOnscreen: defaultIsOnscreen,
  mode,
}: UseSidebarProps): UseSidebarResult => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    value: isCollapsed,
    setValue: setIsCollapsed,
    toggle: toggleIsCollapsed,
  } = useBoolean(defaultIsCollapsed);
  const {
    value: isOnscreen,
    setValue: setIsOnscreen,
    toggle: toggleIsOnscreen,
  } = useBoolean(defaultIsOnscreen);

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  useEffect(() => {
    setIsCollapsed(false);
    setIsOnscreen(false);
  }, [mode, setIsCollapsed, setIsOnscreen]);

  useEffect(() => {
    setIsCollapsed(!!defaultIsCollapsed);
  }, [defaultIsCollapsed, setIsCollapsed]);

  useEffect(() => {
    setIsOnscreen(!!defaultIsOnscreen);
  }, [defaultIsOnscreen, setIsOnscreen]);

  const handleClickOutside = useCallback(() => {
    if (isOnscreen && (mode === "offscreen" || !isTabletLandscapeAndAbove)) {
      setIsOnscreen(false);
    }
  }, [isOnscreen, isTabletLandscapeAndAbove, mode, setIsOnscreen]);

  useOnClickOutside(ref, handleClickOutside);
  usePreventScroll({
    isDisabled:
      !isOnscreen || (mode === "persistent" && isTabletLandscapeAndAbove),
  });

  return {
    isCollapsed: isTabletLandscapeAndAbove && isCollapsed,
    isOnscreen,
    mode,
    ref,
    setIsCollapsed,
    setIsOnscreen,
    toggleIsCollapsed,
    toggleIsOnscreen,
  };
};

export default useSidebar;
