import type { FormControlOption } from "../../types/design-system";
import Checkbox from "../Checkbox/Checkbox";
import { StyledFormControlList } from "./styled";

interface CheckboxGroupDialogListProps<T extends string> {
  columns?: number;
  items: FormControlOption<T>[];
  onChange: (value: T[]) => void;
  value: T[];
}

const CheckboxGroupDialogList = <T extends string>({
  columns = 1,
  items,
  onChange: _onChange,
  value,
}: CheckboxGroupDialogListProps<T>) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      _onChange([...value, event.currentTarget.value as T]);
    } else {
      _onChange(value.filter((v) => v !== event.currentTarget.value));
    }
  };

  return (
    <StyledFormControlList columns={columns}>
      {items.map((item) => (
        <Checkbox
          checked={value.includes(item.value)}
          key={item.value}
          onChange={onChange}
          label={item.label}
          value={item.value}
        />
      ))}
    </StyledFormControlList>
  );
};

export default CheckboxGroupDialogList;
