import { useNumberFormatter } from "@react-aria/i18n";
import { useSlider } from "@react-aria/slider";
import { useSliderState } from "@react-stately/slider";
import type { AriaSliderProps } from "@react-types/slider";
import React from "react";
import styled, { css } from "styled-components";
import SliderThumb, { type SliderVariant } from "./SliderThumb";

const StyledSlider = styled.div`
  display: grid;
  gap: 0.375rem;
  width: 100%;
`;

interface StyledTrackProps {
  isDisabled: boolean;
  thumbValue: number;
}

const StyledTrack = styled.div<StyledTrackProps>`
  height: 0.375rem;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  background-image: linear-gradient(
    to right,
    ${(p) => p.theme.colors.neutrals.backgroundInverse} 0%,
    ${(p) => p.theme.colors.neutrals.backgroundInverse} ${(p) => p.thumbValue}%,
    ${(p) => p.theme.colors.neutrals.backgroundMedium} ${(p) => p.thumbValue}%,
    ${(p) => p.theme.colors.neutrals.backgroundMedium} 100%
  );
  background-repeat: no-repeat;

  ${(p) =>
    p.isDisabled &&
    css`
      opacity: 0.4;
    `}
`;

const StyledDataList = styled.datalist`
  ${(p) => p.theme.typography.variants.footnote}
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledLabel = styled.label`
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

export interface SliderProps extends AriaSliderProps {
  "aria-label"?: string;
  children?: React.ReactNode;
  "data-testid"?: string;
  formatTooltip?: (value: number) => React.ReactNode;
  list?: string;
  tooltip?: boolean;
  variant?: SliderVariant;
}

const Slider = (props: SliderProps) => {
  const trackRef = React.useRef<HTMLDivElement>(null);

  const {
    "aria-label": ariaLabel,
    children,
    "data-testid": dataTestId,
    formatTooltip,
    id,
    label,
    list,
    tooltip,
    variant,
  } = props;

  const numberFormatter = useNumberFormatter();

  const state = useSliderState({ ...props, numberFormatter });

  const { groupProps, trackProps, labelProps } = useSlider(
    props,
    state,
    trackRef,
  );

  const thumbValuePercent = state.getValuePercent(state.getThumbValue(0)) * 100;

  return (
    <StyledSlider id={id} {...groupProps} data-testid={dataTestId}>
      {label ? <StyledLabel {...labelProps}>{label}</StyledLabel> : null}
      {(list || children) && (
        <StyledDataList id={list}>{children}</StyledDataList>
      )}
      <StyledTrack
        {...trackProps}
        data-testid={dataTestId && `${dataTestId}-track`}
        isDisabled={state.isDisabled}
        ref={trackRef}
        thumbValue={thumbValuePercent}
      >
        <SliderThumb
          aria-label={ariaLabel}
          data-testid={dataTestId && `${dataTestId}-thumb`}
          formatTooltip={formatTooltip}
          index={0}
          list={list}
          state={state}
          tooltip={tooltip}
          trackRef={trackRef}
          variant={variant}
        />
      </StyledTrack>
    </StyledSlider>
  );
};

export default Slider;
