import getEntries from "./getEntries";

export type RecursiveCloneParams<T> = {
  iteratee: (value: string, path: string) => string;
  path?: string;
  separator?: string;
  source: T;
};

const recursiveClone = <T extends DeeplyNestedRecord<T>>({
  iteratee,
  path,
  separator = "-",
  source,
}: RecursiveCloneParams<T>): T => {
  const result = {} as Partial<T>;

  for (const [key, value] of getEntries(source)) {
    const nextPath = path ? `${path}${separator}${key}` : `${key}`;
    if (typeof value === "string") {
      result[key] = iteratee(value, nextPath) as T[typeof key];
    } else {
      result[key] = recursiveClone({
        iteratee,
        path: nextPath,
        separator,
        source: value as DeeplyNestedRecord,
      }) as T[typeof key];
    }
  }

  return result as T;
};

export default recursiveClone;
