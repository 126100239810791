export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1080,
  xl: 1728,
  xxl: 2440,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export const borderRadiuses = {
  none: 0,
  sm: 4,
  base: 6,
  md: 6,
  lg: 8,
  xl: 12,
  "2xl": 16,
  "3xl": 24,
  "4xl": 48,
  full: 9999,
} as const;

export const boxShadows = {
  xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
  sm: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
  base: "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
  md: "0px 0.5px 1px rgba(0, 0, 0, 0.03), 0px 1.5px 2px rgba(0, 0, 0, 0.05), 0px 2.5px 4px rgba(0, 0, 0, 0.08)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  "3xl":
    "0 0 0 1px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.2), 0 15px 40px rgba(0, 0, 0, 0.4)",
  cardExtraWeak: "0px 0px 13px -4px rgba(16, 24, 40, 0.08)",
  cardWeak:
    "rgba(0, 0, 0, 0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.04) 0px 0px 4px -1px",
} as const;

export const anim = {
  curve: "cubic-bezier(0.3, 0, 0, 1)",
  duration: {
    // Use this for most transitions
    sm: 150,
    // Use these with care in exit transitions
    md: 300,
    lg: 600,
  } as const,
} as const;

export const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export type FontWeightKey = keyof typeof fontWeights;

export const fontStyles = {
  normal: "normal",
  italic: "italic",
} as const;

export type FontStyleKey = keyof typeof fontStyles;

export const fontCases = {
  none: "none",
  uppercase: "uppercase",
} as const;

export type FontCaseKey = keyof typeof fontCases;

export const fontTracking = {
  normal: "normal",
  medium: "0.01em",
  wide: "0.05em",
} as const;

export type FontTrackingKey = keyof typeof fontTracking;

export const typographySizes = [
  "h1",
  "h2",
  "h3",
  "buttonDefault",
  "buttonSm",
  "buttonLg",
  "eyebrowLg",
  "eyebrowDefault",
  "groupLabel",
  "label",
  "subtitleLg",
  "subtitleMd",
  "subtitleSm",
  "bodyLg",
  "bodyLgStrong",
  "bodyDefault",
  "bodyDefaultStrong",
  "footnote",
  "footnoteStrong",
  "caption",
  "captionStrong",
] as const;

export type TypographySize = (typeof typographySizes)[number];

export const typographyCSSProperties = [
  "font-size", // font-size
  "line-height", // line-height
  "text-transform", // fontCases
  "letter-spacing", // fontTracking
  "font-style", // fontStyles
  "font-weight", // fontWeights
] as const;

export type TypographyCSSProperty = (typeof typographyCSSProperties)[number];

export const badgeStyles = ["modern", "classic"] as const;
export type BadgeStyle = (typeof badgeStyles)[number];

type BadgeThemeConfig = {
  badgeStyle: BadgeStyle;
};

export const badges: BadgeThemeConfig = {
  badgeStyle: "classic",
};
