import {
  Tab as ReactTab,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
  Tabs as ReactTabs,
  type TabProps as ReactTabProps,
  type TabsProps as ReactTabsProps,
} from "react-tabs";
import styled, { css } from "styled-components";
import media from "../../theme/media";
import { focusStyles, mediumStyles } from "../Button/states";
import { tabListStyles } from "./styled";

const SELECTED_TAB = "react-tabs__tab--selected";
const SELECTED_TAB_PANEL = "react-tabs__tab-panel--selected";

const DISABLED_TAB = "react-tabs__tab--disabled";

type TabListVariant = "default" | "transparent";

interface TabListProps {
  isSticky?: boolean;
  variant?: TabListVariant;
}

export const TabList = styled(ReactTabList)<TabListProps>`
  ${(p) =>
    p.isSticky &&
    css`
      position: sticky;
      top: 0;
    `}

  ${tabListStyles}
`;

const svgSize = (size: number) => css`
  svg {
    width: ${size}px;
    height: ${size}px;
    margin: auto 5px auto;
  }
`;

interface StyledTabProps {
  $flex?: boolean;
}

const StyledTab = styled(ReactTab)<StyledTabProps>`
  display: flex;
  ${(p) =>
    p.$flex &&
    css`
      flex: 1;
    `}
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.buttonDefault}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  text-align: center;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  cursor: pointer;

  &.${SELECTED_TAB} {
    background-color: ${(p) => p.theme.colors.neutrals.background};
    color: ${(p) => p.theme.colors.neutrals.text};
    box-shadow: ${(p) => p.theme.boxShadows.sm};
  }

  &.${DISABLED_TAB} {
    cursor: not-allowed;
    margin-top: 0;
  }

  &:hover:not(.${SELECTED_TAB}) {
    ${mediumStyles.background.hover}
  }

  &:focus-visible {
    ${focusStyles("ring")}
    z-index: 1;
  }

  &:active:not(.${SELECTED_TAB}) {
    ${mediumStyles.background.active}
  }

  ${svgSize(24)}
`;

interface TabProps extends Omit<ReactTabProps, "as" | "ref" | "size"> {
  children: React.ReactNode;
  "data-testid"?: string;
  disabled?: boolean;
  icon?: ReactSVGComponent;
  id?: string;
  selected?: boolean;
  flex?: boolean;
}

export const Tab = Object.assign(
  ({
    children,
    "data-testid": dataTestId,
    disabled,
    icon: Icon,
    id,
    flex,
    ...props
  }: TabProps) => {
    return (
      <StyledTab
        {...props}
        id={id}
        disabled={disabled}
        $flex={flex}
        data-testid={dataTestId}
      >
        {Icon && <Icon data-testid={dataTestId && `${dataTestId}-icon`} />}
        {children}
      </StyledTab>
    );
  },
  { tabsRole: "Tab" },
);

export const StyledTabPanel = styled(ReactTabPanel)`
  position: relative;
  display: none;
  flex: 1;

  &.${SELECTED_TAB_PANEL} {
    display: flex;
    flex-direction: column;
  }

  @media ${media.lg} {
    position: static;
  }
`;

const StyledTabPanelInner = styled.div`
  width: 100%;
  flex: 1;
`;

interface TabPanelProps {
  children?: React.ReactNode;
}

export const TabPanel = Object.assign(
  ({ children, ...props }: TabPanelProps) => (
    <StyledTabPanel {...props}>
      <StyledTabPanelInner>{children}</StyledTabPanelInner>
    </StyledTabPanel>
  ),
  { tabsRole: "TabPanel" },
);

interface StyledTabsProps {
  $isScrollable?: boolean;
}

export const StyledTabs = styled(ReactTabs)<StyledTabsProps>`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${StyledTabPanelInner} {
    ${(p) =>
      p.$isScrollable
        ? css`
            @media ${media.lg} {
              position: absolute;
              inset: 0;
              overflow: auto;
            }
          `
        : css`
            display: flex;
            flex-direction: column;
            flex: 1;
          `}
  }
`;

interface TabsProps extends Omit<ReactTabsProps, "as" | "size"> {
  isScrollable?: boolean;
}

export const Tabs = ({ isScrollable, ...props }: TabsProps) => {
  return <StyledTabs {...props} $isScrollable={isScrollable} />;
};

export const TabTitle = styled.h4`
  padding: 0 4px;
  ${(p) => p.theme.typography.variants.buttonSm}
`;

export const TabGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-flow: row dense;
  row-gap: 1rem;
`;

export const TabContent = styled.div`
  padding: 1rem 0.75rem;
  display: grid;
  gap: 1rem;
`;

export const TabFooter = styled.div`
  position: sticky;
  margin-top: auto;
  bottom: 0.5rem;

  @media ${media.lg} {
    padding: 0.25rem;
  }
`;
