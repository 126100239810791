import styled, { css } from "styled-components";
import media from "../../theme/media";
import type { Size, SizeNumberMap } from "../../types/design-system";

const spinnerSizeStyles: SizeNumberMap = {
  sm: 20,
  md: 32,
  lg: 40,
  xl: 48,
};

const lgSpinnerSizeStyles: SizeNumberMap = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 40,
};

const xlSpinnerSizeStyles: SizeNumberMap = {
  sm: 24,
  md: 40,
  lg: 48,
  xl: 56,
};

const spinnerSizeStyle = (size: number) => `
  width: ${size}px;
  height: ${size}px;
`;

export interface StyledSpinnerProps {
  size: Size;
  spinnerSize?: number;
}

export const StyledSpinner = styled.div<StyledSpinnerProps>`
  display: inline-block;
  position: relative;

  ${(p) =>
    p.spinnerSize
      ? css`
          width: ${`${p.spinnerSize}px`};
          height: ${`${p.spinnerSize}px`};
        `
      : css`
          ${spinnerSizeStyle(spinnerSizeStyles[p.size])}
          @media ${media.lg} {
            ${spinnerSizeStyle(lgSpinnerSizeStyles[p.size])}
          }

          @media ${media.xxl} {
            ${spinnerSizeStyle(xlSpinnerSizeStyles[p.size])}
          }
        `}

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid currentColor;
    border-radius: ${(p) => p.theme.borderRadiuses.full}px;
    animation: spin 0.8s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
    border-color: currentColor transparent transparent transparent;

    @media ${media.lg} {
      border-width: 2px;
    }

    @media ${media.xxl} {
      border-width: 3px;
    }
  }

  div:nth-child(1) {
    animation-delay: -0.6s;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface SpinnerProps {
  "data-testid"?: string;
  size?: Size;
  spinnerSize?: number;
}

const Spinner = ({
  "data-testid": dataTestId,
  size = "md",
  spinnerSize,
}: SpinnerProps) => {
  return (
    <StyledSpinner
      size={size}
      spinnerSize={spinnerSize}
      data-testid={dataTestId}
    >
      <div />
      <div />
    </StyledSpinner>
  );
};

export default Spinner;
