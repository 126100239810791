import type { UserClaims } from "@okta/okta-auth-js";
import isObject from "lodash/isObject";

export const convertRolesToString = (roles?: UserClaims["groups"]) => {
  if (typeof roles === "string") {
    return roles;
  }

  if (Array.isArray(roles)) {
    if (roles.some(isObject)) {
      return "";
    }
    return roles.join(",");
  }
  return "";
};
