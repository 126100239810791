import styled, { css } from "styled-components";
import media from "../../../theme/media";
import type { SidebarMode } from "./useSidebar";

const sidebarMinWidth = 312;
const sidebarMinWidthXxl = 376;

export const SIDEBAR_POSITIONS = ["start", "end"] as const;
export type SidebarPosition = (typeof SIDEBAR_POSITIONS)[number];

const SIDEBAR_TRANSFORM_POSITIONS: Record<SidebarPosition, string> = {
  start: "-100%",
  end: "100%",
};

const SIDEBAR_INSETS: Record<SidebarPosition, string> = {
  start: "0 auto 0 0",
  end: "0 0 0 auto",
};

export interface StyledSidebarProps {
  isOnscreen?: boolean;
  $mode: SidebarMode;
  position: SidebarPosition;
}

const offscreenStyles = (p: StyledSidebarProps) => css`
  transform: translateX(${SIDEBAR_TRANSFORM_POSITIONS[p.position]});
`;

const persistentStyles = css`
  @media ${media.lg} {
    position: sticky;
    inset: auto;
    top: 0;
    transform: none;
  }
`;

export const StyledSidebar = styled.div<StyledSidebarProps>`
  position: fixed;
  inset: ${(p) => SIDEBAR_INSETS[p.position]};
  transition: transform
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  ${(p) => !p.isOnscreen && offscreenStyles(p)}
  ${(p) => p.$mode === "persistent" && persistentStyles}
`;

interface StyledSidebarContainerProps {
  isCollapsed?: boolean;
  position: SidebarPosition;
}

const openContainerStyles = css`
  width: ${sidebarMinWidth}px;

  @media ${media.xxl} {
    width: ${sidebarMinWidthXxl}px;
  }
`;

const collapsedContainerStyles = css`
  width: ${sidebarMinWidth}px;

  @media ${media.lg} {
    width: 80px;
  }

  @media ${media.xxl} {
    width: 88px;
  }
`;

const startBorders = css`
  border-right: 1px solid
    ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
`;

const endBorders = css`
  border-left: 1px solid ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
`;

export const StyledSidebarContainer = styled.div<StyledSidebarContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  ${(p) => (p.isCollapsed ? collapsedContainerStyles : openContainerStyles)}
  ${(p) => (p.position === "end" ? endBorders : startBorders)}
  background-color: ${(p) => p.theme.colors.neutrals.background};
  transition: width
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  overflow-x: hidden;
`;

interface StyledSidebarContentProps {
  isCollapsed?: boolean;
}

const openContentsStyles = css`
  min-width: ${sidebarMinWidth - 2}px;

  @media ${media.xxl} {
    min-width: ${sidebarMinWidthXxl - 2}px;
  }
`;

export const StyledSidebarContent = styled.div<StyledSidebarContentProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(p) => !p.isCollapsed && openContentsStyles}
`;

export const StyledSidebarAdornment = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  display: grid;
  align-items: start;
  grid-auto-flow: column;
  pointer-events: none;
  overflow: hidden;
`;
