export const black = {
  black100: "#21272C",
  black95: "rgba(33, 39, 44, 0.95)",
  black75: "rgba(33, 39, 44, 0.75)",
  black50: "rgba(33, 39, 44, 0.50)",
  black25: "rgba(33, 39, 44, 0.25)",
  black10: "rgba(33, 39, 44, 0.10)",
  black5: "rgba(33, 39, 44, 0.05)",
} as const;

export const white = {
  white100: "#FFFFFF",
  white75: "rgba(255, 255, 255, 0.75)",
  white50: "rgba(255, 255, 255, 0.50)",
  white25: "rgba(255, 255, 255, 0.25)",
  white20: "rgba(255, 255, 255, 0.20)",
  white10: "rgba(255, 255, 255, 0.10)",
  white5: "rgba(255, 255, 255, 0.05)",
} as const;

export const grey = {
  grey50: "#F9F9F9",
  grey100: "#F4F4F4",
  grey200: "#E9E9E9",
  grey300: "#D3D3D3",
  grey400: "#B7B7B7",
  grey500: "#898989",
  grey600: "#706F6F",
  grey700: "#5E5D5D",
  grey800: "#494949",
  grey900: "#272626",
} as const;

export const charcoal = {
  charcoal25: "#F8F9FA",
  charcoal50: "#F1F3F4",
  charcoal100: "#DEE2E6",
  charcoal200: "#C5CCD3",
  charcoal300: "#A9B3BD",
  charcoal400: "#8694A2",
  charcoal500: "#738494",
  charcoal600: "#606F7E",
  charcoal700: "#505C69",
  charcoal800: "#414B56",
  charcoal900: "#2D343C",
} as const;

export const manualBlue = {
  manualBlue50: "#E1EDFF",
  manualBlue100: "#BED8FF",
  manualBlue200: "#98C1FF",
  manualBlue300: "#6CA6FF",
  manualBlue400: "#408BFF",
  manualBlue500: "#106EFF",
  manualBlue600: "#0059E2",
  manualBlue700: "#004ABC",
  manualBlue800: "#003C96",
  manualBlue900: "#002B6E",
} as const;

export const navy = {
  navy50: "#eceff7",
  navy100: "#d8dfef",
  navy200: "#bec8e3",
  navy300: "#9fafd6",
  navy400: "#8296ca",
  navy500: "#667fbe",
  navy600: "#4c69b3",
  navy700: "#405896",
  navy800: "#34487b",
  navy900: "#253256",
} as const;

export const yellow = {
  yellow50: "#FFEBB1",
  yellow100: "#FFD14F",
  yellow200: "#F3B400",
  yellow300: "#D39C00",
  yellow400: "#B58600",
  yellow500: "#997100",
  yellow600: "#805F00",
  yellow700: "#6A4E00",
  yellow800: "#543F00",
  yellow900: "#3D2D00",
} as const;

export const orange = {
  orange50: "#FDF1E9",
  orange100: "#FADDCA",
  orange200: "#FADDCA",
  orange300: "#F09F67",
  orange400: "#EA7B2E",
  orange500: "#D16215",
  orange600: "#B05212",
  orange700: "#93450F",
  orange800: "#79380C",
  orange900: "#542709",
} as const;

export const mint = {
  mint25: "#EAF9F1",
  mint50: "#D5F4E4",
  mint75: "#D4ECE0",
  mint100: "#9DE6C0",
  mint200: "#5BD596",
  mint300: "#30BC73",
  mint400: "#29A162",
  mint500: "#238853",
  mint600: "#1D7246",
  mint700: "#185E3A",
  mint800: "#134B2E",
  mint900: "#0E3621",
} as const;

export const green = {
  green50: "#F0F5F2",
  green100: "#D8E6DE",
  green200: "#B8D2C3",
  green300: "#95BCA5",
  green400: "#71A586",
  green500: "#5A8E6F",
  green600: "#4C775E",
  green700: "#3F634E",
  green800: "#335140",
  green900: "#24382C",
} as const;

export const purple = {
  purple50: "#F3F2F7",
  purple100: "#E2E0EC",
  purple200: "#CDC8DD",
  purple300: "#B4ADCD",
  purple400: "#9C93BD",
  purple500: "#857BAD",
  purple600: "#7165A0",
  purple700: "#5E5387",
  purple800: "#4D446F",
  purple900: "#352F4D",
} as const;

export const red = {
  red50: "#FFF0F0",
  red100: "#FFDBDB",
  red200: "#FFBCBC",
  red300: "#FF9696",
  red400: "#FF6969",
  red500: "#FF2525",
  red600: "#DF0000",
  red700: "#BB0000",
  red800: "#9B0000",
  red900: "#6F0000",
} as const;

export const pink = {
  pink50: "#FCF0F5",
  pink100: "#F8DBE8",
  pink200: "#F2BDD4",
  pink300: "#EB9ABD",
  pink400: "#E475A5",
  pink500: "#DC4D8B",
  pink600: "#CC286E",
  pink700: "#AB215D",
  pink800: "#8D1B4C",
  pink900: "#631336",
} as const;

export const blue = {
  socialBlue: "#0073DE",
} as const;

const palette = {
  ...black,
  ...charcoal,
  ...grey,
  ...manualBlue,
  ...mint,
  ...green,
  ...orange,
  ...purple,
  ...red,
  ...white,
  ...yellow,
  ...pink,
  ...navy,
  ...blue,
};

export default palette;
