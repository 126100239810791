import { useTheme } from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import type { Breakpoint } from "../theme";

const useIsMinWidth = (breakpoint: Breakpoint): boolean => {
  const theme = useTheme();
  const matches = useMediaQuery(
    `(min-width: ${
      breakpoint === "xxl" && !theme.isLeanBack
        ? 9999
        : theme.breakpoints[breakpoint]
    }px)`,
  );
  return matches;
};

export default useIsMinWidth;
