import React from "react";
import styled, { css } from "styled-components";
import media from "../../theme/media";
import Text from "../Text/Text";

type FieldGroupGapSize = "sm" | "md";

interface StyledFieldGroupProps {
  gap: FieldGroupGapSize;
}

const StyledFieldGroup = styled.div<StyledFieldGroupProps>`
  display: grid;
  ${(p) =>
    p.gap === "sm"
      ? css`
          gap: 0.25rem;
        `
      : css`
          gap: 0.5rem;
        `}
`;

interface StyledHeaderProps {
  alignActions?: React.CSSProperties["alignItems"];
}

const StyledHeader = styled.div<StyledHeaderProps>`
  display: flex;

  ${(p) =>
    p.alignActions &&
    css`
      @media ${media.lg} {
        align-items: ${p.alignActions};
      }
    `}
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-left: auto;
`;

/**
 * Used to space out "block" content from other items in the field group. Block
 * content such as alerts, images, charts should have a bit more spacing applied
 * to them to look right amongst typographical elements.
 */
const StyledOffset = styled.div`
  padding-top: 0.25rem;
`;

interface StyledBodyProps {
  constrain?: boolean;
}

const StyledBody = styled.div<StyledBodyProps>`
  display: grid;
  gap: 0.75rem;
  ${(p) =>
    p.constrain &&
    css`
      max-width: 32rem;
    `}
`;

type FieldGroupSize = "sm" | "md";

interface FieldGroupProps {
  alignActions?: React.CSSProperties["alignItems"];
  actions?: React.ReactNode;
  children?: React.ReactNode;
  /**
   * Applies a max width to avoid content becoming too long. Should match max
   * width of other block constrained content such as alerts.
   */
  constrain?: boolean;
  /**
   * Use `sm` within drawers, popovers, and `sm` modals. Use `md` elsewhere.
   */
  size?: FieldGroupSize;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const FieldGroup = ({
  actions,
  alignActions,
  children,
  constrain,
  size = "md",
  subtitle,
  title,
}: FieldGroupProps) => {
  // Use a larger gap when there is block content such as an image or alert
  // block in the FieldGroup, or when there is a subtitle provided
  let gap: FieldGroupGapSize = "sm";
  if (subtitle) {
    gap = "md";
  }

  return (
    <StyledFieldGroup gap={gap}>
      {title && (
        <StyledHeader alignActions={alignActions}>
          <Text size={size === "sm" ? "subtitleSm" : "groupLabel"}>
            {title}
            {subtitle && (
              <Text size="footnote" variant="weak">
                {subtitle}
              </Text>
            )}
          </Text>
          {actions && <StyledActions>{actions}</StyledActions>}
        </StyledHeader>
      )}
      <StyledBody constrain={constrain}>{children}</StyledBody>
    </StyledFieldGroup>
  );
};

export default Object.assign(FieldGroup, {
  Offset: StyledOffset,
});
