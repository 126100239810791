import { useButton } from "@react-aria/button";
import { useOverlayTrigger, type Placement } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import React, { useRef } from "react";
import Popover from "./Popover";
import PopoverDialog from "./PopoverDialog";

type RenderTriggerProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  "data-testid"?: string;
  ref: React.RefObject<HTMLButtonElement>;
};

interface PopoverDialogTriggerProps {
  children?: React.ReactNode;
  placement?: Placement;
  renderTrigger: (props: RenderTriggerProps) => React.ReactNode;
  title: React.ReactNode;
  "data-testid"?: string;
}

const PopoverDialogTrigger = ({
  children,
  placement,
  renderTrigger,
  title,
  "data-testid": dataTestId,
}: PopoverDialogTriggerProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const triggerState = useOverlayTriggerState({});

  const { overlayProps, triggerProps } = useOverlayTrigger(
    {
      type: "dialog",
    },
    triggerState,
    triggerRef,
  );

  const { buttonProps } = useButton(triggerProps, triggerRef);

  return (
    <>
      {renderTrigger({
        ref: triggerRef,
        ...buttonProps,
        ...(dataTestId ? { "data-testid": `${dataTestId}-trigger` } : {}),
      })}
      <Popover
        {...overlayProps}
        isModalOnMobile
        placement={placement}
        state={triggerState}
        triggerRef={triggerRef}
        data-testid={dataTestId && `${dataTestId}-popover`}
      >
        <PopoverDialog onClose={triggerState.close} title={title}>
          {children}
        </PopoverDialog>
      </Popover>
    </>
  );
};

export default PopoverDialogTrigger;
