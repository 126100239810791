import { useOktaAuth } from "@okta/okta-react";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  boot as bootIntercom,
  load as loadIntercom,
  update as updateIntercom,
} from "../../thirdParty/intercom/intercom-boot";
import useUserEmail from "../useUserEmail";
import useUserRoles from "../useUserRoles";
import useUsername from "../useUsername";
import { convertRolesToString } from "./convertRolesToString";
import { useIntercomUserHash } from "./useIntercomUserHash";

const { publicRuntimeConfig } = getConfig();
const { INTERCOM_APP_ID } = publicRuntimeConfig;

const useIntercom = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated;

  const userEmail = useUserEmail();
  const userName = useUsername();
  const roles = useUserRoles();
  const router = useRouter();
  const [isIntercomLoaded, setIntercomLoaded] = useState(false);
  const userHash = useIntercomUserHash();

  useEffect(() => {
    // Disabled in environments without App ID -- local devs
    if (!INTERCOM_APP_ID) {
      return;
    }
    loadIntercom();
    setIntercomLoaded(true);
  }, [isIntercomLoaded]);

  useEffect(() => {
    if (
      !isAuthenticated ||
      !isIntercomLoaded ||
      !userHash ||
      !INTERCOM_APP_ID
    ) {
      return;
    }
    const rolesString = convertRolesToString(roles);
    bootIntercom({
      name: userName,
      email: userEmail,
      roles: rolesString,
      user_hash: userHash,
    });
  }, [isAuthenticated, userEmail, userName, roles, isIntercomLoaded, userHash]);

  useEffect(() => {
    // Disabled in environments without App ID -- local devs
    if (!INTERCOM_APP_ID) {
      return;
    }
    const handleRouteChange = () => {
      updateIntercom();
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

export default useIntercom;
