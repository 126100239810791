import { css } from "styled-components";

const UNDERLAY_BACKGROUND_COLOR_PROPERTY = "--underlay-background-color";

const baseUnderlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(${UNDERLAY_BACKGROUND_COLOR_PROPERTY});
  overflow-y: auto;
  overflow-x: hidden;

  &.enter,
  &.appear {
    background-color: rgba(33, 39, 44, 0);
  }

  &.enter-active,
  &.appear-active {
    background-color: var(${UNDERLAY_BACKGROUND_COLOR_PROPERTY});
    transition: background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit {
    background-color: var(${UNDERLAY_BACKGROUND_COLOR_PROPERTY});
  }

  &.exit-active {
    background-color: rgba(33, 39, 44, 0);
    transition: background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  .modal[data-overlay-container][aria-hidden="true"] & {
    background-color: transparent;
    transition: none;
  }

  .modal[data-overlay-container][aria-hidden="true"]
    + .modal[data-overlay-container]
    & {
    background-color: var(${UNDERLAY_BACKGROUND_COLOR_PROPERTY});
    transition: none;
  }
`;

const underlayStyles = css`
  ${(p) =>
    `${UNDERLAY_BACKGROUND_COLOR_PROPERTY}: ${p.theme.colors.neutrals.backdropWeak}`};
  ${baseUnderlayStyles}
`;

export const strongUnderlayStyles = css`
  ${(p) =>
    `${UNDERLAY_BACKGROUND_COLOR_PROPERTY}: ${p.theme.colors.neutrals.backdrop}`};
  ${baseUnderlayStyles}
`;

export default underlayStyles;
