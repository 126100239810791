import { mergeRefs } from "@kablamo/kerosene-ui";
import { Overlay, usePopover, type Placement } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import fadeDownStyles from "../../lib/styled/fadeDownStyles";
import { focusStyles } from "../Button/states";

export const POPOVER_SIZES = ["md", "lg", "xl"] as const;

export type PopoverSize = (typeof POPOVER_SIZES)[number];

export type PopoverSizeStyleMap = Record<PopoverSize, ReturnType<typeof css>>;

const popoverSizeStyles: PopoverSizeStyleMap = {
  md: css`
    width: 19rem;
  `,
  lg: css`
    width: 26rem;
  `,
  xl: css`
    width: 36rem;
  `,
};

interface StyledPopoverOverlayProps {
  size: PopoverSize;
  width?: number | string;
}

const StyledPopoverOverlay = styled.div<StyledPopoverOverlayProps>`
  position: relative;
  display: grid;
  min-width: 10rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  box-shadow:
    0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
  /* Make the contents of popovers scrollable */
  overflow: auto;

  ${fadeDownStyles}

  &:focus-visible {
    ${focusStyles("ring")}
  }

  ${(p) => !p.width && popoverSizeStyles[p.size]}

  ${(p) =>
    typeof p.width === "number" &&
    css`
      width: ${p.width}px;
    `}

    ${(p) =>
    typeof p.width === "string" &&
    css`
      width: ${p.width};
    `}
`;

const StyledUnderlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${(p) => p.theme.zIndexes.popover};
`;

interface PopoverOverlayProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  maxHeight?: number;
  offset?: number;
  placement: Placement;
  size: PopoverSize;
  state: OverlayTriggerState;
  triggerRef: React.RefObject<HTMLButtonElement>;
  width?: number | string;
}

const PopoverOverlay: ForwardRefRenderFunction<
  HTMLDivElement,
  PopoverOverlayProps
> = (
  {
    children,
    maxHeight,
    offset = 8,
    placement,
    size,
    state,
    triggerRef,
    width,
    "data-testid": dataTestId,
  }: PopoverOverlayProps,
  ref,
) => {
  const popoverRef = React.useRef<HTMLDivElement>(null);

  const { popoverProps, underlayProps } = usePopover(
    {
      triggerRef,
      maxHeight,
      offset,
      popoverRef,
      placement,
    },
    state,
  );

  const setRef = mergeRefs(ref, popoverRef);

  // In the case where a popover is contained within another clickable element,
  // e.g. a clickable table row, when clicking on the underlay to close the
  // popover the event would bubble up to trigger the link click.
  const onUnderlayClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  return (
    <Overlay>
      <StyledUnderlay {...underlayProps} onClick={onUnderlayClick} />
      <StyledPopoverOverlay
        {...popoverProps}
        ref={setRef}
        width={width}
        data-testid={dataTestId}
        size={size}
      >
        {children}
      </StyledPopoverOverlay>
    </Overlay>
  );
};

export default forwardRef(PopoverOverlay);
