import { mergeRefs } from "@kablamo/kerosene-ui";
import { FocusScope } from "@react-aria/focus";
import { OverlayContainer, useOverlayPosition } from "@react-aria/overlays";
import type { Placement } from "@react-types/overlays";
import { useSelect, type UseSelectProps } from "downshift";
import React, { forwardRef, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { ArrowDropDown } from "../../../icons";
import { useTheme } from "../../../theme";
import type { FormControlOption } from "../../../types/design-system";
import ButtonInput from "../../ButtonInput/ButtonInput";
import Field from "../../Field/Field";
import type {
  InputSize,
  ValidationStatus,
} from "../../InputContainer/InputContainer";
import {
  DropdownOptionItem,
  defaultRenderOption,
  type RenderOptionProps,
} from "../DropdownOptionItem";
import { StyledDropdown, StyledMenu } from "../styled";

export interface SelectProps<T extends string = string> {
  "aria-label"?: string;
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  error?: HookFormFieldError | string;
  id?: string;
  isLoading?: boolean;
  label?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange?: (value: FormControlOption<T> | null) => void;
  options: FormControlOption<T>[];
  renderOption?: (props: RenderOptionProps) => React.ReactNode;
  placeholder?: string;
  placement?: Placement;
  size?: InputSize;
  validationStatus?: ValidationStatus;
  value?: FormControlOption<T> | null;
}

const Select = <T extends string = string>(
  {
    "aria-label": ariaLabel,
    constrain,
    "data-testid": dataTestId,
    disabled,
    error,
    id,
    isLoading,
    label,
    onBlur,
    onChange,
    options,
    placeholder,
    placement = "bottom left",
    renderOption = defaultRenderOption,
    size,
    validationStatus,
    value,
  }: SelectProps<T>,
  _ref: React.Ref<HTMLButtonElement>,
) => {
  const theme = useTheme();

  const targetRef = useRef<HTMLButtonElement>(null);
  const triggerRef = mergeRefs(_ref, targetRef);

  const overlayRef = useRef<HTMLUListElement>(null);

  const onSelectedItemChange: UseSelectProps<
    FormControlOption<T>
  >["onSelectedItemChange"] = (event) => {
    onChange?.(event.selectedItem ?? null);
  };

  const selectedIndex = value
    ? options.findIndex((option) => option.value === value.value)
    : 0;

  const {
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    selectedItem,
  } = useSelect({
    id,
    defaultHighlightedIndex: selectedIndex !== -1 ? selectedIndex : 0,
    isItemDisabled: (item) => !!item.disabled,
    items: options,
    onSelectedItemChange,
    selectedItem: value ?? null,
  });

  const { overlayProps: positionProps } = useOverlayPosition({
    containerPadding: 24,
    isOpen,
    offset: 8,
    overlayRef,
    placement,
    targetRef,
  });

  const { observe: dropdownRef, width } = useDimensions();

  const content = (
    <StyledDropdown isOpen={isOpen} data-testid={dataTestId} ref={dropdownRef}>
      <ButtonInput
        iconEnd={ArrowDropDown}
        isPlaceholder={!selectedItem}
        size={size}
        type="button"
        validationStatus={validationStatus}
        aria-label={ariaLabel}
        data-testid={dataTestId && `${dataTestId}-button-input`}
        {...getToggleButtonProps({ disabled, ref: triggerRef })}
      >
        {selectedItem?.label || placeholder}
      </ButtonInput>
      <OverlayContainer>
        <FocusScope>
          <CSSTransition
            in={isOpen}
            nodeRef={overlayRef}
            timeout={theme.anim.duration.sm}
          >
            <StyledMenu
              {...positionProps}
              {...getMenuProps({ onBlur, ref: overlayRef })}
              aria-hidden={!isOpen}
              data-testid={dataTestId && `${dataTestId}-menu`}
              isOpen={isOpen}
              width={width}
            >
              {isLoading ? (
                <DropdownOptionItem
                  disabled
                  focused={false}
                  hasSelection={false}
                  selected={false}
                >
                  Loading options...
                </DropdownOptionItem>
              ) : (
                options.map((option, index) => (
                  <React.Fragment key={`${option.value}`}>
                    {renderOption({
                      ...(dataTestId && {
                        "data-testid": `${dataTestId}-${option.value}`,
                      }),
                      disabled: !!option.disabled,
                      focused: highlightedIndex === index,
                      hasSelection: !!selectedItem,
                      option,
                      selected: selectedItem?.value === option.value,
                      itemProps: getItemProps({
                        item: option,
                        index,
                      }),
                    })}
                  </React.Fragment>
                ))
              )}
            </StyledMenu>
          </CSSTransition>
        </FocusScope>
      </OverlayContainer>
    </StyledDropdown>
  );

  return label ? (
    <Field
      {...getLabelProps()}
      constrain={constrain}
      error={error}
      label={label}
    >
      {content}
    </Field>
  ) : (
    <>{content}</>
  );
};

// Using type assertion to allow using a generic with `forwardRef`
// @see https://stackoverflow.com/a/58473012

export default forwardRef(Select) as <T extends string = string>(
  p: SelectProps<T> & { ref?: React.Ref<HTMLButtonElement> },
) => React.ReactElement;
