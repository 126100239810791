import { css } from "styled-components";

const fadeInStyles = css`
  &.enter,
  &.appear {
    opacity: 0;
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transition: opacity
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit-done {
    opacity: 0;
  }
`;

export default fadeInStyles;
