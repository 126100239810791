import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import media from "../../theme/media";
import {
  InverseSkeletonTheme,
  MediumSkeletonTheme,
  SkeletonTheme,
  SocialSkeletonTheme,
} from "../SkeletonTheme/SkeletonTheme";

export const NAV_SUBHEADER_VARIANTS = [
  "default",
  "immersive",
  "inverse",
  "medium",
  "social",
] as const;
export type NavSubheaderVariant = (typeof NAV_SUBHEADER_VARIANTS)[number];

const StyledNavSubheaderInner = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  min-height: 2.5rem;
  overflow-x: auto;
  white-space: nowrap;

  @media ${media.lg} {
    padding: 0 1.375rem;
  }
`;

const defaultStyles = css`
  background-color: ${(p) => p.theme.colors.neutrals.background};
  color: ${(p) => p.theme.colors.neutrals.text};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
`;

const mediumStyles = css`
  background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  color: ${(p) => p.theme.colors.neutrals.text};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
`;

const immersiveStyles = css`
  background-color: ${(p) => p.theme.colors.neutrals.backdropWeak};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const inverseStyles = css`
  background-color: ${(p) => p.theme.colors.neutrals.background};

  ${StyledNavSubheaderInner} {
    ${immersiveStyles}
  }
`;

const socialStyles = css`
  background-color: ${(p) => p.theme.colors.social.strongBackground};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const navSubheaderVariantStyles: Readonly<
  Record<NavSubheaderVariant, ReturnType<typeof css>>
> = {
  default: defaultStyles,
  inverse: inverseStyles,
  immersive: immersiveStyles,
  medium: mediumStyles,
  social: socialStyles,
};

interface StyledNavSubheaderProps {
  variant: NavSubheaderVariant;
}

const StyledNavSubheader = styled.div<StyledNavSubheaderProps>`
  position: relative;
  ${(p) => navSubheaderVariantStyles[p.variant]}
  z-index: ${(p) => p.theme.zIndexes.appHeader};
`;

export interface NavSubheaderProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  variant?: NavSubheaderVariant;
}

const skeletonVariantMap: Record<
  NavSubheaderVariant,
  React.ComponentType<React.PropsWithChildren>
> = {
  default: SkeletonTheme,
  immersive: InverseSkeletonTheme,
  inverse: InverseSkeletonTheme,
  medium: MediumSkeletonTheme,
  social: SocialSkeletonTheme,
};

const NavSubheader: ForwardRefRenderFunction<
  HTMLDivElement,
  NavSubheaderProps
> = (
  {
    children,
    "data-testid": dataTestId,
    variant = "inverse",
  }: NavSubheaderProps,
  ref,
) => {
  const SkeletonThemeComponent = skeletonVariantMap[variant];

  return (
    <SkeletonThemeComponent>
      <StyledNavSubheader ref={ref} variant={variant} data-testid={dataTestId}>
        <StyledNavSubheaderInner>{children}</StyledNavSubheaderInner>
      </StyledNavSubheader>
    </SkeletonThemeComponent>
  );
};

export default forwardRef(NavSubheader);
