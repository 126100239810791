import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { Close } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { focusStyles } from "../Button/states";
import { chipStyles } from "./styled";

const StyledRemoveButton = styled.button`
  ${buttonReset}
  background-color: transparent;
  margin-right: -0.125rem;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &:focus-visible {
    ${focusStyles("border")}
  }

  &:disabled {
    background-color: transparent;
  }
`;

const StyledClose = styled(Close)`
  height: 1rem;
  width: 1rem;
`;

interface StyledRemovableChipProps {
  $disabled?: boolean;
  isActive?: boolean;
}

const StyledRemovableChip = styled.div<StyledRemovableChipProps>`
  ${chipStyles.base}

  ${(p) => p.$disabled && chipStyles.disabled}
  ${(p) => p.isActive && chipStyles.checked}

  ${StyledRemoveButton} {
    &:hover {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakHover};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakActive};
    }

    ${(p) =>
      p.isActive &&
      css`
        &:hover {
          background-color: ${p.theme.colors.primary.backgroundWeakHover};
        }

        &:active {
          background-color: ${p.theme.colors.primary.backgroundWeakPressed};
        }
      `}
  }
`;

interface RemovableChipProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  disabled?: boolean;
  isActive?: boolean;
  onRemove: React.MouseEventHandler<HTMLButtonElement>;
  "data-testid"?: string;
}

const RemovableChip: ForwardRefRenderFunction<
  HTMLButtonElement,
  RemovableChipProps
> = (
  {
    children,
    disabled,
    isActive,
    onRemove,
    "data-testid": dataTestId,
    ...props
  }: RemovableChipProps,
  ref,
) => {
  return (
    <StyledRemovableChip
      data-testid={dataTestId}
      $disabled={disabled}
      isActive={isActive}
      {...props}
    >
      {children}
      <StyledRemoveButton
        aria-label="Remove"
        data-testid={dataTestId && `${dataTestId}-remove-button`}
        disabled={disabled}
        onClick={onRemove}
        type="button"
        ref={ref}
      >
        <StyledClose />
      </StyledRemoveButton>
    </StyledRemovableChip>
  );
};

export default forwardRef(RemovableChip);
