import { ModalForm, onPromise } from "@app/design-system";
import React, { useEffect, useState } from "react";
import { useForm, type SubmitHandler, FormProvider } from "react-hook-form";
import DeveloperOptionsForm, {
  getDefaultDeveloperOptionsFormValues,
  type DeveloperOptionsFormValues,
} from "./DeveloperOptionsForm";
import type { DeveloperOptions } from "./DeveloperOptionsProvider";

interface DeveloperOptionsModalFormProps {
  developerOptions: DeveloperOptions;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<DeveloperOptionsFormValues>;
}

const DeveloperOptionsModalForm = ({
  developerOptions,
  isOpen,
  onClose,
  onSubmit,
}: DeveloperOptionsModalFormProps) => {
  const form = useForm<DeveloperOptionsFormValues>({
    defaultValues: getDefaultDeveloperOptionsFormValues(developerOptions),
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (isOpen) {
      reset(getDefaultDeveloperOptionsFormValues(developerOptions));
    }
  }, [developerOptions, isOpen, reset]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <ModalForm
      id="developerOptions"
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      title="Developer options"
    >
      <FormProvider {...form}>
        <DeveloperOptionsForm
          onSelect={(index) => setSelectedIndex(index)}
          selectedIndex={selectedIndex}
        />
      </FormProvider>
    </ModalForm>
  );
};

export default DeveloperOptionsModalForm;
