import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import { AttachFile } from "../../icons";
import { mediumStyles } from "../Button/states";
import { buttonSizeStyles } from "../Button/styled";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

export const StyledFileDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${(p) => p.theme.colors.neutrals.text};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  overflow: hidden;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  ${mediumStyles.background.base}
  ${buttonSizeStyles.md}

  svg {
    color: ${(p) => p.theme.colors.primary.iconOnBackground};
    margin-left: -0.25rem;
  }
`;

const StyledLabel = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface FileDisplayProps {
  children: React.ReactNode;
  "data-testid"?: string;
  icon?: ReactSVGComponent;
}

const FileDisplay: ForwardRefRenderFunction<
  HTMLDivElement,
  FileDisplayProps
> = (
  { children, "data-testid": dataTestId, icon = AttachFile }: FileDisplayProps,
  ref,
) => {
  return (
    <StyledFileDisplay ref={ref} data-testid={dataTestId}>
      <StaticIconWrapper icon={icon} size="sm" />
      <StyledLabel>{children}</StyledLabel>
    </StyledFileDisplay>
  );
};

export default forwardRef(FileDisplay);
