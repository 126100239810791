import { ThemeProvider } from "@app/design-system";
import { useLocalStorage } from "@kablamo/kerosene-ui";
import React from "react";
import { LocalStorageKeys } from "../../../config/storage";
import type { DeveloperOptionsContextValue } from "../DeveloperOptionsProvider/DeveloperOptionsProvider";
import DeveloperOptionsProvider, {
  defaultDeveloperOptions,
  isDeveloperOptions,
} from "../DeveloperOptionsProvider/DeveloperOptionsProvider";

interface AppThemeProviderProps {
  children?: React.ReactNode;
  isNonProdEnvironment: boolean;
}

const AppThemeProvider = ({
  children,
  isNonProdEnvironment,
}: AppThemeProviderProps) => {
  const [value, setValue] = useLocalStorage(
    LocalStorageKeys.DEVELOPER_OPTIONS,
    defaultDeveloperOptions,
    isDeveloperOptions,
  );

  const onChange = (nextValue: DeveloperOptionsContextValue) => {
    setValue(nextValue);
  };

  return (
    <ThemeProvider badgeStyle={value.options.badgeStyle}>
      <DeveloperOptionsProvider
        isNonProdEnvironment={isNonProdEnvironment}
        onChange={onChange}
        value={value}
      >
        {children}
      </DeveloperOptionsProvider>
    </ThemeProvider>
  );
};

export default AppThemeProvider;
