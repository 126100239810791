import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";

export const TEXT_LINK_VARIANTS = ["text", "textWeak"] as const;

export type TextLinkVariant = (typeof TEXT_LINK_VARIANTS)[number];

const variantStyles: Readonly<Record<TextLinkVariant, ReturnType<typeof css>>> =
  {
    text: css`
      color: ${(p) => p.theme.colors.neutrals.text};
    `,
    textWeak: css`
      color: ${(p) => p.theme.colors.neutrals.textWeak};

      &:hover {
        color: ${(p) => p.theme.colors.neutrals.text};
      }
    `,
  };

interface StyledTextLinkProps {
  variant: TextLinkVariant;
}

const StyledTextLink = styled.a<StyledTextLinkProps>`
  text-decoration: underline;
  text-underline-offset: 3px;

  ${(p) => variantStyles[p.variant]}
`;

interface TextLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
  isExternal?: boolean;
  variant?: TextLinkVariant;
}

const externalProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
  target: "_blank",
  rel: "noreferrer",
};

const TextLink: ForwardRefRenderFunction<HTMLAnchorElement, TextLinkProps> = (
  { children, isExternal: external, variant = "text", ...props }: TextLinkProps,
  ref,
) => {
  return (
    <StyledTextLink
      {...(external ? externalProps : {})}
      ref={ref}
      variant={variant}
      {...props}
    >
      {children}
    </StyledTextLink>
  );
};

export default forwardRef(TextLink);
