import styled from "styled-components";

type THIconWrapperSize = "sm" | "md";

const iconSizes: Record<THIconWrapperSize, number> = {
  sm: 16,
  md: 20,
};

interface StyledDataTableHeaderIconWrapperProps {
  size: THIconWrapperSize;
}
// Subtract the difference between the line height of our TH elements from the
// size of the icons so that the icons fall in line perfectly with the text and
// don't expand the height of the table row
const StyledDataTableHeaderIconWrapper = styled.div<StyledDataTableHeaderIconWrapperProps>`
  --icon-size: ${(p) => iconSizes[p.size] / 16}rem;

  svg {
    height: var(--icon-size);
    width: var(--icon-size);
  }

  --margin-y: calc(
    -1 * (
        var(--icon-size) -
          ${(p) => p.theme.typography.variants.eyebrowDefault["line-height"]}
      ) / 2
  );
  margin-top: var(--margin-y);
  margin-bottom: var(--margin-y);
`;

interface DataTableHeaderIconWrapperProps {
  "data-testid"?: string;
  icon: ReactSVGComponent;
  size?: THIconWrapperSize;
}

const DataTableHeaderIconWrapper = ({
  "data-testid": dataTestId,
  icon: Icon,
  size = "md",
}: DataTableHeaderIconWrapperProps) => {
  return (
    <StyledDataTableHeaderIconWrapper data-testid={dataTestId} size={size}>
      <Icon />
    </StyledDataTableHeaderIconWrapper>
  );
};

export default DataTableHeaderIconWrapper;
