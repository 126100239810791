import { useMenuItem } from "@react-aria/menu";
import type { TreeState } from "@react-stately/tree";
import type { Node } from "@react-types/shared";
import { useRef } from "react";
import styled, { css } from "styled-components";
import { mediumStyles } from "../Button/states";
import { StyledCheck } from "../Dropdown/styled";
import useFixTouchEventClickThrough from "./useFixTouchEventClickThrough";

interface StyledLiProps {
  $disabled: boolean;
  isFocused: boolean;
  isPressed: boolean;
  isSelected: boolean;
}

const StyledLi = styled.li<StyledLiProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 0.25rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) =>
    p.$disabled
      ? p.theme.colors.neutrals.textDisabled
      : p.theme.colors.neutrals.text};
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;

  cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};

  ${(p) =>
    p.isFocused &&
    css`
      ${mediumStyles.background.hover}
    `}

  ${(p) =>
    p.isPressed &&
    css`
      ${mediumStyles.background.active}
    `}

    ${(p) =>
    p.isSelected &&
    css`
      ${StyledCheck} {
        visibility: visible;
      }
    `}
`;

interface MenuItemProps {
  "data-testid"?: string;
  item: Node<NonNullable<unknown>>;
  state: TreeState<NonNullable<unknown>>;
}

export const MenuItem = ({
  "data-testid": dataTestId,
  item,
  state,
}: MenuItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { menuItemProps, isDisabled, isFocused, isPressed, isSelected } =
    useMenuItem(
      {
        key: item.key,
      },
      state,
      ref,
    );

  useFixTouchEventClickThrough(ref);
  return (
    <StyledLi
      {...menuItemProps}
      data-testid={dataTestId && `${dataTestId}-${item.key}`}
      $disabled={isDisabled}
      isFocused={isFocused}
      isPressed={isPressed}
      isSelected={isSelected}
      ref={ref}
    >
      {item.rendered}
      <StyledCheck />
    </StyledLi>
  );
};
