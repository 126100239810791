import styled from "styled-components";
import { VerticalLine } from "../../icons";
import { type TextVariant, textVariantStyles } from "../Text/Text";

interface StyledVerticalLineProps {
  variant: TextVariant;
}

const StyledVerticalLine = styled(VerticalLine)<StyledVerticalLineProps>`
  ${(p) => textVariantStyles[p.variant]}
`;

interface VerticalDividerProps {
  "data-testid"?: string;
  height: number;
  variant?: TextVariant;
}

const VerticalDivider = ({
  "data-testid": dataTestId,
  height,
  variant = "inherit",
}: VerticalDividerProps) => {
  return (
    <StyledVerticalLine
      data-testid={dataTestId}
      height={height}
      variant={variant}
      width={1}
    />
  );
};

export default VerticalDivider;
