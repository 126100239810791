import React, { createContext, useContext, useMemo } from "react";

interface RadioButtonGroupContextValue {
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
}

const RadioButtonGroupContext = createContext<
  RadioButtonGroupContextValue | undefined
>(undefined);

interface RadioButtonGroupProviderProps {
  children?: React.ReactNode;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
}

const RadioButtonGroupProvider = ({
  value,
  children,
  name,
  onChange,
}: RadioButtonGroupProviderProps) => {
  const context = useMemo<RadioButtonGroupContextValue>(
    () => ({ value, name, onChange }),
    [value, name, onChange],
  );

  return (
    <RadioButtonGroupContext.Provider value={context}>
      {children}
    </RadioButtonGroupContext.Provider>
  );
};

export default RadioButtonGroupProvider;

export const useRadioButtonGroup = () => {
  const context = useContext(RadioButtonGroupContext);

  if (!context) {
    throw new Error(
      "useRadioButtonGroup must be used inside a RadioButtonGroupProvider",
    );
  }

  return context;
};
