import { Menu, NavHeader, NavHeaderButton } from "@app/design-system";
import styled from "styled-components";

const StyledAuthLayoutLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <StyledAuthLayoutLayout>
      <NavHeader
        nav={
          <NavHeaderButton
            icon={Menu}
            label="Open navigation drawer"
            disabled
          />
        }
        title="Athena"
      />
      <StyledInner>{children}</StyledInner>
    </StyledAuthLayoutLayout>
  );
};

export default AuthLayout;
