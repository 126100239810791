import styled from "styled-components";
import { StyledButtonChip } from "./ButtonChip";
import { StyledChip, StyledInputChip } from "./InputChip";

// Key styling decisions here were made to have a single pixel dividing border
// between sibling chips. You can do this by switching off one x-axis border
// using sibling selectors, however when you hover a chip you want that chip
// to have all four borders visible.

// We also want a checked chip to always have all four borders visible.
const StyledInputChipGroup = styled.div`
  display: flex;

  /* Remove the rounded borders from the outside edges of child chips */
  ${StyledInputChip}:not(:first-child) ${StyledChip},
  ${StyledButtonChip}:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${StyledInputChip}:not(:last-child) ${StyledChip},
  ${StyledButtonChip}:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* If we have :has() support, we can properly support single pixel borders.
     Currently, only Firefox doesn't support this, and in that case it's fine
     to show a double pixel border. */

  @supports selector(:has(*)) {
    /* Switch off the right border of non-hovered, non-checked, non-last child chips */
    ${StyledInputChip}:not(:hover):not(:has(input:checked)):not(:last-child) ${StyledChip},
    ${StyledButtonChip}:not(:hover):not(:last-child) {
      border-right: none;
    }

    /* Switch off the right border of a checked chip if it has a following sibling
     that is being hovered */
    ${StyledInputChip}:has(input:checked):has(+ ${StyledInputChip}:hover) ${StyledChip},
    ${StyledInputChip}:has(input:checked):has(+ ${StyledButtonChip}:hover) ${StyledChip} {
      border-right: none;
    }

    /* Switch off the left border of the chips following a hovered chip */
    ${StyledInputChip}:hover + ${StyledInputChip} ${StyledChip},
    ${StyledInputChip}:hover + ${StyledButtonChip} {
      border-left: none;
    }

    /* Switch off the left border of non-hovered chips that follow a checked chip
     so that the checked chip can have full borders */
    ${StyledInputChip}:has(input:checked) + ${StyledInputChip}:not(:hover) ${StyledChip},
    ${StyledInputChip}:has(input:checked) + ${StyledButtonChip}:not(:hover) {
      border-left: none;
    }
  }
`;

interface ChipGroupProps {
  children?: React.ReactNode;
}

const ChipGroup = ({ children }: ChipGroupProps) => {
  return <StyledInputChipGroup>{children}</StyledInputChipGroup>;
};

export default ChipGroup;
