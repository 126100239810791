import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import styled, { useTheme } from "styled-components";

export const StyledInner = styled.div``;

const StyledSubTR = styled.div`
  &:not(:last-child) ${StyledInner} {
    border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  }
`;

type SubTRProps = React.HTMLAttributes<HTMLDivElement> & {
  isExpanded: boolean;
};

const SubTR = ({ children, isExpanded, ...props }: SubTRProps) => {
  const theme = useTheme();

  const [shouldRender, setShouldRender] = useState<boolean>(false);

  if (isExpanded && !shouldRender) {
    setShouldRender(true);
  }

  const { getCollapseProps } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
    isExpanded,
    onTransitionStateChange: (stage) => {
      if (stage === "collapseEnd") setShouldRender(false);
    },
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <StyledSubTR {...getCollapseProps()} {...props}>
      <StyledInner>{children}</StyledInner>
    </StyledSubTR>
  );
};

export default SubTR;
