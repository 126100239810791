import { useButton } from "@react-aria/button";
import { Menu } from "../../icons";
import NavHeaderButton from "../NavHeader/NavHeaderButton";
import { useNavDrawer } from "./NavDrawerProvider";

interface NavDrawerToggleButtonProps {
  "data-testid"?: string;
}

const NavDrawerToggleButton = ({
  "data-testid": dataTestId,
}: NavDrawerToggleButtonProps) => {
  const { triggerProps, triggerRef } = useNavDrawer();

  const { buttonProps } = useButton(triggerProps, triggerRef);

  return (
    <NavHeaderButton
      {...buttonProps}
      data-testid={dataTestId && `${dataTestId}-header-button`}
      icon={Menu}
      label="Open nav"
    />
  );
};

export default NavDrawerToggleButton;
