import styled from "styled-components";

type DividerVariant = "default" | "weak";

interface DividerProps {
  /**
   * Use default for dividers _between_ surfaces, use weak for dividers _within_
   * surfaces
   */
  variant?: DividerVariant;
}

const Divider = styled.hr<DividerProps>`
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: ${(p) =>
    p.variant === "weak"
      ? p.theme.colors.neutrals.surfaceDivider
      : p.theme.colors.neutrals.surfaceBorder};
  border: none;
`;

export default Divider;
