import styled from "styled-components";
import Skeleton from "../Skeleton/Skeleton";

// This component is making the assumption that the Alert it is replacing will
// have a single line title and message. This is enough to suit current needs,
// if we have the requirements to adjust in the future we can improve with some
// extra props.
const StyledSkeletonAlert = styled.div`
  font-size: 4rem;
`;

interface SkeletonAlertProps {
  "data-testid"?: string;
}

const SkeletonAlert = ({ "data-testid": dataTestId }: SkeletonAlertProps) => {
  return (
    <StyledSkeletonAlert>
      <Skeleton data-testid={dataTestId} />
    </StyledSkeletonAlert>
  );
};

export default SkeletonAlert;
