import { css } from "styled-components";

const fadeDownStyles = css`
  &.enter,
  &.appear {
    opacity: 0;
    transform: translateY(-8px) scale(0.98);
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition:
      opacity ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
      transform ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-8px) scale(0.98);
    transition:
      opacity ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
      transform ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }
`;

export default fadeDownStyles;
