import recursiveClone from "../lib/util/recursiveClone";
import recursiveReduce from "../lib/util/recursiveReduce";
import type { ColorTheme } from "./colors";

export const colorsToPropertyDeclarations = <T extends DeeplyNestedRecord<T>>(
  colors: DeeplyNestedRecord<T>,
) =>
  recursiveReduce({
    iteratee: (value: string, path: string) => `--${path}: ${value};`,
    source: colors,
  });

export const colorsToProperties = (colors: ColorTheme) =>
  recursiveClone({
    iteratee: (value: string, path: string) => `var(--${path})`,
    source: colors,
  });
