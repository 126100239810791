import styled, { css } from "styled-components";
import Text from "../Text/Text";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  ${(p) => p.theme.typography.variants.h3}
  color: ${(p) => p.theme.colors.neutrals.text};

  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledSeverityCardSupportingMetric = styled.div`
  display: flex;
  gap: 4px;
  ${(p) => p.theme.typography.variants.bodyLg};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

interface StyledSupportingIconProps {
  orientation?: number;
}

const StyledSupportingIcon = styled.div<StyledSupportingIconProps>`
  ${(p) =>
    p.orientation &&
    css`
      svg {
        transform-origin: 50%;
        transform: rotate(${p.orientation}deg);
      }
    `}
`;

interface SeverityCardSupportingMetricProps {
  children?: React.ReactNode;
  supportingIcon?: ReactSVGComponent;
  supportingIconOrientation?: number;
}

const SeverityCardSupportingMetric = ({
  children,
  supportingIcon,
  supportingIconOrientation,
}: SeverityCardSupportingMetricProps) => {
  if (!children && !supportingIcon) {
    return null;
  }

  return (
    <StyledSeverityCardSupportingMetric>
      {supportingIcon && (
        <StyledSupportingIcon orientation={supportingIconOrientation}>
          <TypographyIconWrapper
            icon={supportingIcon}
            size="bodyLg"
            variant="weak"
          />
        </StyledSupportingIcon>
      )}
      {children}
    </StyledSeverityCardSupportingMetric>
  );
};

interface SeverityCardMetricProps {
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  supportingMetric?: React.ReactNode;
  supportingIcon?: ReactSVGComponent;
  supportingIconOrientation?: number;
}

/**
 * This component displays a title, subtitle, and supporting metric & icon. It
 * can be used as the primary metric of the `SeverityCard` via its props of the
 * same name, or standalone in the `children` prop of the SeverityCard as
 * additional metrics.
 */
const SeverityCardMetric = ({
  children,
  subtitle,
  supportingMetric,
  supportingIcon,
  supportingIconOrientation,
}: SeverityCardMetricProps) => {
  return (
    <div>
      <StyledTitle>
        {children}
        <SeverityCardSupportingMetric
          supportingIcon={supportingIcon}
          supportingIconOrientation={supportingIconOrientation}
        >
          {supportingMetric}
        </SeverityCardSupportingMetric>
      </StyledTitle>
      {subtitle && (
        <Text size="bodyDefault" variant="weak">
          {subtitle}
        </Text>
      )}
    </div>
  );
};

export default SeverityCardMetric;
