import { useMenuItem } from "@react-aria/menu";
import type { TreeState } from "@react-stately/tree";
import type { Node } from "@react-types/shared";
import { useRef } from "react";
import styled, { css } from "styled-components";
import { mediumStyles } from "../Button/states";
import { StyledCheck } from "../Dropdown/styled";
import useFixTouchEventClickThrough from "../OverflowMenu/useFixTouchEventClickThrough";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

interface StyledLinkDropdownMenuItemProps {
  isFocused?: boolean;
  isPressed?: boolean;
  isSelected?: boolean;
}

const StyledLinkDropdownMenuItem = styled.li<StyledLinkDropdownMenuItemProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  ${(p) => p.theme.typography.variants.buttonLg};
  color: ${(p) => p.theme.colors.neutrals.text};
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  cursor: pointer;
  outline: none;

  ${(p) =>
    p.isFocused &&
    css`
      ${mediumStyles.background.hover}
    `}

  ${(p) =>
    p.isPressed &&
    css`
      ${mediumStyles.background.active}
    `}

    ${(p) =>
    p.isSelected &&
    css`
      ${StyledCheck} {
        visibility: visible;
      }
    `}
`;

export interface LinkDropdownMenuItemProps {
  "data-testid"?: string;
  children?: React.ReactNode;
  icon?: ReactSVGComponent;
  item: Node<NonNullable<unknown>>;
  state: TreeState<NonNullable<unknown>>;
}

const LinkDropdownMenuItem = ({
  "data-testid": dataTestId,
  children,
  icon,
  item,
  state,
}: LinkDropdownMenuItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { isFocused, isPressed, isSelected, menuItemProps } = useMenuItem(
    {
      key: item.key,
    },
    state,
    ref,
  );

  useFixTouchEventClickThrough(ref);

  return (
    <StyledLinkDropdownMenuItem
      {...menuItemProps}
      isFocused={isFocused}
      isPressed={isPressed}
      isSelected={isSelected}
      ref={ref}
      data-testid={dataTestId}
    >
      {icon && (
        <StaticIconWrapper
          icon={icon}
          data-testid={dataTestId && `${dataTestId}-icon`}
        />
      )}
      {children}
      <StyledCheck />
    </StyledLinkDropdownMenuItem>
  );
};

export default LinkDropdownMenuItem;
