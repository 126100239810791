import React, { type ForwardRefRenderFunction, forwardRef } from "react";
import styled, { css } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import { chipStyles, StyledDone } from "./styled";

interface StyledButtonChipStyles {
  $checked: boolean;
}

export const StyledButtonChip = styled.button<StyledButtonChipStyles>`
  ${buttonReset}
  ${chipStyles.base}

  &:hover {
    ${chipStyles.hover}
  }

  &:active {
    ${chipStyles.active}
  }

  &:focus-visible {
    ${chipStyles.focus}
  }

  ${(p) =>
    p.$checked &&
    css`
      ${chipStyles.checked}

      &:hover {
        ${chipStyles.checkedHover}
      }

      &:active {
        ${chipStyles.checkedActive}
      }
    `}

  &:disabled {
    ${chipStyles.disabled}
  }
`;

const StyledIcon = styled.div`
  margin-right: -0.125rem;
`;

interface ButtonChipProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  checked?: boolean;
  children?: React.ReactNode;
  icon?: ReactSVGComponent;
}

const ButtonChip: ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonChipProps
> = (
  {
    children,
    checked = false,
    icon: Icon,
    type = "button",
    ...props
  }: ButtonChipProps,
  ref,
) => {
  return (
    <StyledButtonChip {...props} $checked={checked} ref={ref} type={type}>
      <StyledDone />
      {children}
      {Icon && (
        <StyledIcon>
          <Icon />
        </StyledIcon>
      )}
    </StyledButtonChip>
  );
};

export default forwardRef(ButtonChip);
