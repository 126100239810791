import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";

interface StyledDivButtonProps {
  $disabled?: boolean;
}

const StyledDivButton = styled.div<StyledDivButtonProps>`
  cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};
`;

export type DivButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  "data-testid"?: string;
  disabled?: boolean;
};

const DivButton: ForwardRefRenderFunction<HTMLDivElement, DivButtonProps> = (
  {
    children,
    "data-testid": dataTestId,
    disabled,
    onClick,
    ...props
  }: DivButtonProps,
  ref,
) => {
  if (disabled) {
    return (
      <StyledDivButton
        data-testid={dataTestId}
        $disabled={disabled}
        ref={ref}
        {...props}
      >
        {children}
      </StyledDivButton>
    );
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClick?.(event as unknown as React.MouseEvent<HTMLDivElement>);
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " ") {
      event.preventDefault();
      onClick?.(event as unknown as React.MouseEvent<HTMLDivElement>);
    }
  };

  return (
    <StyledDivButton
      {...props}
      data-testid={dataTestId}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      ref={ref}
      tabIndex={0}
    >
      {children}
    </StyledDivButton>
  );
};

export default forwardRef(DivButton);
