/**
 * Takes an event `handler` that returnds a `Promise` and returns a new function which voids (ignores) that Promise so
 * that it may be passed to components which expect a void return
 *
 * @example
 * ```tsx
 * <form onSubmit={onPromise(handleSubmit(onSubmit))}>
 * ```
 *
 * @param handler
 */
export default function onPromise<T extends unknown[]>(
  handler: (...args: T) => Promise<unknown>,
): (...args: T) => void {
  return (...args: T) => void handler(...args);
}
