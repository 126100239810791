import Skeleton from "../Skeleton/Skeleton";
import Text from "../Text/Text";

interface SkeletonStatusIndicatorProps {
  "data-testid"?: string;
  width?: number;
}

const SkeletonStatusIndicator = ({
  "data-testid": dataTestId,
  width,
}: SkeletonStatusIndicatorProps) => {
  return (
    <Text size="eyebrowDefault">
      <Skeleton data-testid={dataTestId} width={width} />
    </Text>
  );
};

export default SkeletonStatusIndicator;
