import { transformAndCheckStatus } from "@kablamo/kerosene";
import { useOktaAuth } from "@okta/okta-react";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import catchAbortError from "../../utils/catchAbortError/catchAbortError";

const { publicRuntimeConfig } = getConfig();
const { INTERCOM_APP_ID } = publicRuntimeConfig;

export function useIntercomUserHash() {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const [userHash, setUserHash] = useState<string | null>(null);
  useEffect(() => {
    if (!INTERCOM_APP_ID) return;

    const abortController = new AbortController();
    fetch("/api/intercom-hmac", {
      signal: abortController.signal,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(transformAndCheckStatus<{ userHash: string }>)
      .then((data) => {
        setUserHash(data.userHash);
      })
      .catch(catchAbortError)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message);
      });

    return () => {
      abortController.abort();
    };
  }, [accessToken]);
  return userHash;
}
