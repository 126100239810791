import { forwardRef, type ForwardRefRenderFunction } from "react";
import useIsMinWidth from "../../hooks/useIsMinWidth";
import IconButton, { type IconButtonProps } from "../IconButton/IconButton";

export type NavHeaderButtonProps = Omit<IconButtonProps, "size" | "variant">;

const NavHeaderButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  NavHeaderButtonProps
> = (props: NavHeaderButtonProps, ref) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");
  return (
    <IconButton
      {...props}
      ref={ref}
      size={isTabletLandscapeAndAbove ? "md" : "sm"}
      variant="inverse"
    />
  );
};

export default forwardRef(NavHeaderButton);
