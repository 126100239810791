import { css } from "styled-components";

const buttonReset = css`
  text-align: inherit;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: ${(p) => p.theme.borderRadiuses.none};
  background-color: transparent;
  font: inherit;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  color: inherit;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default buttonReset;
