import React, { useEffect } from "react";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  ArrowForward,
  DoubleChevronLeft,
  DoubleChevronRight,
  LeftChevron,
  RightChevron,
} from "../../icons";
import onPromise from "../../lib/util/onPromise";
import media from "../../theme/media";
import type { FormControlOption } from "../../types/design-system";
import Select from "../Dropdown/Select/Select";
import IconButton from "../IconButton/IconButton";
import Text from "../Text/Text";
import TextInput from "../TextInput/TextInput";
import VerticalDivider from "../VerticalDivider/VerticalDivider";

const StyledPagination = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${media.md} {
    flex-direction: row;
    gap: 1rem;
  }

  @media ${media.lg} {
    margin-left: auto;
  }
`;

const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @media ${media.md} {
    gap: 0.5rem;
  }
`;

const StyledDivider = styled.div`
  display: none;
  color: ${(p) => p.theme.colors.neutrals.borderWeak};

  @media ${media.md} {
    display: block;
  }
`;

const StyledForm = styled.form`
  display: none;

  @media ${media.md} {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  @media ${media.lg} {
    gap: 1rem;
  }
`;

const StyledLabel = styled.label`
  ${(p) => p.theme.typography.variants.bodyLg}
`;

const StyledPageField = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledPageControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledPageInput = styled(TextInput)`
  width: 5.5rem;
`;

const PER_PAGE_OPTIONS: FormControlOption[] = [
  {
    label: "Show 10",
    value: "10",
  },
  {
    label: "Show 25",
    value: "25",
  },
  {
    label: "Show 50",
    value: "50",
  },
];

interface PaginationFormValues {
  page: number | null;
}

interface GetDefaultPaginationFormValuesParams {
  page: number;
}

const getDefaultPaginationFormValues = ({
  page,
}: GetDefaultPaginationFormValuesParams): PaginationFormValues => {
  return {
    page,
  };
};

const getPerPageOption = (perPage: number) => {
  const perPageOption = PER_PAGE_OPTIONS.find((option) => {
    const number = parseInt(option.value, 10);
    return perPage === number;
  });

  return perPageOption ?? { label: `Show ${perPage}`, value: `${perPage}` };
};

export interface PageState {
  page: number;
  perPage: number;
}

export type PageStateChangeHandler = (pageState: PageState) => void;

export interface PaginationProps {
  onPageStateChange: PageStateChangeHandler;
  page: number;
  perPage: number;
  totalPages: number;
}

const Pagination = ({
  onPageStateChange,
  page,
  perPage,
  totalPages,
}: PaginationProps) => {
  const perPageValue = getPerPageOption(perPage);

  const onPerPageChange = (option: FormControlOption | null) => {
    if (!option) return;
    const perPageNumber = parseInt(option.value, 10);

    onPageStateChange({
      page: 1,
      perPage: perPageNumber,
    });
  };

  const form = useForm<PaginationFormValues>({
    defaultValues: getDefaultPaginationFormValues({ page }),
    mode: "onChange",
  });

  const {
    control,
    formState: { isValid, dirtyFields },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultPaginationFormValues({ page }));
  }, [page, perPage, reset]);

  const onSubmit: SubmitHandler<PaginationFormValues> = (values) => {
    const newPage = values.page ?? 1;

    // If the user has changed per page, we need to reset to the first page
    // regardless. Otherwise the user can get to an empty set of results
    onPageStateChange({
      page: newPage,
      perPage,
    });
  };

  const onFirstClick = () => {
    onPageStateChange({ perPage, page: 1 });
  };

  const onNextClick = () => {
    onPageStateChange({ perPage, page: page + 1 });
  };

  const onPreviousClick = () => {
    onPageStateChange({ perPage, page: page - 1 });
  };

  const onLastClick = () => {
    onPageStateChange({ perPage, page: totalPages });
  };

  const firstDisabled = page === 1;
  const lastDisabled = page === totalPages;
  const nextDisabled = page === totalPages;
  const previousDisabled = page === 1;

  return (
    <StyledPagination>
      <StyledNavigation>
        <IconButton
          disabled={firstDisabled}
          icon={DoubleChevronLeft}
          label="First page"
          onClick={onFirstClick}
          size="md"
          variant="ghost"
        />
        <IconButton
          disabled={previousDisabled}
          icon={LeftChevron}
          label="Previous page"
          onClick={onPreviousClick}
          size="md"
          variant="ghost"
        />
        <Text size="bodyLg">
          Page <strong>{page}</strong> of {totalPages}
        </Text>
        <IconButton
          disabled={nextDisabled}
          icon={RightChevron}
          label="Next page"
          onClick={onNextClick}
          size="md"
          variant="ghost"
        />
        <IconButton
          disabled={lastDisabled}
          icon={DoubleChevronRight}
          label="Last page"
          onClick={onLastClick}
          size="md"
          variant="ghost"
        />
      </StyledNavigation>
      <StyledDivider>
        <VerticalDivider height={32} />
      </StyledDivider>
      <StyledForm onSubmit={onPromise(handleSubmit(onSubmit))}>
        <StyledPageField>
          <StyledLabel htmlFor="page">Go to page:</StyledLabel>
          <StyledPageControls>
            <Controller
              control={control}
              name="page"
              render={({ field: { onChange, value, ...field }, formState }) => {
                return (
                  <StyledPageInput
                    {...field}
                    id="page"
                    onBlur={() => {
                      if (value === null) {
                        onChange(formState.defaultValues?.page ?? null);
                      }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!event.currentTarget.value) {
                        onChange(null);
                        return;
                      }

                      const number = parseInt(event.currentTarget.value, 10);
                      onChange(number);
                    }}
                    type="number"
                    min={1}
                    max={totalPages}
                    step={1}
                    value={value ?? ""}
                  />
                );
              }}
              rules={{
                required: "Please enter a page",
                max: {
                  message: "Page cannot be greater than total pages",
                  value: totalPages,
                },
                min: {
                  message: "Page cannot be less than 1",
                  value: 1,
                },
              }}
            />
            <IconButton
              disabled={!isValid || !dirtyFields.page}
              icon={ArrowForward}
              label="Submit"
              size="md"
              type="submit"
              variant="ghost"
            />
          </StyledPageControls>
        </StyledPageField>

        <div>
          <Select
            aria-label="Results per page"
            placement="bottom right"
            onChange={onPerPageChange}
            options={PER_PAGE_OPTIONS}
            value={perPageValue}
          />
        </div>
      </StyledForm>
    </StyledPagination>
  );
};

export default Pagination;
