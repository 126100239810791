import { useEffect } from "react";

/**
 * Fixes this react-aria bug where touch events on a menu item fall through to
 * whatever element is behind the menu item. This can cause clicks on links or
 * buttons that are "beneath" the menu popup.
 * https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1172267250
 * @param ref A ref to the menu item element
 */
const useFixTouchEventClickThrough = (ref: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const preventTouchStartDefault = (event: TouchEvent) => {
      event.preventDefault();
    };
    ref.current?.addEventListener("touchstart", preventTouchStartDefault);

    const currentRef = ref.current;
    return () => {
      currentRef?.removeEventListener("touchstart", preventTouchStartDefault);
    };
  }, [ref]);
};

export default useFixTouchEventClickThrough;
