import styled from "styled-components";
import media from "../../theme/media";
import { weakStyles } from "../Button/states";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";

const StyledButton = styled.div`
  padding: 0.25rem;
  color: ${(p) => p.theme.colors.neutrals.text};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media ${media.lg} {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const StyledTextLabel = styled.div`
  flex: 1;
  ${(p) => p.theme.typography.variants.bodyDefault};
`;

export const PILOT_LIGHT_ORIENTATIONS = ["portrait", "landscape"] as const;

export type PilotLightOrientation = (typeof PILOT_LIGHT_ORIENTATIONS)[number];

interface StyledContentProps {
  orientation: PilotLightOrientation;
}

const StyledContent = styled.div<StyledContentProps>`
  display: flex;
  flex-direction: ${(p) => (p.orientation === "portrait" ? "column" : "row")};
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const StyledPilotLight = styled.label`
  ${StyledHiddenInput}:not(:disabled):not(:checked) + ${StyledContent}:hover ${StyledButton} {
    ${weakStyles.background.hover}
  }

  ${StyledHiddenInput}:not(:disabled) + ${StyledContent}:active ${StyledButton} {
    ${weakStyles.background.active}
  }

  ${StyledHiddenInput}:checked + ${StyledContent} ${StyledButton} {
    ${weakStyles.background.active}
  }

  ${StyledHiddenInput}:focus-visible + ${StyledContent} ${StyledButton} {
    outline: 2px solid ${(p) => p.theme.colors.neutrals.borderFocus};
  }

  ${StyledHiddenInput}:disabled + ${StyledContent} {
    cursor: not-allowed;
  }

  ${StyledHiddenInput}:disabled + ${StyledContent} ${StyledButton} {
    color: ${(p) => p.theme.colors.neutrals.iconDisabled};
  }

  ${StyledHiddenInput}:disabled + ${StyledContent}  ${StyledTextLabel} {
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  }

  ${StyledHiddenInput}:checked + ${StyledContent} ${StyledTextLabel} {
    font-weight: ${(p) => p.theme.fontWeights.semibold};
  }
`;

export interface PilotLightProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  "data-testid"?: string;
  disabled?: boolean;
  icon: ReactSVGComponent;
  label: string;
  orientation?: PilotLightOrientation;
}

const PilotLight = ({
  "data-testid": dataTestId,
  icon: Icon,
  label,
  orientation = "landscape",
  ...htmlProps
}: PilotLightProps) => {
  return (
    <StyledPilotLight data-testid={dataTestId}>
      <StyledHiddenInput type="checkbox" {...htmlProps} aria-hidden="true" />
      <StyledContent
        data-testid={dataTestId && `${dataTestId}-content`}
        orientation={orientation}
      >
        <StyledButton>
          <Icon width={16} />
        </StyledButton>
        <StyledTextLabel>{label}</StyledTextLabel>
      </StyledContent>
    </StyledPilotLight>
  );
};

export default PilotLight;
