import styled from "styled-components";
import Skeleton from "../Skeleton/Skeleton";

const StyledSkeletonBadge = styled.div`
  font-size: 1.375rem;
`;

interface SkeletonBadgeProps {
  width?: number;
}

const SkeletonBadge = ({ width }: SkeletonBadgeProps) => {
  return (
    <StyledSkeletonBadge>
      <Skeleton width={width} />
    </StyledSkeletonBadge>
  );
};

export default SkeletonBadge;
