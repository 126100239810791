import styled, { css } from "styled-components";
import { CheckboxChecked, CheckboxUnchecked } from "../../../icons";
import media from "../../../theme/media";
import { focusStyles } from "../../Button/states";
import StyledHiddenInput from "../../StyledHiddenInput/StyledHiddenInput";

const uncheckedSvgClassPrefix = `checkbox-unchecked_svg`;

export const CHECKBOX_SIZES = ["md", "lg"] as const;

export type CheckboxSize = (typeof CHECKBOX_SIZES)[number];

export type CheckboxSizeStyleMap = Record<CheckboxSize, ReturnType<typeof css>>;

const checkboxSizeStyles: CheckboxSizeStyleMap = {
  md: css`
    width: 24px;
    height: 24px;
  `,
  lg: css`
    width: 28px;
    height: 28px;
  `,
};

const lgCheckboxSizeStyles: CheckboxSizeStyleMap = {
  md: css`
    width: 20px;
    height: 20px;
  `,
  lg: css`
    width: 24px;
    height: 24px;
  `,
} as const;

const xlCheckboxSizeStyles: CheckboxSizeStyleMap = {
  md: css`
    width: 32px;
    height: 32px;
  `,
  lg: css`
    width: 32px;
    height: 32px;
  `,
} as const;

export const StyledCheckboxChecked = styled(CheckboxChecked)``;
export const StyledCheckboxUnchecked = styled(CheckboxUnchecked)``;

export const StyledIconCheckbox = styled.label`
  display: block;
`;

interface StyledCheckboxProps {
  size: CheckboxSize;
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: flex;
  cursor: pointer;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  ${(p) => checkboxSizeStyles[p.size]}

  @media ${media.lg} {
    ${(p) => lgCheckboxSizeStyles[p.size]}
  }

  @media ${media.xxl} {
    ${(p) => xlCheckboxSizeStyles[p.size]}
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:hover {
    .${uncheckedSvgClassPrefix}__background {
      color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    }
  }

  .${uncheckedSvgClassPrefix}__background {
    color: ${(p) => p.theme.colors.neutrals.background};
  }
  .${uncheckedSvgClassPrefix}__border {
    color: ${(p) => p.theme.colors.neutrals.borderSelected};
  }

  ${StyledHiddenInput}:focus-visible ~ & {
    ${focusStyles("border")}
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:active {
    .${uncheckedSvgClassPrefix}__background {
      color: ${(p) => p.theme.colors.neutrals.backgroundMediumActive};
    }
    .${uncheckedSvgClassPrefix}__border {
      color: ${(p) => p.theme.colors.neutrals.borderSelected};
    }
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:focus-visible {
    .${uncheckedSvgClassPrefix}__border {
      color: ${(p) => p.theme.colors.neutrals.borderSelected};
    }
  }

  ${StyledCheckboxChecked} {
    display: none;
  }

  ${StyledHiddenInput}:checked ~ & {
    ${StyledCheckboxChecked} {
      display: block;
    }

    ${StyledCheckboxUnchecked} {
      display: none;
    }

    color: ${(p) => p.theme.colors.neutrals.text};
  }

  ${StyledHiddenInput}:disabled ~ & {
    cursor: not-allowed;
    color: ${(p) => p.theme.colors.neutrals.iconDisabled};

    .${uncheckedSvgClassPrefix}__background {
      color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
    }

    .${uncheckedSvgClassPrefix}__border {
      color: ${(p) => p.theme.colors.neutrals.borderDisabled};
    }
  }
`;
