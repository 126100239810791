import { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import type { ButtonIconPosition } from "../Button/styled";
import ModalDialog, { type ModalDialogProps } from "./ModalDialog";
import { StyledFooterButtons } from "./styled";

export interface ConfirmModalProps<D>
  extends Omit<ModalDialogProps, "actions" | "footer" | "onClose"> {
  cancelLabel?: string | null;
  cancelLabelIcon?: ReactSVGComponent;
  cancelLabelIconPosition?: ButtonIconPosition;
  confirmLabel?: string;
  confirmLabelIcon?: ReactSVGComponent;
  confirmLabelIconPosition?: ButtonIconPosition;
  onConfirm: () => Promise<D> | D;
  onConfirmSuccess: [D] extends [void]
    ? () => void
    : (data: Awaited<D>) => void;
  onConfirmError?: (error: Error) => void;
  onCancel: () => void;
}

const ConfirmModal = <D,>({
  children,
  cancelLabel = "Cancel",
  cancelLabelIcon,
  cancelLabelIconPosition,
  confirmLabel = "Confirm",
  confirmLabelIcon,
  confirmLabelIconPosition,
  error = <Alert title="A problem has occurred" variant="error" />,
  isOpen,
  onCancel,
  onConfirm,
  onConfirmError,
  onConfirmSuccess,
  ...props
}: ConfirmModalProps<D>) => {
  const dataTestId = props["data-testid"];

  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const onConfirmClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        setIsConfirming(true);
        setIsError(false);

        const results = await onConfirm?.();
        setIsConfirming(false);

        onConfirmSuccess?.(results);
      } catch (e) {
        setIsConfirming(false);
        setIsError(true);

        onConfirmError?.(e as Error);
      }
    })();
  };

  useEffect(() => {
    if (!isOpen) {
      setIsError(false);
    }
  }, [isOpen]);

  return (
    <ModalDialog
      error={isError && error}
      footer={
        <StyledFooterButtons>
          {cancelLabel && (
            <Button
              data-testid={dataTestId && `${dataTestId}-cancel-button`}
              disabled={isConfirming}
              icon={cancelLabelIcon}
              iconPosition={cancelLabelIconPosition}
              onClick={onCancel}
              variant="ghost"
            >
              {cancelLabel}
            </Button>
          )}
          <Button
            data-testid={dataTestId && `${dataTestId}-confirm-button`}
            disabled={isConfirming}
            icon={confirmLabelIcon}
            iconPosition={confirmLabelIconPosition}
            isLoading={isConfirming}
            onClick={onConfirmClick}
            variant="primary"
          >
            {confirmLabel}
          </Button>
        </StyledFooterButtons>
      }
      isDismissable
      isOpen={isOpen}
      onClose={onCancel}
      {...props}
    >
      {children}
    </ModalDialog>
  );
};

export default ConfirmModal;
