import type { TypographySize } from "../../theme";
import type { StatusVariant } from "../../types/design-system";
import { statusIconMap } from "../Alert/Alert";
import type { IconVariant } from "../StaticIconWrapper/StaticIconWrapper";
import { Tooltip } from "../Tooltip/Tooltip";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

export interface StatusIconProps {
  "data-testid"?: string;
  size: TypographySize;
  status: StatusVariant;
  tooltip?: React.ReactNode;
  variant?: IconVariant;
}

const TypographyStatusIcon = ({
  "data-testid": dataTestId,
  size,
  status,
  tooltip,
  variant = status,
}: StatusIconProps) => {
  const icon = statusIconMap[status];

  if (!tooltip) {
    return (
      <TypographyIconWrapper
        data-testid={dataTestId && `${dataTestId}-static-icon-wrapper`}
        icon={icon}
        size={size}
        variant={variant}
      />
    );
  }

  return (
    <Tooltip
      data-testid={dataTestId && `${dataTestId}-tooltip`}
      message={tooltip}
    >
      <div>
        <TypographyIconWrapper
          data-testid={dataTestId && `${dataTestId}-static-icon-wrapper`}
          icon={icon}
          size={size}
          variant={variant}
        />
      </div>
    </Tooltip>
  );
};

export default TypographyStatusIcon;
