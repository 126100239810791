import styled, { css } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import media from "../../theme/media";
import { spinnerStyles } from "../Spinner/utils";
import { focusStyles, weakStyles } from "./states";

export const StyledTextWrapper = styled.span`
  display: flex;
  gap: 0.375rem;
  white-space: nowrap;
`;

export const commonButtonStyles = css`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  white-space: nowrap;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    border-color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  [class*="adjustment-indicator"] {
    color: ${(p) => p.theme.colors.informative.icon};
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

export const primary = css`
  color: ${(p) => p.theme.colors.primary.textOnBackground};
  background-color: ${(p) => p.theme.colors.primary.backgroundMedium};
  border-color: transparent;

  &:hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.primary.backgroundMediumHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) => p.theme.colors.primary.backgroundMediumPressed};
  }

  &:disabled {
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
    background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  }
`;

export const secondary = css`
  ${weakStyles.background.base}
  ${weakStyles.border.base}

  &:hover:not(:disabled) {
    ${weakStyles.background.hover}
    ${weakStyles.border.hover}
  }

  &:active:not(:disabled) {
    ${weakStyles.background.active}
    ${weakStyles.border.active}
  }

  &:disabled {
    ${weakStyles.background.disabled}
    ${weakStyles.border.disabled}
  }
`;

export const ghost = css`
  color: ${(p) => p.theme.colors.primary.textOnBackground};
  background-color: transparent;
  border-color: transparent;

  &:hover:not(:disabled) {
    ${weakStyles.background.hover}
  }

  &:active:not(:disabled) {
    ${weakStyles.background.active}
  }

  &:disabled {
    ${weakStyles.background.disabled}
  }
`;

export const ghostWeak = css`
  color: ${(p) => p.theme.colors.neutrals.iconWeak};
  background-color: transparent;
  border-color: transparent;

  &:hover:not(:disabled) {
    color: ${(p) => p.theme.colors.neutrals.text};
    ${weakStyles.background.hover}
  }

  &:active:not(:disabled) {
    color: ${(p) => p.theme.colors.neutrals.text};
    ${weakStyles.background.active}
  }

  &:disabled {
    ${weakStyles.background.disabled}
  }
`;

const inverse = css`
  color: ${(p) => p.theme.colors.fireStatus.textOnBackground};
  background-color: transparent;
  border-color: ${(p) => p.theme.colors.fireStatus.borderOnBackground};

  &:hover {
    background-color: ${(p) => p.theme.colors.fireStatus.buttonBackgroundHover};
    border-color: ${(p) => p.theme.colors.fireStatus.borderOnBackgroundHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) => p.theme.colors.fireStatus.buttonBackgroundHover};
    border-color: ${(p) => p.theme.colors.fireStatus.borderOnBackgroundHover};
  }

  &:disabled {
    color: ${(p) => p.theme.colors.fireStatus.textDisabledOnBackground};
    background-color: transparent;
    border-color: ${(p) =>
      p.theme.colors.fireStatus.borderDisabledOnBackground};
  }
`;

const error = css`
  color: ${(p) => p.theme.colors.error.text};
  background-color: transparent;
  border-color: ${(p) => p.theme.colors.error.border};

  &:hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.error.backgroundWeakHover};
  }

  &:active:not(:disabled) {
    background-color: ${(p) => p.theme.colors.error.backgroundMedium};
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  }
`;

export const buttonVariantStyles = {
  primary,
  secondary,
  ghost,
  inverse,
  error,
};

export const BUTTON_VARIANTS = [
  "primary",
  "secondary",
  "ghost",
  "inverse",
  "error",
] as const;

export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export const BUTTON_SIZES = ["sm", "md"] as const;

export type ButtonSize = (typeof BUTTON_SIZES)[number];

export type ButtonSizeStyleMap = Record<ButtonSize, ReturnType<typeof css>>;

export type ButtonSizeNumberMap = Record<ButtonSize, number>;

export const buttonIconSizes: ButtonSizeNumberMap = {
  sm: 16,
  md: 20,
};

export const iconSizeStyles = (pxSize: number) => css`
  width: ${pxSize / 16}rem;
  height: ${pxSize / 16}rem;
`;

export const buttonSizeStyles: ButtonSizeStyleMap = {
  sm: css`
    ${(p) => p.theme.typography.variants.buttonSm}
    padding: calc(0.5rem - 1px) 0.75rem;
  `,
  md: css`
    ${(p) => p.theme.typography.variants.buttonDefault}
    padding: calc(0.625rem - 1px) 1rem;
  `,
};

export const BUTTON_ICON_POSITIONS = ["start", "end"] as const;

export type ButtonIconPosition = (typeof BUTTON_ICON_POSITIONS)[number];

interface StyledButtonProps {
  fullWidth: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
}

const StyledButton = styled.button<StyledButtonProps>`
  ${buttonReset}
  ${commonButtonStyles}
  ${buttonSizeStyles.md}
  gap: 0.25rem;
  ${(p) => buttonVariantStyles[p.variant]}
  ${(p) => p.fullWidth && `width: 100%`};
  position: relative;
  text-align: center;
  white-space: normal;

  ${StyledTextWrapper} {
    svg {
      ${iconSizeStyles(buttonIconSizes.md)}
    }
  }

  ${(p) => spinnerStyles(buttonIconSizes[p.size], p.isLoading)}

  @media ${media.lg} {
    ${(p) => buttonSizeStyles[p.size]}

    ${StyledTextWrapper} {
      svg {
        ${(p) => iconSizeStyles(buttonIconSizes[p.size])}
      }
    }
  }
`;

export default StyledButton;
