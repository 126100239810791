import type { MergedUnion } from "@kablamo/kerosene";
import Button from "../Button/Button";
import TextButton from "../TextButton/TextButton";
import ModalDialog, { type ModalDialogProps } from "./ModalDialog";
import { StyledFooterButtons, StyledReset } from "./styled";

type ResettableProps = {
  isResettable: true;
  onReset: () => void;
  resetLabel?: string;
};

type NonResettableProps = {
  isResettable?: false;
};

export type ModalFormProps = Omit<ModalDialogProps, "onClose" | "footer"> & {
  cancelLabel?: string;
  cancelLabelIcon?: ReactSVGComponent;
  error?: React.ReactNode;
  id: string;
  isSubmitting?: boolean;
  onCancel: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitLabel?: string;
  submitLabelIcon?: ReactSVGComponent;
} & MergedUnion<ResettableProps | NonResettableProps>;

const ModalForm = ({
  cancelLabel = "Cancel",
  cancelLabelIcon,
  children,
  "data-testid": dataTestId,
  error,
  id,
  isResettable,
  isSubmitting,
  onCancel,
  onReset,
  onSubmit,
  resetLabel = "Reset to default",
  submitLabel = "Submit",
  submitLabelIcon,
  ...props
}: ModalFormProps) => {
  return (
    <ModalDialog
      isDismissable={false}
      isKeyboardDismissDisabled
      error={error}
      footer={
        <>
          {isResettable && (
            <StyledReset>
              <TextButton
                data-testid={dataTestId && `${dataTestId}-reset-button`}
                disabled={isSubmitting}
                onClick={onReset}
              >
                {resetLabel}
              </TextButton>
            </StyledReset>
          )}
          <StyledFooterButtons>
            <Button
              data-testid={dataTestId && `${dataTestId}-cancel-button`}
              disabled={isSubmitting}
              icon={cancelLabelIcon}
              iconPosition="start"
              onClick={onCancel}
              variant="ghost"
            >
              {cancelLabel}
            </Button>
            <Button
              data-testid={dataTestId && `${dataTestId}-submit-button`}
              disabled={isSubmitting}
              icon={submitLabelIcon}
              iconPosition="end"
              isLoading={isSubmitting}
              form={id}
              type="submit"
            >
              {submitLabel}
            </Button>
          </StyledFooterButtons>
        </>
      }
      onClose={onCancel}
      {...props}
    >
      <form
        id={id}
        onSubmit={onSubmit}
        data-testid={dataTestId && `${dataTestId}-form`}
      >
        {children}
      </form>
    </ModalDialog>
  );
};

export default ModalForm;
