import styled from "styled-components";
import Spinner, { type SpinnerProps } from "../Spinner/Spinner";

const StyledFlexSpinner = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface FlexSpinnerProps extends Pick<SpinnerProps, "spinnerSize"> {
  "data-testid"?: string;
}

const FlexSpinner = ({
  "data-testid": dataTestId,
  spinnerSize = 24,
}: FlexSpinnerProps) => {
  return (
    <StyledFlexSpinner data-testid={dataTestId}>
      <Spinner
        data-testid={dataTestId && `${dataTestId}-spinner`}
        spinnerSize={spinnerSize}
      />
    </StyledFlexSpinner>
  );
};

export default FlexSpinner;
