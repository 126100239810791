import styled, { css, type RuleSet } from "styled-components";

type ColumnCount = 1 | 2 | 3;

const columnCountStyleMap: Record<ColumnCount, ReturnType<typeof css>> = {
  1: css`
    width: 100%;
  `,
  2: css`
    width: 50%;
  `,
  3: css`
    width: 33.3%;
  `,
};

interface StyledTableProps {
  columns: ColumnCount;
  size?: "sm" | "md";
}

const cellBaseStyles = css`
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  white-space: pre-wrap;
`;

const cellSizeStyles = {
  sm: css`
    padding: 0.25rem;
  `,
  md: css`
    padding: 0.5rem;
    &:first-child {
      padding-left: 1.5rem;
    }

    &:last-child {
      padding-right: 1.5rem;
    }
  `,
} as const satisfies Record<Required<StyledTableProps>["size"], RuleSet>;

const StyledHeaderCell = styled.th`
  ${cellBaseStyles}
  ${(p) => p.theme.typography.variants.buttonDefault}
  border-bottom: 2px solid ${(p) => p.theme.colors.neutrals.borderWeak};
`;

const StyledCell = styled.td`
  ${cellBaseStyles}
  ${(p) => p.theme.typography.variants.bodyDefault};
`;

const StyledLabelCell = styled.td`
  ${cellBaseStyles}
  ${(p) => p.theme.typography.variants.bodyDefaultStrong};
`;

const StyledWeakCell = styled.td`
  ${cellBaseStyles}
  ${(p) => p.theme.typography.variants.bodyDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const StyledRow = styled.tr`
  &:last-child {
    ${StyledCell}, ${StyledLabelCell}, ${StyledWeakCell} {
      border-bottom: none;
    }
  }
`;

const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  table-layout: fixed;
  text-align: left;
  border-collapse: separate;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};

  td,
  th {
    ${(p) => columnCountStyleMap[p.columns]}
  }

  /* Add size padding from the table to all of the cell children */
  ${StyledHeaderCell}, ${StyledHeaderCell}, ${StyledCell}, ${StyledLabelCell}, ${StyledWeakCell} {
    ${(p) => cellSizeStyles[p.size || "md"]}
  }
`;

export default Object.assign(StyledTable, {
  HeaderCell: StyledHeaderCell,
  WeakCell: StyledWeakCell,
  Cell: StyledCell,
  LabelCell: StyledLabelCell,
  Row: StyledRow,
});
