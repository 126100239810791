import type { DefaultTheme } from "styled-components";
import type { Severity } from "../../types/design-system";

interface GetSeverityBorderColorParams {
  severity?: Severity;
  theme: DefaultTheme;
}

export const getSeverityBorderColor = ({
  severity,
  theme,
}: GetSeverityBorderColorParams) => {
  switch (severity) {
    case "severe":
    case "high":
      return theme.colors.error.border;
    case "moderate":
      return theme.colors.warning.border;
    case "low":
    case "very-low":
      return theme.colors.informative.border;
    default:
      return theme.colors.neutrals.surfaceBorder;
  }
};

interface GetSeverityBackgroundColorParams {
  severity?: Severity;
  theme: DefaultTheme;
}

export const getSeverityBackgroundColor = ({
  severity,
  theme,
}: GetSeverityBackgroundColorParams) => {
  switch (severity) {
    case "severe":
    case "high":
      return theme.colors.error.backgroundWeak;
    case "moderate":
      return theme.colors.warning.backgroundWeak;
    case "low":
    case "very-low":
      return theme.colors.informative.backgroundWeak;
    default:
      return "transparent";
  }
};
