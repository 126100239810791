import React from "react";
import styled from "styled-components";

const StyledHeaderLinkTabs = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
`;

interface HeaderLinkTabsProps {
  children?: React.ReactNode;
}

const HeaderLinkTabs = ({ children }: HeaderLinkTabsProps) => {
  return <StyledHeaderLinkTabs>{children}</StyledHeaderLinkTabs>;
};

export default HeaderLinkTabs;
