import { useUpdatingRef } from "@kablamo/kerosene-ui";
import React, {
  forwardRef,
  useState,
  type ForwardRefRenderFunction,
  useEffect,
} from "react";
import { useDebounce } from "usehooks-ts";
import type { TextInputProps } from "./TextInput";
import TextInput from "./TextInput";

type DebouncedTextInputProps = Omit<
  TextInputProps,
  // Since the DebouncedTextInput doesn't pass its consumer an actual change
  // event, we can save the need to handle onClearClick separately from onChange
  // and instead just send up an empty string in the change handler
  "onChange" | "onClearClick" | "value"
> & {
  debounceTime?: number;
  onChange: (value: string) => void;
  value?: string;
};

const DebouncedTextInput: ForwardRefRenderFunction<
  HTMLInputElement,
  DebouncedTextInputProps
> = (
  {
    debounceTime = 300,
    onChange: _onChange,
    value,
    ...props
  }: DebouncedTextInputProps,
  ref,
) => {
  const [inputValue, setInputValue] = useState(value ?? "");

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  const onClearClick = () => {
    setInputValue("");
  };

  const debouncedTerm = useDebounce(inputValue, debounceTime);

  const onChangeRef = useUpdatingRef(_onChange);

  useEffect(() => {
    onChangeRef.current?.(debouncedTerm);
  }, [debouncedTerm, onChangeRef]);

  return (
    <TextInput
      {...props}
      onChange={onChange}
      onClearClick={onClearClick}
      ref={ref}
      value={inputValue}
    />
  );
};

export default forwardRef(DebouncedTextInput);
