import { AlertModal, DEFAULT_ERROR_MESSAGE } from "@app/design-system/src";

export const FallbackComponent = ({
  resetError,
}: {
  resetError: () => void;
}) => (
  <AlertModal
    isOpen
    closeOnClickOutside={false}
    closeOnEsc={false}
    title="There is an issue"
    dismissLabel="Try again"
    onDismiss={() => {
      resetError();
    }}
  >
    {`There was an error in the application. ${DEFAULT_ERROR_MESSAGE}`}
  </AlertModal>
);
