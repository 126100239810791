import styled from "styled-components";

const StyledNavDrawerMenu = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface NavDrawerMenuProps {
  children?: React.ReactNode;
}

const NavDrawerMenu = ({ children }: NavDrawerMenuProps) => {
  return <StyledNavDrawerMenu>{children}</StyledNavDrawerMenu>;
};

export default NavDrawerMenu;
