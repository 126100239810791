import styled from "styled-components";

interface SidebarStepProps {
  children?: React.ReactNode;
  error?: React.ReactNode;
  footer?: React.ReactNode;
}

const StyledSideSheetStep = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
`;

const StyledFooter = styled.div`
  position: sticky;
  bottom: 0;
`;

const StyledError = styled.div`
  position: sticky;
  bottom: 0;
  padding: 24px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-top: 1px solid ${(p) => p.theme.colors.neutrals.backgroundMedium};
`;

const StyledFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 16px;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
`;

const SideSheetStep = ({ children, error, footer }: SidebarStepProps) => {
  return (
    <>
      <StyledSideSheetStep>{children}</StyledSideSheetStep>
      <StyledFooter>
        {error && <StyledError>{error}</StyledError>}
        <StyledFooterContent>{footer}</StyledFooterContent>
      </StyledFooter>
    </>
  );
};

export default SideSheetStep;
