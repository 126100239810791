import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const APP_ID = publicRuntimeConfig.INTERCOM_APP_ID;

/**
 * Taken from intercom developments website : https://developers.intercom.com/installing-intercom/docs/basic-javascript under 'Single Page  App'
 * */

// This must be run before boot, it initializes window.Intercom
export const load = () => {
  const w = window;
  // @ts-ignore
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    // @ts-ignore
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    // @ts-ignore
    i.q = [];
    // @ts-ignore
    i.c = function (args) {
      i.q.push(args);
    };
    // @ts-ignore
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${APP_ID}`;
      const x = d.getElementsByTagName("script")[0];
      // @ts-ignore
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
      // @ts-ignore
    } else if (w.attachEvent) {
      // @ts-ignore
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};

/** Taken from intercom developments website : https://developers.intercom.com/installing-intercom/docs/intercom-javascript */
export const boot = (options = {}) => {
  // @ts-ignore
  if (!window || !window.Intercom || typeof window.Intercom !== "function") {
    return;
  }
  // @ts-ignore
  window.intercomSettings = {
    api_base: "https://api-iam.au.intercom.io",
    app_id: APP_ID,
    // This setting here hides the launcher
    hide_default_launcher: true,
  };
  // @ts-ignore
  window.Intercom("boot", { app_id: APP_ID, ...options });
};

/** Taken from intercom developments website : https://developers.intercom.com/installing-intercom/docs/intercom-javascript */
export const update = () => {
  // @ts-ignore
  window && window.Intercom && window.Intercom("update");
};

/** Taken from intercom developments website : https://developers.intercom.com/installing-intercom/docs/intercom-javascript */
export const trackEvent = (name: string, metadata: Record<string, unknown>) => {
  // @ts-ignore
  window && window.Intercom && window.Intercom("trackEvent", name, metadata);
};
