import styled, { css } from "styled-components";
import {
  mediumSurfaceOverrides,
  weakSurfaceOverrides,
} from "../../theme/colorsLight";

type TableElement = "table" | "th" | "td";

type TableVariantStyleMap = Record<TableElement, ReturnType<typeof css>>;

interface StyledTDProps {
  maxSize?: number;
  size?: number;
  variant: TableVariant;
}

export const StyledTD = styled.div<StyledTDProps>`
  display: flex;
  align-items: center;
  padding: calc(0.75rem - 1px) 0.5rem;
  flex: ${(p) => p.size ?? 150} 0 auto;
  width: ${(p) => p.size ?? 150}px;
  min-width: 0;
  ${(p) =>
    p.maxSize &&
    css`
      max-width: ${p.maxSize}px;
    `}
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.text};
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

  ${(p) => tableThemeStyles[p.variant].td}

  &:first-child {
    padding-left: 0.75rem;
  }

  &:last-child {
    padding-right: 0.75rem;
  }
`;

interface StyledTRProps {
  isClickable?: boolean;
  isLast?: boolean;
  variant: TableVariant;
}

export const StyledTR = styled.div<StyledTRProps>`
  display: flex;

  ${StyledTD} {
    background-color: ${(p) => p.theme.colors.neutrals.surface};
  }

  &:hover {
    ${weakSurfaceOverrides}

    ${(p) =>
      p.isClickable &&
      css`
        cursor: pointer;
      `};
  }

  ${(p) =>
    p.isClickable &&
    css`
      &:active {
        ${mediumSurfaceOverrides}
      }
    `};

  &:is([data-selected="true"], [data-expanded="true"]) ${StyledTD} {
    ${mediumSurfaceOverrides}
  }

  ${(p) =>
    p.isLast &&
    p.variant === "primary" &&
    css`
      &:not([data-expanded="true"]) {
        border-bottom-left-radius: ${p.theme.borderRadiuses.lg}px;
        border-bottom-right-radius: ${p.theme.borderRadiuses.lg}px;

        & > [role="cell"] {
          border-bottom: none;
        }

        & > [role="cell"]:first-child {
          border-bottom-left-radius: ${p.theme.borderRadiuses.lg}px;
        }

        & > [role="cell"]:last-child {
          border-bottom-right-radius: ${p.theme.borderRadiuses.lg}px;
        }
      }
    `}
`;

const primary: TableVariantStyleMap = {
  table: css`
    border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  `,
  th: css`
    ${(p) => p.theme.typography.variants.subtitleSm}
    ${weakSurfaceOverrides}
    background-color: ${(p) => p.theme.colors.neutrals.surface};
    color: ${(p) => p.theme.colors.neutrals.text};

    &:first-child {
      border-top-left-radius: ${(p) => p.theme.borderRadiuses.lg}px;
    }

    &:first-child {
      border-top-right-radius: ${(p) => p.theme.borderRadiuses.lg}px;
    }
  `,
  td: css``,
};

const secondary: TableVariantStyleMap = {
  table: css`
    border-radius: 0;
  `,
  th: css`
    ${(p) => p.theme.typography.variants.subtitleSm}
    ${weakSurfaceOverrides}
    color: ${(p) => p.theme.colors.neutrals.textWeak};
    background-color: ${(p) => p.theme.colors.neutrals.surface};
  `,
  td: css``,
};

const tableThemeStyles = {
  primary,
  secondary,
} as const;

export const tableVariants: TableVariant[] = ["primary", "secondary"];

export type TableVariant = keyof typeof tableThemeStyles;

interface StyledDataTableProps {
  variant: TableVariant;
}

export const StyledDataTable = styled.div<StyledDataTableProps>`
  width: 100%;
  overflow-x: auto;
  ${(p) => p.theme.typography.variants.bodyDefault}
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border-collapse: separate;
  text-align: left;

  ${(p) => tableThemeStyles[p.variant].table}
`;

interface StyledTHProps {
  canSort?: boolean;
  maxSize?: number;
  size?: number;
  variant: TableVariant;
}

export const StyledTH = styled.div<StyledTHProps>`
  flex: ${(p) => p.size ?? 150} 0 auto;
  width: ${(p) => p.size ?? 150}px;
  min-width: 0;
  ${(p) =>
    p.maxSize &&
    css`
      max-width: ${p.maxSize}px;
    `}
  padding: 1rem 0.5rem calc(1rem - 1px);
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};

  ${(p) =>
    p.canSort &&
    css`
      cursor: pointer;
    `}

  ${(p) => tableThemeStyles[p.variant].th}

  &:first-child {
    padding-left: 0.75rem;
  }

  &:last-child {
    padding-right: 0.75rem;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const EndTD = styled.div`
  display: flex;
  margin-left: auto;
  gap: 0.5rem;
`;

export const HeaderActionTH = styled.div`
  margin: -0.375rem 0;
`;
