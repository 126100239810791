import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnSort,
  type ExpandedState,
  type OnChangeFn,
  type PaginationState,
  type Row,
  type RowSelectionState,
  type SortingState,
  type TableOptions,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import ScrollHorizontal from "../ScrollHorizontal/ScrollHorizontal";
import SortArrow from "./SortArrow";
import {
  StyledDataTable,
  StyledHeader,
  StyledTD,
  StyledTH,
  StyledTR,
  type TableVariant,
} from "./styles";

export interface SubRowComponentProps<D extends NonNullable<unknown>> {
  isExpanded: boolean;
  row: D;
}
export interface DataTableProps<D extends NonNullable<unknown>> {
  clickableRows?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<D, any>[];
  data: D[];
  "data-testid"?: string;
  expandedIds?: readonly string[];
  getRowId: TableOptions<D>["getRowId"];
  page: number;
  perPage: number;
  onExpandedIdsChange?: (expandedIds: string[]) => void;
  onSelectedIdsChange?: (selectedIds: string[]) => void;
  onSortingChange?: (sorting: ColumnSort) => void;
  renderRow?: (row: Row<D>, rowElement: React.ReactElement) => React.ReactNode;
  subRowComponent?: React.ComponentType<SubRowComponentProps<D>>;
  selectedIds?: readonly string[];
  sorting?: SortingState;
  variant?: TableVariant;
}

const DEFAULT_SORTING: SortingState = [];

const DataTable = <D extends NonNullable<unknown>>({
  clickableRows,
  columns,
  data,
  "data-testid": dataTestId,
  expandedIds = [],
  getRowId,
  onExpandedIdsChange,
  onSelectedIdsChange,
  onSortingChange: _onSortingChange,
  page,
  perPage,
  renderRow,
  subRowComponent: SubRowComponent,
  selectedIds = [],
  sorting = DEFAULT_SORTING,
  variant = "primary",
}: DataTableProps<D>) => {
  const pagination = useMemo<PaginationState>(
    () => ({
      pageIndex: page,
      pageSize: perPage,
    }),
    [page, perPage],
  );

  const onSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    const updatedSorting =
      updaterOrValue instanceof Function
        ? updaterOrValue(sorting)
        : updaterOrValue;

    const [columnSort] = updatedSorting;

    _onSortingChange?.(columnSort);
  };

  const rowSelection = Object.fromEntries(selectedIds.map((id) => [id, true]));

  const onSelectedChange: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
    const updatedSelected =
      updaterOrValue instanceof Function
        ? updaterOrValue(rowSelection)
        : updaterOrValue;

    const newSelectedIds = Object.entries(updatedSelected)
      .filter(([, selectedId]) => !!selectedId)
      .map(([id]) => id);

    onSelectedIdsChange?.(newSelectedIds);
  };

  const expanded = expandedIds.reduce<Record<string, boolean>>((acc, val) => {
    acc[val] = true;
    return acc;
  }, {});

  const onExpandedChange: OnChangeFn<ExpandedState> = (updaterOrValue) => {
    const updatedExpanded =
      updaterOrValue instanceof Function
        ? updaterOrValue(expanded)
        : updaterOrValue;

    if (updatedExpanded === true) {
      onExpandedIdsChange?.(
        data.map((row, index) =>
          // If `getRowId` not passed, row index is used as ID for row.
          getRowId ? getRowId(row, index) : `${index}`,
        ),
      );
      return;
    }

    const newExpandedIds = Object.entries(updatedExpanded)
      .filter(([, expandedId]) => !!expandedId)
      .map(([id]) => id);

    onExpandedIdsChange?.(newExpandedIds);
  };

  const table = useReactTable({
    columns,
    data,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onExpandedChange,
    onRowSelectionChange: onSelectedChange,
    onSortingChange,
    state: {
      expanded,
      pagination,
      sorting,
      rowSelection,
    },
    enableMultiRowSelection: true,
    enableRowSelection: true,
    manualExpanding: true,
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <ScrollHorizontal>
      <StyledDataTable data-testid={dataTestId} role="table" variant={variant}>
        <div>
          {table.getHeaderGroups().map((headerGroup) => (
            <StyledTR key={headerGroup.id} role="row" variant={variant}>
              {headerGroup.headers.map((header) => {
                const canSort = header.column.getCanSort();
                const onClick = canSort
                  ? header.column.getToggleSortingHandler()
                  : undefined;
                const sortDirection = header.column.getIsSorted();
                const isSorted = !!sortDirection;
                return (
                  <StyledTH
                    canSort={canSort}
                    key={header.id}
                    maxSize={header.column.columnDef.maxSize}
                    onClick={onClick}
                    role="columnheader"
                    size={header.getSize()}
                    variant={variant}
                  >
                    <StyledHeader
                      data-testid={
                        dataTestId && `${dataTestId}-header-${header.id}`
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {isSorted && (
                        <SortArrow
                          data-testid={dataTestId && `${dataTestId}-sort-arrow`}
                          isDesc={sortDirection === "desc"}
                        />
                      )}
                    </StyledHeader>
                  </StyledTH>
                );
              })}
            </StyledTR>
          ))}
        </div>
        {table.getRowModel().rows.map((row, index, array) => {
          const isExpanded =
            typeof expanded === "boolean"
              ? expanded
              : !!expanded && !!expanded[row.id];
          const isSelected = row.getIsSelected();
          const isLast = index === array.length - 1;

          const rowElement = (
            <StyledTR
              isClickable={clickableRows}
              isLast={isLast}
              role="row"
              variant={variant}
              data-expanded={isExpanded}
              data-selected={isSelected}
              data-testid={dataTestId && `${dataTestId}-row-${row.index}`}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <StyledTD
                    key={cell.id}
                    maxSize={cell.column.columnDef.maxSize}
                    role="cell"
                    size={cell.column.getSize()}
                    variant={variant}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </StyledTD>
                );
              })}
            </StyledTR>
          );

          const result = renderRow ? renderRow(row, rowElement) : rowElement;

          if (!SubRowComponent) {
            return <React.Fragment key={row.id}>{result}</React.Fragment>;
          }

          return (
            <React.Fragment key={row.id}>
              {result}
              <SubRowComponent isExpanded={isExpanded} row={row.original} />
            </React.Fragment>
          );
        })}
      </StyledDataTable>
    </ScrollHorizontal>
  );
};

export default DataTable;
