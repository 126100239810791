import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import styled from "styled-components";
import { Check } from "../../icons";
import StatusMessage from "../StatusMessage/StatusMessage";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";
import Text from "../Text/Text";
import {
  StyledControl,
  StyledCheckbox,
  StyledChecked,
  StyledLabel,
  StyledContent,
  StyledText,
  StyledUnchecked,
} from "./styled";

export type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "type"
> & {
  "aria-label"?: string;
  "data-testid"?: string;
  error?: HookFormFieldError | string;
  hint?: React.ReactNode;
  label?: React.ReactNode;
};

const StyledError = styled.div`
  padding-top: 0.25rem;
`;

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  {
    "aria-label": ariaLabel,
    "data-testid": dataTestId,
    error,
    hint,
    id,
    label,
    ...props
  }: CheckboxProps,
  ref,
) => {
  let errorMessage: string | undefined;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error) {
    errorMessage = error.message;
  }

  return (
    <StyledCheckbox
      data-testid={dataTestId && `${dataTestId}`}
      htmlFor={id}
      /**
       * Unless we use an explicit tabIndex value here, this element will not
       * populate the `event.relatedTarget` of a blur when clicking the label.
       *
       * This is nice to have when inspecting the element that is gaining
       * focus inside `onBlur` to implement certain UX patterns.
       *
       * @see @see https://stackoverflow.com/a/42764495
       */
      tabIndex={-1}
    >
      <StyledHiddenInput
        {...props}
        data-testid={dataTestId && `${dataTestId}-input`}
        id={id}
        ref={ref}
        type="checkbox"
        aria-label={ariaLabel}
      />
      <StyledContent>
        <StyledControl data-testid={dataTestId && `${dataTestId}-control`}>
          <StyledChecked>
            <Check />
          </StyledChecked>
          <StyledUnchecked />
        </StyledControl>
        {(label || hint) && (
          <StyledText>
            {label && (
              <StyledLabel data-testid={dataTestId && `${dataTestId}-label`}>
                {label}
              </StyledLabel>
            )}
            {hint && (
              <Text
                data-testid={dataTestId && `${dataTestId}-hint`}
                size="footnote"
                variant="weak"
              >
                {hint}
              </Text>
            )}
          </StyledText>
        )}
      </StyledContent>
      {errorMessage && (
        <StyledError>
          <StatusMessage variant="error">{errorMessage}</StatusMessage>
        </StyledError>
      )}
    </StyledCheckbox>
  );
};

export default forwardRef(Checkbox);
