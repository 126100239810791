import React, { createContext, useContext, useMemo, useState } from "react";
import type { MapBounds } from "./MapProvider";

type MapStoreContextValue = {
  bounds: MapBounds | undefined;
  setBounds: React.Dispatch<React.SetStateAction<MapBounds | undefined>>;
};

const MapStoreContext = createContext<MapStoreContextValue | undefined>(
  undefined,
);

interface MapStoreProviderProps {
  children?: React.ReactNode;
}

const MapStoreProvider = ({ children }: MapStoreProviderProps) => {
  const [bounds, setBounds] = useState<MapBounds | undefined>();

  const value = useMemo<MapStoreContextValue>(
    () => ({
      bounds,
      setBounds,
    }),
    [bounds],
  );

  return (
    <MapStoreContext.Provider value={value}>
      {children}
    </MapStoreContext.Provider>
  );
};

export default MapStoreProvider;

export const useMapStoreContext = () => {
  const value = useContext(MapStoreContext);

  if (!value) {
    throw new Error(
      "useMapStoreContext must be used inside a MapStoreProvider",
    );
  }

  return value;
};
