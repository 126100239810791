import { useMenu } from "@react-aria/menu";
import { Item } from "@react-stately/collections";
import { useTreeState } from "@react-stately/tree";
import type { AriaMenuProps } from "@react-types/menu";
import { useRef } from "react";
import styled from "styled-components";
import LinkDropdownMenuItem from "./LinkDropdownMenuItem";

const StyledLinkDropdownMenu = styled.ul`
  display: grid;
  gap: 0.25rem;
  padding: 0.25rem;

  &:focus-visible {
    outline: none;
  }
`;

export interface LinkDropdownItemProps {
  children?: React.ReactNode;
  label?: string;
  icon: ReactSVGComponent;
  id: string;
  textValue: string;
}

export const LinkDropdownItem = Item as (
  props: LinkDropdownItemProps,
) => JSX.Element;

interface LinkDropdownMenuProps extends AriaMenuProps<LinkDropdownItemProps> {
  "data-testid"?: string;
}

const LinkDropdownMenu = ({
  "data-testid": dataTestId,
  ...props
}: LinkDropdownMenuProps) => {
  const state = useTreeState<LinkDropdownItemProps>(props);

  const ref = useRef<HTMLUListElement>(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <StyledLinkDropdownMenu {...menuProps} data-testid={dataTestId} ref={ref}>
      {[...state.collection].map((item) => {
        const itemProps = item.props as LinkDropdownItemProps;
        return (
          <LinkDropdownMenuItem
            data-testid={`${dataTestId}-${itemProps.id}`}
            key={item.key}
            icon={itemProps.icon}
            item={item}
            state={state}
          >
            {item.rendered}
          </LinkDropdownMenuItem>
        );
      })}
    </StyledLinkDropdownMenu>
  );
};

export default LinkDropdownMenu;
