import styled, { css } from "styled-components";
import { focusStyles, mediumStyles } from "../Button/states";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";

const checkboxSizeStyles = css`
  margin-top: 2px;
  width: 1rem;
  height: 1rem;

  svg {
    height: 1.125rem;
    width: 1.125rem;
    transform: translate(-2px, -2px);
  }
`;

export const StyledCheckbox = styled.label`
  display: block;
`;

export const StyledChecked = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderSelected};
  background-color: ${(p) => p.theme.colors.neutrals.backgroundSelected};
  color: ${(p) => p.theme.colors.neutrals.background};
`;

export const StyledUnchecked = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  ${mediumStyles.background.base}
  ${mediumStyles.border.base}
`;

export const StyledControl = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  ${checkboxSizeStyles}
`;

export const StyledText = styled.div`
  display: grid;
  gap: 0.125rem;
  width: 100%;
`;

export const StyledLabel = styled.div`
  word-break: break-word;
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

export const StyledContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: start;

  ${StyledChecked} {
    display: none;
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:hover {
    ${StyledUnchecked} {
      ${mediumStyles.background.hover}
      ${mediumStyles.border.hover}
    }
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:active {
    ${StyledUnchecked} {
      ${mediumStyles.background.active}
      ${mediumStyles.border.active}
    }
  }

  ${StyledHiddenInput}:disabled ~ & {
    cursor: not-allowed;

    ${StyledUnchecked} {
      ${mediumStyles.background.disabled}
      ${mediumStyles.border.disabled}
    }
  }

  ${StyledHiddenInput}:focus-visible ~ & ${StyledControl} {
    ${focusStyles("ring")}
  }

  ${StyledHiddenInput}:checked ~ & {
    ${StyledChecked} {
      display: block;
    }

    ${StyledUnchecked} {
      display: none;
    }
  }

  ${StyledHiddenInput}:disabled ~ & {
    ${StyledChecked} {
      ${mediumStyles.border.disabled}
      border-color: ${(p) =>
        p.theme.colors.neutrals.backgroundSelectedDisabled};
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundSelectedDisabled};
    }

    ${StyledLabel} {
      color: ${(p) => p.theme.colors.neutrals.textDisabled};
    }
  }
`;
