import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import {
  OverlayContainer,
  useModal,
  useOverlay,
  usePreventScroll,
} from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import type { DOMProps } from "@react-types/shared";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { useTheme } from "styled-components";
import { Close } from "../../icons";
import underlayStyles from "../../lib/styled/underlayStyles";
import media from "../../theme/media";
import IconButton from "../IconButton/IconButton";

const StyledDrawer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(p) => p.theme.colors.neutrals.background};

  @media ${media.lg} {
    width: 310px;
    padding: 16px;
  }

  @media ${media.xxl} {
    width: 380px;
  }

  &:focus-visible {
    outline: none;
  }
`;

const StyledUnderlay = styled.div`
  ${underlayStyles}
  z-index: ${(p) => p.theme.zIndexes.navDrawer};

  &.enter,
  &.appear {
    ${StyledDrawer} {
      transform: translateY(-100%);

      @media ${media.lg} {
        transform: translateX(-100%);
      }
    }
  }

  &.enter-active,
  &.appear-active {
    ${StyledDrawer} {
      transform: translateY(0);
      transition: transform
        ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
    }
  }

  &.exit {
    ${StyledDrawer} {
      transform: translateY(0);
    }
  }

  &.exit-active {
    ${StyledDrawer} {
      transform: translateY(-100%);

      @media ${media.lg} {
        transform: translateX(-100%);
      }
      transition: transform
        ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
    }
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 24px 14px 0 28px;
  ${(p) => p.theme.typography.variants.subtitleLg};
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &:focus {
    outline: none;
  }
`;

interface DrawerOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  "data-testid"?: string;
  overlayState: OverlayTriggerState;
  title: string;
  underlayRef?: React.Ref<HTMLDivElement>;
}

const DrawerOverlay = ({
  children,
  "data-testid": dataTestId,
  overlayState,
  title,
  underlayRef,
  ...props
}: DrawerOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: overlayState.isOpen,
      onClose: overlayState.close,
    },
    overlayRef,
  );

  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, overlayRef);
  usePreventScroll();

  return (
    <StyledUnderlay {...underlayProps} ref={underlayRef}>
      <FocusScope contain restoreFocus>
        <StyledDrawer
          {...props}
          {...modalProps}
          {...dialogProps}
          {...overlayProps}
          ref={overlayRef}
          data-testid={dataTestId}
        >
          <StyledContent>
            <StyledTitle>
              {title}
              <IconButton
                icon={Close}
                label="Close"
                onClick={overlayState.close}
                variant="ghost"
                data-testid={dataTestId && `${dataTestId}-close-button`}
              />
            </StyledTitle>
            {children}
          </StyledContent>
        </StyledDrawer>
      </FocusScope>
    </StyledUnderlay>
  );
};

export interface DrawerProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  overlayProps: DOMProps;
  overlayTriggerState: OverlayTriggerState;
  title: string;
}

const Drawer = ({
  children,
  "data-testid": dataTestId,
  overlayProps,
  overlayTriggerState,
  title,
}: DrawerProps) => {
  const theme = useTheme();
  const underlayRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={overlayTriggerState.isOpen}
      mountOnEnter
      nodeRef={underlayRef}
      timeout={{
        enter: theme.anim.duration.sm,
        exit: theme.anim.duration.sm,
      }}
      unmountOnExit
    >
      <OverlayContainer>
        <DrawerOverlay
          {...overlayProps}
          overlayState={overlayTriggerState}
          underlayRef={underlayRef}
          data-testid={dataTestId && `${dataTestId}-overlay`}
          title={title}
        >
          {children}
        </DrawerOverlay>
      </OverlayContainer>
    </CSSTransition>
  );
};

export default Drawer;
