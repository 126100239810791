import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Mousewheel, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import type { SwiperOptions } from "swiper/types";
import { ArrowBackShort, ArrowForwardShort } from "../../icons";
import { weakStyles } from "../Button/states";

export type CarouselOptions = SwiperOptions;
export type CarouselBreakpoints = Record<number, CarouselOptions>;

const StyledCarousel = styled(Swiper)`
  display: flex;
  padding: 0 2.5rem;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .swiper-wrapper {
    height: auto;
    flex: 1;
    align-items: stretch;
    min-height: 0;
  }

  .swiper-slide {
    height: auto;
  }
`;

const navigationStyles = css`
  position: absolute;
  top: 50%;
  width: 2rem;
  height: 2rem;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  color: ${(p) => p.theme.colors.neutrals.text};
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.boxShadows.base};
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);

  &:hover:not(.swiper-button-disabled) {
    ${weakStyles.background.hover}
  }

  &:active:not(.swiper-button-disabled) {
    ${weakStyles.background.active}
  }

  &.swiper-button-disabled {
    color: ${(p) => p.theme.colors.neutrals.iconDisabled};
    cursor: not-allowed;
  }

  svg {
    width: 1.25rem;
    height: auto;
  }
`;

const StyledPrev = styled.div`
  ${navigationStyles}
  left: 0.125rem;
`;

const StyledNext = styled.div`
  ${navigationStyles}
  right: 0.125rem;
`;

interface CarouselProps {
  activeIndex?: number;
  breakpoints?: CarouselBreakpoints;
  children: React.ReactNode;
  "data-testid"?: string;
}

const Carousel = ({
  activeIndex = 0,
  breakpoints,
  children,
  "data-testid": dataTestId,
}: CarouselProps) => {
  const [nextEl, setNextEl] = useState<HTMLDivElement | null>(null);
  const [prevEl, setPrevEl] = useState<HTMLDivElement | null>(null);
  return (
    <StyledCarousel
      breakpoints={breakpoints}
      data-testid={dataTestId}
      initialSlide={activeIndex}
      mousewheel={{ forceToAxis: true }}
      modules={[Mousewheel, Navigation]}
      navigation={{
        nextEl,
        prevEl,
      }}
      spaceBetween={8}
    >
      {children}
      <StyledPrev
        ref={setPrevEl}
        data-testid={dataTestId && `${dataTestId}-previous-button`}
      >
        <ArrowBackShort />
      </StyledPrev>
      <StyledNext
        ref={setNextEl}
        data-testid={dataTestId && `${dataTestId}-next-button`}
      >
        <ArrowForwardShort />
      </StyledNext>
    </StyledCarousel>
  );
};

export default Carousel;

export { SwiperSlide as CarouselSlide };
