import { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import linkReset from "../../lib/styled/linkReset";
import media from "../../theme/media";
import Button from "../Button/Button";
import {
  buttonSizeStyles,
  buttonVariantStyles,
  commonButtonStyles,
  type ButtonSize,
  type ButtonVariant,
  iconSizeStyles,
  buttonIconSizes,
} from "../Button/styled";

interface StyledAnchorButtonProps {
  fullWidth: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
}

const StyledAnchorButton = styled.a<StyledAnchorButtonProps>`
  ${linkReset}
  ${commonButtonStyles}
  ${buttonSizeStyles.md}
  ${(p) => buttonVariantStyles[p.variant]}
  ${(p) => p.fullWidth && "width: 100%"};
  gap: 0.375rem;
  cursor: pointer;

  svg {
    ${iconSizeStyles(buttonIconSizes.md)}
  }

  @media ${media.lg} {
    ${(p) => buttonSizeStyles[p.size]}

    svg {
      ${(p) => iconSizeStyles(buttonIconSizes[p.size])}
    }
  }
`;

export interface AnchorButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactSVGComponent;
  size?: ButtonSize;
  variant?: ButtonVariant;
  "data-testid"?: string;
}

const AnchorButton: ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  AnchorButtonProps
> = (
  {
    children,
    disabled,
    fullWidth = false,
    icon: Icon,
    size = "md",
    variant = "primary",
    "data-testid": dataTestId,
    ...htmlProps
  }: AnchorButtonProps,
  ref,
) => {
  if (disabled) {
    return (
      <Button
        disabled={disabled}
        fullWidth={fullWidth}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        size={size}
        variant={variant}
        data-testid={dataTestId}
      >
        {Icon && <Icon />}
        {children}
      </Button>
    );
  }
  return (
    <StyledAnchorButton
      {...htmlProps}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      size={size}
      variant={variant}
    >
      {Icon && <Icon />}
      {children}
    </StyledAnchorButton>
  );
};

export default forwardRef(AnchorButton);
