import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import {
  type SidebarPosition,
  StyledSidebar,
  StyledSidebarAdornment,
  StyledSidebarContainer,
  StyledSidebarContent,
  type StyledSidebarProps,
} from "./styled";
import type { UseSidebarResult } from "./useSidebar";

export { StyledSidebar, StyledSidebarContainer, StyledSidebarContent };
export type { StyledSidebarProps };

export interface SidebarProps extends Omit<UseSidebarResult, "ref"> {
  adornment?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  position?: SidebarPosition;
}

/**
 * Don't use this component -- it is legacy and only used within the legacy
 * sidesheet that is used on the upload prediction screens. Do not export it
 * from the design system. The only Sidebar being exported is the new style
 * sidebar used for settings pages.
 */
const Sidebar: ForwardRefRenderFunction<HTMLDivElement, SidebarProps> = (
  {
    adornment,
    children,
    className,
    "data-testid": dataTestId,
    isCollapsed,
    isOnscreen,
    mode,
    position = "start",
  }: SidebarProps,
  ref,
) => {
  return (
    <StyledSidebar
      className={className}
      isOnscreen={isOnscreen}
      $mode={mode}
      position={position}
      ref={ref}
      data-testid={dataTestId}
    >
      {adornment && (
        <StyledSidebarAdornment>{adornment}</StyledSidebarAdornment>
      )}
      <StyledSidebarContainer isCollapsed={isCollapsed} position={position}>
        <StyledSidebarContent isCollapsed={isCollapsed}>
          {children}
        </StyledSidebarContent>
      </StyledSidebarContainer>
    </StyledSidebar>
  );
};

export default forwardRef(Sidebar);
