import React, { useRef } from "react";
import styled from "styled-components";
import { weakSurfaceOverrides } from "../../theme/colorsLight";
import { focusStyles, mediumStyles, weakStyles } from "../Button/states";
import {
  getInputSizeProperties,
  inputContainerCssProperties,
} from "../InputContainer/InputContainer";

const StyledActionInput = styled.div`
  ${getInputSizeProperties({
    gap: 4,
    padding: [3, 12],
    svgSize: 16,
    typographySize: "bodyDefault",
  })}
`;

const StyledInput = styled.input`
  display: block;
  flex: 1;
  min-width: 0;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:focus-visible {
    outline: none;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: var(${inputContainerCssProperties.paddingY})
    var(${inputContainerCssProperties.paddingX});
  padding-right: 0.1875rem;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  ${(p) => p.theme.typography.variants.bodyDefault}
  align-items: center;
  gap: 0.5rem;
  cursor: text;
  ${weakStyles.background.base}
  ${mediumStyles.border.base}

  &:has(${StyledInput}:focus) {
    ${focusStyles("ring")}
  }
`;

const StyledButton = styled.div`
  ${weakSurfaceOverrides}
`;

interface ActionInputProps {
  action?: React.ReactNode;
  value?: string;
  "data-testid"?: string;
}

const ActionInput = ({
  action,
  value,
  "data-testid": dataTestId,
}: ActionInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = () => {
    inputRef.current?.select();
  };
  return (
    <StyledActionInput data-testid={dataTestId}>
      <StyledInputContainer>
        <StyledInput onClick={onClick} readOnly ref={inputRef} value={value} />
        <StyledButton data-testid={dataTestId && `${dataTestId}-button`}>
          {action}
        </StyledButton>
      </StyledInputContainer>
    </StyledActionInput>
  );
};

export default ActionInput;
