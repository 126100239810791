import { Overlay } from "@react-aria/overlays";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useTheme } from "../../theme";
import Modal, { type ModalProps } from "./Modal";

type ModalOverlayProps = Omit<ModalProps, "underlayRef">;

const ModalOverlay = ({ children, ...props }: ModalOverlayProps) => {
  const theme = useTheme();
  const { isOpen } = props;
  const underlayRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      appear
      in={isOpen}
      mountOnEnter
      nodeRef={underlayRef}
      timeout={theme.anim.duration.sm}
      unmountOnExit
    >
      <Overlay>
        <Modal underlayRef={underlayRef} {...props}>
          {children}
        </Modal>
      </Overlay>
    </CSSTransition>
  );
};

export default ModalOverlay;
