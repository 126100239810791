import styled from "styled-components";
import { AttachFile } from "../../icons";
import linkReset from "../../lib/styled/linkReset";
import { focusStyles } from "../Button/states";
import Field from "../Field/Field";
import FileDisplay from "./FileDisplay";

interface LinkFileDisplayProps {
  "data-testid"?: string;
  filename: string;
  icon?: ReactSVGComponent;
  label?: React.ReactNode;
  url: string;
}

const StyledAnchor = styled.a`
  ${linkReset}
  max-width: 100%;
  overflow: hidden;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  &:focus-visible {
    ${focusStyles("ring")}
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.colors.primary.text};
  }
`;

const LinkFileDisplay = ({
  "data-testid": dataTestId,
  filename,
  icon = AttachFile,
  label,
  url,
}: LinkFileDisplayProps) => {
  return (
    <Field label={label}>
      <StyledAnchor download href={url} target="_blank" title={filename}>
        <FileDisplay icon={icon} data-testid={dataTestId}>
          {filename}
        </FileDisplay>
      </StyledAnchor>
    </Field>
  );
};

export default LinkFileDisplay;
