import styled, { css } from "styled-components";
import {
  DangerOutline,
  Done,
  ErrorOutline,
  Info,
  InProgress,
  Warning,
} from "../../icons";
import listStyles from "../../lib/styled/listStyles";
import type { StatusVariant } from "../../types/design-system";
import Text from "../Text/Text";
import { DEFAULT_ERROR_MESSAGE } from "../Toast/Toast";

export const statusIconMap: Record<StatusVariant, ReactSVGComponent> = {
  danger: DangerOutline,
  error: ErrorOutline,
  info: Info,
  progress: InProgress,
  success: Done,
  warning: Warning,
};

export const alertVariantStyleMap: Record<
  StatusVariant,
  ReturnType<typeof css>
> = {
  danger: css`
    color: ${(p) => p.theme.colors.danger.textOnBackground};
    background-color: ${(p) => p.theme.colors.danger.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.danger.icon};
    }
  `,
  error: css`
    color: ${(p) => p.theme.colors.error.textOnBackground};
    background-color: ${(p) => p.theme.colors.error.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.error.icon};
    }
  `,
  info: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.informative.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.informative.icon};
    }
  `,
  progress: css`
    color: ${(p) => p.theme.colors.neutrals.text};
    background-color: ${(p) => p.theme.colors.informative.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.informative.icon};
    }
  `,
  success: css`
    color: ${(p) => p.theme.colors.success.textOnBackground};
    background-color: ${(p) => p.theme.colors.success.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.success.icon};
    }
  `,
  warning: css`
    color: ${(p) => p.theme.colors.warning.textOnBackground};
    background-color: ${(p) => p.theme.colors.warning.backgroundWeak};

    svg {
      color: ${(p) => p.theme.colors.warning.icon};
    }
  `,
};

interface StyledAlertProps {
  layout: AlertLayout;
  variant: StatusVariant;
}

export const StyledAlert = styled.div<StyledAlertProps>`
  display: grid;

  ${(p) => {
    switch (p.layout) {
      case "default": {
        return css`
          grid-template-columns: auto minmax(0, 1fr);
          gap: 0.5rem;
        `;
      }
      case "stacked": {
        return css`
          align-content: start;
          gap: 0.25rem;
        `;
      }
    }
  }}
  padding: 0.75rem 1rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  ${(p) => alertVariantStyleMap[p.variant]}

  svg {
    width: ${(p) => p.theme.typography.variants.subtitleSm["line-height"]};
    height: ${(p) => p.theme.typography.variants.subtitleSm["line-height"]};
  }

  ${listStyles}
`;

const StyledContent = styled.div`
  text-wrap: pretty;
`;

export interface AlertContent {
  title: string;
  message?: string;
}

const alertChildren = (variant: StatusVariant, children?: React.ReactNode) => {
  if (variant === "error") {
    return children || DEFAULT_ERROR_MESSAGE;
  }
  return children;
};

export type AlertLayout = "default" | "stacked";

export interface AlertProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  layout?: AlertLayout;
  title: string;
  variant: StatusVariant;
}

const Alert = ({
  children,
  "data-testid": dataTestId,
  layout = "default",
  title,
  variant,
}: AlertProps) => {
  const Icon = statusIconMap[variant];
  const message = alertChildren(variant, children);

  return (
    <StyledAlert
      data-testid={dataTestId}
      layout={layout}
      role="status"
      variant={variant}
    >
      <Icon />
      <StyledContent>
        <Text size="subtitleSm">{title}</Text>
        {message && <Text size="bodyDefault">{message}</Text>}
      </StyledContent>
    </StyledAlert>
  );
};

export default Alert;
