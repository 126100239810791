import React, { createContext, useContext, useMemo } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyles from "../lib/styled/GlobalStyles";
import { lightColors } from "./colorsLight";
import type { BadgeStyle } from "./constants";
import { theme, type Theme } from "./theme";
import { colorsToProperties } from "./transformColors";

interface ThemeContextValue {
  theme: Theme;
}

const ThemeContext = createContext<ThemeContextValue | undefined>(undefined);

interface ThemeProviderProps {
  badgeStyle?: BadgeStyle;
  children?: React.ReactNode;
}

export const ThemeProvider = ({
  badgeStyle = "classic",
  children,
}: ThemeProviderProps) => {
  const value = useMemo<ThemeContextValue>(() => {
    return {
      theme: {
        ...theme,
        badges: {
          badgeStyle,
        },
        colors: lightColors, // mode === "dark" ? darkColors : lightColors,
      },
    };
  }, [badgeStyle]);

  // values are swapped with the css variable name used
  const themeWithCssVariables = useMemo(() => {
    return {
      ...theme,
      badges: {
        badgeStyle,
      },
      colors: colorsToProperties(
        lightColors, // mode === "dark" ? darkColors : lightColors
      ),
    };
  }, [badgeStyle]);

  // ThemeContext.Provider enables access to design tokens from the theme
  //  in the context of a component
  // StyledThemeProvider enables access to the theme in any `styled` components
  //  created in the application consuming the design system
  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={themeWithCssVariables}>
        <GlobalStyles />
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

// useTheme provides access to the current theme
export function useTheme(): Theme {
  const themeCtx = useContext(ThemeContext);

  if (!themeCtx) {
    throw new Error("useTheme must be used inside a ThemeProvider");
  }

  return themeCtx.theme;
}
