import { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { type DefaultTheme } from "styled-components";

export type IconVariant =
  | "danger"
  | "disabled"
  | "error"
  | "info"
  | "inherit"
  | "inverse"
  | "neutral"
  | "primary"
  | "progress"
  | "success"
  | "weak"
  | "warning";

interface IconVariantStylesProps {
  theme: DefaultTheme;
  variant?: IconVariant;
}

export const getIconVariantColor = ({
  theme,
  variant,
}: IconVariantStylesProps) => {
  switch (variant) {
    case "primary":
      return theme.colors.primary.icon;
    case "neutral":
      return theme.colors.neutrals.text;
    case "info":
    case "progress":
      return theme.colors.informative.icon;
    case "danger":
      return theme.colors.danger.icon;
    case "error":
      return theme.colors.error.icon;
    case "disabled":
      return theme.colors.neutrals.iconDisabled;
    case "inverse":
      return theme.colors.neutrals.background;
    case "weak":
      return theme.colors.neutrals.iconWeak;
    case "success":
      return theme.colors.success.icon;
    case "warning":
      return theme.colors.warning.icon;
    case "inherit":
    default:
      return "inherit";
  }
};

export const STATIC_ICON_WRAPPER_SIZES = [
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
  "xxxl",
] as const;

export type StaticIconWrapperSize = (typeof STATIC_ICON_WRAPPER_SIZES)[number];

type StaticIconWrapperSizeMap = Record<StaticIconWrapperSize, number>;

export interface StaticIconWrapperProps {
  variant?: IconVariant;
  "data-testid"?: string;
  icon: ReactSVGComponent;
  size?: StaticIconWrapperSize;
}

const iconWrapperSizeStyles: StaticIconWrapperSizeMap = {
  xxs: 12,
  xs: 16,
  sm: 20,
  md: 24,
  lg: 28,
  xl: 32,
  xxl: 40,
  xxxl: 44,
};

interface StyledIconWrapperProps {
  size: StaticIconWrapperSize;
  variant?: IconVariant;
}

const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  color: ${(p) => getIconVariantColor({ variant: p.variant, theme: p.theme })};
  svg {
    height: ${(p) => iconWrapperSizeStyles[p.size] / 16}rem;
    width: ${(p) => iconWrapperSizeStyles[p.size] / 16}rem;
  }
`;

const StaticIconWrapper: ForwardRefRenderFunction<
  HTMLDivElement,
  StaticIconWrapperProps
> = (
  {
    variant,
    "data-testid": dataTestId,
    icon: Icon,
    size = "md",
  }: StaticIconWrapperProps,
  ref,
) => {
  return (
    <StyledIconWrapper variant={variant} ref={ref} size={size}>
      <Icon data-testid={dataTestId} />
    </StyledIconWrapper>
  );
};

export default forwardRef(StaticIconWrapper);
