import { useCollapse } from "react-collapsed";
import styled, { css, useTheme } from "styled-components";
import { ExpandLess, ExpandMore } from "../../icons";
import IconButton from "../IconButton/IconButton";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

const StyledAccordion = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderExtraWeak};

  &:first-child {
    border-top-left-radius: ${(p) => p.theme.borderRadiuses.base}px;
    border-top-right-radius: ${(p) => p.theme.borderRadiuses.base}px;
  }

  &:last-child {
    border-bottom-left-radius: ${(p) => p.theme.borderRadiuses.base}px;
    border-bottom-right-radius: ${(p) => p.theme.borderRadiuses.base}px;
    border-bottom: none;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

const StyledLabel = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: inherit;
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

const StyledPanel = styled.div``;

interface StyledPannerInnerProps {
  hasDivider: boolean;
}

const StyledPanelInner = styled.div<StyledPannerInnerProps>`
  ${(p) =>
    p.hasDivider &&
    css`
      border-top: 1px solid ${p.theme.colors.neutrals.borderExtraWeak};
    `}
`;

export interface AccordionProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  defaultExpanded?: boolean;
  hasDivider?: boolean;
  /**
   * If you control `isExpanded` you must also listen to `onExpandedChange` to
   * respond to changes in expanded state
   */
  isExpanded?: boolean;
  icon?: ReactSVGComponent;
  onExpandedChange?: (isExpanded: boolean) => void;
  title: React.ReactNode;
}

const Accordion = ({
  children,
  "data-testid": dataTestId,
  defaultExpanded,
  hasDivider = true,
  onExpandedChange,
  title,
  icon,
  ...props
}: AccordionProps) => {
  const theme = useTheme();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded,
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
    // Consumer can optionally control `isExpanded`
    isExpanded: props.isExpanded,
  });

  return (
    <StyledAccordion data-testid={dataTestId && `${dataTestId}`}>
      <StyledHeader>
        <StyledLabel>
          {icon && (
            <TypographyIconWrapper
              data-testid={dataTestId && `${dataTestId}-icon-wrapper`}
              icon={icon}
              size="bodyLg"
            />
          )}
          {title}
        </StyledLabel>
        <IconButton
          {...getToggleProps({
            onClick: () => onExpandedChange?.(!isExpanded),
          })}
          data-testid={dataTestId && `${dataTestId}-trigger`}
          icon={isExpanded ? ExpandLess : ExpandMore}
          label={isExpanded ? "Collapse" : "Expand"}
          size="sm"
          variant="ghost"
        />
      </StyledHeader>
      <StyledPanel {...getCollapseProps()}>
        <StyledPanelInner hasDivider={hasDivider}>{children}</StyledPanelInner>
      </StyledPanel>
    </StyledAccordion>
  );
};

export default Accordion;
