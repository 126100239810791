import styled, { css } from "styled-components";
import { Done } from "../../icons";
import media from "../../theme/media";
import { focusStyles, weakStyles } from "../Button/states";

export const StyledDone = styled(Done)`
  margin-left: -0.125rem;
  display: none;
`;

const base = css`
  display: inline-flex;
  padding: calc(0.375rem - 1px) 0.75rem;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.bodyDefault}
  ${weakStyles.background.base}
  ${weakStyles.border.base}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  white-space: nowrap;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  svg {
    width: ${(p) => p.theme.typography.variants.bodyDefault["line-height"]};
    height: ${(p) => p.theme.typography.variants.bodyDefault["line-height"]};
  }

  button svg {
    width: 1rem;
    height: 1rem;
  }
`;

const hover = css`
  ${weakStyles.background.hover}
  ${weakStyles.border.hover}
`;

const active = css`
  ${weakStyles.background.active}
  ${weakStyles.border.active}

  /* react-aria useButton has strange behaviour where, in Chrome, it shows the
     :focus-visible styling on click even if you haven't triggered it by
     keyboard focusing. This style prevents that from happening.

     https://github.com/adobe/react-spectrum/issues/1247
  */
  && {
    outline: none;
  }
`;

const focus = css`
  ${focusStyles("ring")}
  z-index: 1;
`;

const disabled = css`
  ${weakStyles.background.disabled}
  ${weakStyles.border.disabled}
`;

const checked = css`
  background-color: ${(p) => p.theme.colors.primary.backgroundExtraWeak};
  border-color: ${(p) => p.theme.colors.primary.borderExtraWeakHover};

  ${StyledDone} {
    color: ${(p) => p.theme.colors.primary.icon};
    display: block;
  }
`;

const checkedHover = css`
  background-color: ${(p) => p.theme.colors.primary.backgroundExtraWeakHover};
`;

const checkedActive = css`
  background-color: ${(p) => p.theme.colors.primary.backgroundExtraWeakPressed};
  border-color: ${(p) => p.theme.colors.primary.borderExtraWeakPressed};
`;

export const chipStyles = {
  base,
  hover,
  active,
  disabled,
  focus,
  checked,
  checkedHover,
  checkedActive,
} as const;

export const StyledDialog = styled.div`
  outline: none;
`;

export const StyledDialogBody = styled.div`
  display: grid;
  padding: 0.75rem 0.75rem 0;
  gap: 0.75rem;
`;

interface StyledFormControlListProps {
  columns: number;
}

export const StyledFormControlList = styled.div<StyledFormControlListProps>`
  display: grid;
  gap: 0.5rem;

  @media ${media.lg} {
    grid-template-columns: repeat(${(p) => p.columns}, minmax(0, 1fr));
  }
`;
