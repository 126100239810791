import Dialog, { type DialogProps } from "./Dialog";
import type { ModalProps } from "./Modal";
import ModalOverlay from "./ModalOverlay";
import type { ModalSize } from "./styled";

export type ModalDialogProps = Omit<DialogProps & ModalProps, "size"> & {
  size?: ModalSize;
};

const ModalDialog = ({
  actions,
  children,
  hasHighlight,
  isDismissable,
  isKeyboardDismissDisabled,
  isOpen,
  onClose,
  title,
  error,
  footer,
  size = "sm",
}: ModalDialogProps) => {
  return (
    <ModalOverlay
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <Dialog
        actions={actions}
        error={error}
        footer={footer}
        hasHighlight={hasHighlight}
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
      >
        {children}
      </Dialog>
    </ModalOverlay>
  );
};

export default ModalDialog;
