import getEntries from "./getEntries";

export interface RecursiveReduceParams<T> {
  iteratee: (value: string, path: string) => string;
  path?: string;
  prefix?: string;
  separator?: string;
  source: T;
}

const recursiveReduce = <T extends DeeplyNestedRecord<T>>({
  iteratee,
  path,
  prefix,
  separator = "-",
  source,
}: RecursiveReduceParams<T>): string => {
  const entries = getEntries(source);

  return entries.reduce((acc, [key, value]) => {
    const nextPath = path
      ? `${path}${separator}${key}`
      : `${prefix ? `${prefix}${separator}` : ""}${key}`;

    if (typeof value === "string" || typeof value === "number") {
      return `
        ${acc}
        ${iteratee(value, nextPath)}
      `;
    }

    return `
        ${acc}
        ${recursiveReduce({
          iteratee,
          path: nextPath,
          separator,
          source: value as DeeplyNestedRecord,
        })}
      `;
  }, ``);
};

export default recursiveReduce;
