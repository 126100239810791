import { useDialog } from "@react-aria/dialog";
import { useRef } from "react";
import styled from "styled-components";
import { Close } from "../../icons";
import IconButton from "../IconButton/IconButton";
import Text from "../Text/Text";

const StyledPopoverDialog = styled.div`
  display: grid;
  padding: 0.75rem;
  gap: 0.5rem;
  outline: none;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

interface PopoverDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  title: React.ReactNode;
}

const PopoverDialog = ({ children, onClose, title }: PopoverDialogProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps, titleProps } = useDialog({}, ref);

  return (
    <StyledPopoverDialog {...dialogProps} ref={ref}>
      <StyledHeader>
        <Text size="eyebrowDefault" variant="weak" {...titleProps}>
          {title}
        </Text>
        <IconButton
          label="Close"
          icon={Close}
          onClick={onClose}
          size="xs"
          variant="ghost"
        />
      </StyledHeader>
      {children}
    </StyledPopoverDialog>
  );
};

export default PopoverDialog;
