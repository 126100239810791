import { css } from "styled-components";

interface StateTheme {
  background: StateStyleMap;
  border: StateStyleMap;
}

interface StateStyleMap {
  active: ReturnType<typeof css>;
  base: ReturnType<typeof css>;
  disabled: ReturnType<typeof css>;
  hover: ReturnType<typeof css>;
  checked?: ReturnType<typeof css>;
}

export const weakStyles = {
  background: {
    active: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakActive};
    `,
    base: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
      color: ${(p) => p.theme.colors.neutrals.text};
    `,
    disabled: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
      color: ${(p) => p.theme.colors.neutrals.textControlDisabled};
    `,
    hover: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundWeakHover};
    `,
  },
  border: {
    active: css`
      border-color: ${(p) => p.theme.colors.neutrals.borderWeakActive};
    `,
    base: css`
      border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
    `,
    disabled: css`
      border: 1px solid ${(p) => p.theme.colors.neutrals.borderDisabled};
    `,
    hover: css`
      border-color: ${(p) => p.theme.colors.neutrals.borderWeakHover};
    `,
  },
} satisfies StateTheme;

export const mediumStyles = {
  background: {
    active: css`
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundMediumActive};
    `,
    base: css`
      color: ${(p) => p.theme.colors.neutrals.text};
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
    `,
    disabled: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
      color: ${(p) => p.theme.colors.neutrals.textControlDisabled};
    `,
    hover: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    `,
  },
  border: {
    active: css`
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumActive};
    `,
    base: css`
      border: 1px solid ${(p) => p.theme.colors.neutrals.borderMedium};
    `,
    disabled: css`
      border-color: ${(p) => p.theme.colors.neutrals.borderDisabled};
    `,
    hover: css`
      border-color: ${(p) => p.theme.colors.neutrals.borderMediumHover};
    `,
  },
} satisfies StateTheme;

// No use for strong borders yet
export const strongStyles = {
  background: {
    active: css`
      background-color: ${(p) =>
        p.theme.colors.neutrals.backgroundStrongActive};
    `,
    base: css`
      color: ${(p) => p.theme.colors.neutrals.text};
      background-color: ${(p) => p.theme.colors.neutrals.backgroundStrong};
    `,
    disabled: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
      color: ${(p) => p.theme.colors.neutrals.textControlDisabled};
    `,
    hover: css`
      background-color: ${(p) => p.theme.colors.neutrals.backgroundStrongHover};
    `,
  },
} satisfies { background: StateStyleMap };

type FocusVariant = "inside" | "border" | "ring";

const focusOffsetMap: Record<FocusVariant, number> = {
  border: 0,
  inside: -2,
  ring: 2,
};

export const focusStyles = (variant: FocusVariant) => css`
  outline: 2px solid ${(p) => p.theme.colors.neutrals.borderFocus};
  outline-offset: ${focusOffsetMap[variant]}px;
`;
