import { useButton } from "@react-aria/button";
import { useMenuTrigger } from "@react-aria/menu";
import { Item } from "@react-stately/collections";
import { useMenuTriggerState } from "@react-stately/menu";
import type { Placement } from "@react-types/overlays";
import type { SelectionMode } from "@react-types/shared";
import React, { useRef, type Key } from "react";
import styled from "styled-components";
import Popover from "../Popover/Popover";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";
import Menu from "./Menu";

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

const StyledLabel = styled.div`
  margin: auto 0;
`;

interface OverflowMenuItem {
  icon?: ReactSVGComponent;
  key: string;
  label: string;
  selected?: boolean;
}

type RenderTriggerProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  "data-testid"?: string;
  ref: React.RefObject<HTMLButtonElement>;
};

export interface OverflowMenuProps {
  "data-testid"?: string;
  disabled?: boolean;
  disabledKeys?: string[];
  items: OverflowMenuItem[];
  onAction?: (key: Key) => void;
  placement?: Placement;
  renderTrigger: (props: RenderTriggerProps) => React.ReactNode;
  selectedKeys?: string[];
  selectionMode?: SelectionMode;
}

const OverflowMenu = ({
  "data-testid": dataTestId,
  disabled = false,
  disabledKeys = [],
  items,
  onAction,
  placement = "bottom right",
  renderTrigger,
  selectedKeys,
  selectionMode,
}: OverflowMenuProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);

  const state = useMenuTriggerState({});

  const { menuTriggerProps, menuProps } = useMenuTrigger<NonNullable<unknown>>(
    { isDisabled: disabled },
    state,
    triggerRef,
  );

  const { buttonProps } = useButton(menuTriggerProps, triggerRef);

  return (
    <>
      {renderTrigger({
        ...buttonProps,
        ...(dataTestId && { "data-testid": `${dataTestId}-button` }),
        ref: triggerRef,
      })}
      <Popover
        data-testid={dataTestId && `${dataTestId}-popover`}
        offset={4}
        placement={placement}
        state={state}
        triggerRef={triggerRef}
        width="fit-content"
      >
        <Menu
          {...menuProps}
          disabledKeys={disabledKeys}
          onAction={onAction}
          selectedKeys={selectedKeys}
          selectionMode={selectionMode}
          data-testid={dataTestId}
        >
          {items.map(({ key, label, icon }) => {
            return (
              <Item key={key} textValue={label}>
                <StyledItem>
                  {icon && (
                    <StaticIconWrapper
                      data-testid={dataTestId && `${dataTestId}-icon-wrapper`}
                      size="sm"
                      icon={icon}
                    />
                  )}
                  <StyledLabel>{label}</StyledLabel>
                </StyledItem>
              </Item>
            );
          })}
        </Menu>
      </Popover>
    </>
  );
};

export default OverflowMenu;
