import { mergeRefs } from "@kablamo/kerosene-ui";
import { OverlayContainer, useOverlayPosition } from "@react-aria/overlays";
import { useCombobox, type UseComboboxProps } from "downshift";
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type ForwardRefRenderFunction,
} from "react";
import useDimensions from "react-cool-dimensions";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { useTheme } from "../../../theme";
import type { FormControlOption } from "../../../types/design-system";
import Field from "../../Field/Field";
import type { ValidationStatus } from "../../InputContainer/InputContainer";
import TextInput from "../../TextInput/TextInput";
import {
  DropdownOptionItem,
  defaultRenderOption,
  type RenderOptionProps,
} from "../DropdownOptionItem";
import { StyledDropdown, StyledMenu } from "../styled";

export type ComboboxOptionFilter = (
  options: FormControlOption[],
  inputValue: string,
) => FormControlOption[];

export interface ComboboxProps {
  autoFocus?: boolean;
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  error?: HookFormFieldError | string;
  filterOptions?: ComboboxOptionFilter;
  icon?: ReactSVGComponent;
  id?: string;
  isLoading?: boolean;
  label: React.ReactNode;
  options: FormControlOption[];
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange?: (value: FormControlOption | null) => void;
  onChangeInput?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  renderOption?: (props: RenderOptionProps) => React.ReactNode;
  validationStatus?: ValidationStatus;
  value?: FormControlOption | null;
}

const defaultFilterOptions = (
  options: FormControlOption[],
  inputValue: string,
) =>
  options.filter((option) => option.label.toLowerCase().includes(inputValue));

const Combobox: ForwardRefRenderFunction<HTMLInputElement, ComboboxProps> = (
  {
    autoFocus,
    constrain,
    "data-testid": dataTestId,
    disabled,
    error,
    icon,
    id,
    isLoading,
    filterOptions = defaultFilterOptions,
    label,
    onBlur,
    onChange,
    onChangeInput,
    options,
    placeholder,
    renderOption = defaultRenderOption,
    validationStatus,
    value,
  }: ComboboxProps,
  _ref,
) => {
  const theme = useTheme();

  const targetRef = useRef<HTMLInputElement>(null);
  const ref = mergeRefs(_ref, targetRef);

  const overlayRef = useRef<HTMLUListElement>(null);

  const [items, setItems] = useState(options);

  const itemToString = (item: FormControlOption | null) =>
    item ? item.label : "";

  const onSelectedItemChange: UseComboboxProps<FormControlOption>["onSelectedItemChange"] =
    (event) => {
      onChange?.(event.selectedItem ?? null);
    };

  const selectedIndex = value
    ? items.findIndex((option) => option.value === value.value)
    : 0;

  const {
    closeMenu,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    inputValue,
    isOpen,
  } = useCombobox({
    defaultHighlightedIndex: selectedIndex !== -1 ? selectedIndex : 0,
    id,
    isItemDisabled: (item) => !!item.disabled,
    items,
    itemToString,
    onSelectedItemChange,
    selectedItem: value ?? null,
  });

  const { overlayProps: positionProps } = useOverlayPosition({
    containerPadding: 24,
    isOpen,
    onClose: closeMenu,
    offset: 8,
    overlayRef,
    placement: "bottom left",
    targetRef,
    shouldFlip: false,
  });

  useEffect(() => {
    const filteredOptions = filterOptions(
      options,
      inputValue?.toLowerCase() ?? "",
    );
    setItems(filteredOptions);
  }, [filterOptions, options, inputValue]);

  let renderedItems: React.ReactNode;
  if (isLoading) {
    renderedItems = (
      <DropdownOptionItem
        disabled
        focused={false}
        hasSelection={false}
        selected={false}
      >
        Loading options...
      </DropdownOptionItem>
    );
  } else if (items.length) {
    renderedItems = items.map((option, index) => (
      <React.Fragment key={`${option.value}`}>
        {renderOption({
          ...(dataTestId && {
            "data-testid": `${dataTestId}-${option.value}`,
          }),
          disabled: !!option.disabled,
          focused: highlightedIndex === index,
          hasSelection: !!value,
          itemProps: getItemProps({
            item: option,
            index,
          }),
          option,
          selected: option.value === value?.value,
        })}
      </React.Fragment>
    ));
  } else {
    renderedItems = (
      <DropdownOptionItem
        disabled
        focused={false}
        hasSelection={false}
        selected={false}
      >
        No options
      </DropdownOptionItem>
    );
  }

  const { observe: dropdownRef, width } = useDimensions();

  return (
    <Field
      {...getLabelProps()}
      constrain={constrain}
      error={error}
      label={label}
    >
      <StyledDropdown
        isOpen={isOpen}
        data-testid={dataTestId}
        ref={dropdownRef}
      >
        <TextInput
          autoFocus={autoFocus}
          iconStart={icon}
          placeholder={placeholder}
          validationStatus={validationStatus}
          {...getInputProps({
            disabled,
            onBlur,
            ref,
            onChange: onChangeInput,
          })}
          data-testid={dataTestId && `${dataTestId}-text-input`}
        />
        <OverlayContainer>
          <CSSTransition
            in={isOpen}
            nodeRef={overlayRef}
            timeout={theme.anim.duration.sm}
          >
            <StyledMenu
              {...positionProps}
              {...getMenuProps({ ref: overlayRef })}
              aria-hidden={!isOpen}
              data-testid={dataTestId && `${dataTestId}-menu`}
              isOpen={isOpen}
              width={width}
            >
              {renderedItems}
            </StyledMenu>
          </CSSTransition>
        </OverlayContainer>
      </StyledDropdown>
    </Field>
  );
};

export default forwardRef(Combobox);
