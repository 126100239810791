import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { focusStyles, weakStyles } from "../Button/states";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

interface StyledSidebarAnchorProps {
  isActive?: boolean;
}

const StyledSidebarAnchor = styled.a<StyledSidebarAnchorProps>`
  display: grid;
  padding: calc(0.75rem - 1px) 2rem calc(0.75rem - 1px) 1rem;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.buttonLg}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  border-radius: ${(p) => p.theme.borderRadiuses.md}px;
  border: 1px solid transparent;
  cursor: pointer;

  ${(p) =>
    p.isActive &&
    css`
      color: ${p.theme.colors.neutrals.text};
      background-color: ${p.theme.colors.neutrals.background};
      border: 1px solid ${p.theme.colors.neutrals.surfaceBorder};
    `}

  &:hover {
    ${(p) =>
      !p.isActive &&
      css`
        color: ${p.theme.colors.neutrals.text};
        ${weakStyles.background.hover}
      `}
  }

  &:active {
    color: ${(p) => p.theme.colors.neutrals.text};
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

interface SidebarAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  icon: ReactSVGComponent;
  isActive?: boolean;
}

const SidebarAnchor: ForwardRefRenderFunction<
  HTMLAnchorElement,
  SidebarAnchorProps
> = ({ children, icon, isActive, ...props }: SidebarAnchorProps, ref) => {
  return (
    <StyledSidebarAnchor isActive={isActive} ref={ref} {...props}>
      <TypographyIconWrapper icon={icon} size="bodyLgStrong" />
      {children}
    </StyledSidebarAnchor>
  );
};

export default forwardRef(SidebarAnchor);
