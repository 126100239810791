import { mergeRefs } from "@kablamo/kerosene-ui";
import { useDialog } from "@react-aria/dialog";
import React, {
  forwardRef,
  type ForwardRefRenderFunction,
  useRef,
} from "react";
import { CSSTransition } from "react-transition-group";
import { useTheme } from "styled-components";
import { Close } from "../../icons";
import IconButton, { type IconButtonSize } from "../IconButton/IconButton";
import Text from "../Text/Text";
import {
  type ModalSize,
  StyledActions,
  StyledMain,
  StyledDialog,
  StyledFooter,
  StyledHeader,
  StyledBody,
} from "./styled";

const closeButtonSizeMap: Record<ModalSize, IconButtonSize> = {
  xs: "sm",
  sm: "sm",
  md: "md",
  lg: "md",
};

export interface DialogProps {
  actions?: React.ReactNode;
  children: React.ReactNode;
  closeOnEsc?: boolean;
  closeOnClickOutside?: boolean;
  "data-testid"?: string;
  error?: React.ReactNode;
  footer?: React.ReactNode;
  hasHighlight?: boolean;
  /**
   * isOpen must be controlled from outside the modal, and ensure it's
   * set to false when onClose is called to remove the modal.
   */
  isOpen: boolean;
  onClose: () => void;
  size: ModalSize;
  title: React.ReactNode;
}

const Dialog: ForwardRefRenderFunction<HTMLDivElement, DialogProps> = (
  {
    actions,
    children,
    "data-testid": dataTestId,
    error,
    footer,
    hasHighlight,
    isOpen,
    onClose,
    size,
    title,
  }: DialogProps,
  ref,
) => {
  const theme = useTheme();

  const modalRef = useRef<HTMLDivElement>(null);

  const { dialogProps, titleProps } = useDialog({}, modalRef);

  const setRef = mergeRefs(modalRef, ref);

  return (
    <CSSTransition
      appear
      in={isOpen}
      nodeRef={modalRef}
      timeout={theme.anim.duration.sm}
    >
      <StyledDialog
        {...dialogProps}
        data-testid={dataTestId && `${dataTestId}`}
        ref={setRef}
      >
        <StyledMain hasHighlight={hasHighlight}>
          <StyledHeader {...titleProps}>
            <Text
              as="h5"
              size="h3"
              data-testid={dataTestId && `${dataTestId}-title`}
            >
              {title}
            </Text>
            <StyledActions>
              {actions}
              <IconButton
                label="Close"
                icon={Close}
                onClick={onClose}
                size={closeButtonSizeMap[size]}
                variant="ghost"
              />
            </StyledActions>
          </StyledHeader>
          <StyledBody data-testid={dataTestId && `${dataTestId}-content`}>
            {children}
          </StyledBody>
        </StyledMain>
        {error}
        <StyledFooter data-testid={dataTestId && `${dataTestId}-footer`}>
          {footer}
        </StyledFooter>
      </StyledDialog>
    </CSSTransition>
  );
};

export default forwardRef(Dialog);
