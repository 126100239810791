import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";
import { chipStyles, StyledDone } from "./styled";

export const StyledInputChip = styled.label`
  display: inline-flex;
`;

export const StyledChip = styled.div`
  ${chipStyles.base}
  cursor: pointer;

  &:hover {
    ${chipStyles.hover}
  }

  ${StyledHiddenInput}:not(:disabled) ~ &:active {
    ${chipStyles.active}
  }

  ${StyledHiddenInput}:focus-visible ~ & {
    ${chipStyles.focus}
  }

  ${StyledHiddenInput}:checked ~ & {
    ${chipStyles.checked}
  }

  ${StyledHiddenInput}:checked ~ &:hover {
    ${chipStyles.checkedHover}
  }

  ${StyledHiddenInput}:checked ~ &:active {
    ${chipStyles.checkedActive}
  }

  ${StyledHiddenInput}:disabled ~ & {
    ${chipStyles.disabled}
    cursor: not-allowed;
  }
`;

type InputChipProps = {
  checked?: boolean;
  children: React.ReactNode;
  icon?: ReactSVGComponent;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputChip: ForwardRefRenderFunction<HTMLLabelElement, InputChipProps> = (
  {
    checked,
    children,
    icon: Icon,
    type = "checkbox",
    ...props
  }: InputChipProps,
  ref,
) => {
  return (
    <StyledInputChip ref={ref}>
      <StyledHiddenInput checked={checked} type={type} {...props} />
      <StyledChip>
        <StyledDone />
        {children}
        {Icon && <Icon />}
      </StyledChip>
    </StyledInputChip>
  );
};

export default forwardRef(InputChip);
