import styled from "styled-components";

const StyledSidebarMenu = styled.div`
  display: grid;
  padding: 2rem 1rem 2rem;
  gap: 8px;
`;

interface SidebarMenuProps {
  children?: React.ReactNode;
}

const SidebarMenu = ({ children }: SidebarMenuProps) => {
  return <StyledSidebarMenu>{children}</StyledSidebarMenu>;
};

export default SidebarMenu;
