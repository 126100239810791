import { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import type { TypographySize } from "../../theme";
import { focusStyles } from "../Button/states";
import Text from "../Text/Text";

const StyledTextButton = styled.button`
  ${buttonReset}
  display: inline-block;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  text-decoration: underline;
  text-underline-offset: 3px;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  &:hover {
    color: ${(p) => p.theme.colors.neutrals.text};
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }

  &:disabled {
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  }
`;

export interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  "data-testid"?: string;
  size?: TypographySize;
}

const TextButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  TextButtonProps
> = (
  {
    children,
    "data-testid": dataTestId,
    size = "bodyDefault",
    type = "button",
    ...props
  }: TextButtonProps,
  ref,
) => {
  return (
    <StyledTextButton data-testid={dataTestId} ref={ref} type={type} {...props}>
      <Text size={size}>{children}</Text>
    </StyledTextButton>
  );
};

export default forwardRef(TextButton);
