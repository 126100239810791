import type { MergedUnion } from "@kablamo/kerosene";
import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import type { Placement } from "tippy.js";
import { Close, Info } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import type { BadgeStyle } from "../../theme";
import { focusStyles } from "../Button/states";
import DivButton from "../DivButton/DivButton";
import { Tooltip } from "../Tooltip/Tooltip";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";
import {
  classicBadgeStyles,
  modernBadgeStyles,
  type BadgeTheme,
  type BadgeVariant,
} from "./styled";

const badgeThemeStyles: Record<BadgeStyle, BadgeTheme> = {
  classic: classicBadgeStyles,
  modern: modernBadgeStyles,
} as const;

export const BADGE_VARIANTS = Object.keys(
  classicBadgeStyles,
) as ReadonlyArray<BadgeVariant>;

const StyledRemoveButton = styled(DivButton)`
  ${buttonReset}
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
  margin-right: -0.25rem;

  &:focus-visible {
    ${focusStyles("border")}
  }
`;

interface StyledBadgeProps {
  variant: BadgeVariant;
}

const StyledBadge = styled.div<StyledBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: fit-content;
  max-width: 100%;
  flex-shrink: 0;
  ${(p) => p.theme.typography.variants.footnoteStrong}
  border: 1px solid transparent;
  white-space: nowrap;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &[data-has-tooltip="true"] {
    cursor: default;
  }

  ${(p) =>
    p.theme.badges.badgeStyle === "classic"
      ? css`
          padding: 0.125rem 0.5rem;
          border-radius: ${p.theme.borderRadiuses.base}px;
        `
      : css`
          padding: calc(0.25rem - 1px) calc(0.5rem - 1px);
          border-radius: ${p.theme.borderRadiuses.full}px;
          font-weight: ${p.theme.fontWeights.semibold};
        `}

  ${(p) => badgeThemeStyles[p.theme.badges.badgeStyle][p.variant]}
`;

const StyledLabel = styled.div`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type RemovableBadgeProps = {
  isRemovable: true;
  onRemove: () => void;
  removeDisabled?: boolean;
  removeLabel: string;
};

type NonRemovableBadgeProps = {
  isRemovable?: false;
};

type BadgeProps = {
  children: React.ReactNode;
  "data-testid"?: string;
  icon?: ReactSVGComponent;
  showTooltipIcon?: boolean;
  tooltip?: React.ReactNode;
  tooltipPlacement?: Placement;
  variant?: BadgeVariant;
} & MergedUnion<RemovableBadgeProps | NonRemovableBadgeProps>;

const Badge: ForwardRefRenderFunction<HTMLDivElement, BadgeProps> = (
  {
    children,
    "data-testid": dataTestId,
    icon,
    isRemovable,
    onRemove,
    removeDisabled,
    removeLabel,
    showTooltipIcon,
    tooltip,
    tooltipPlacement,
    variant = "neutral",
    ...props
  }: BadgeProps,
  ref,
) => {
  const badge = (
    <StyledBadge
      ref={ref}
      variant={variant}
      data-testid={dataTestId && `${dataTestId}-chip`}
      {...props}
    >
      {icon && (
        <TypographyIconWrapper
          data-testid={dataTestId && `${dataTestId}-icon`}
          icon={icon}
          size="footnoteStrong"
        />
      )}
      <StyledLabel>{children}</StyledLabel>
      {tooltip && showTooltipIcon && (
        <TypographyIconWrapper
          data-testid={dataTestId && `${dataTestId}-info-icon`}
          icon={Info}
          size="footnoteStrong"
        />
      )}
      {isRemovable && (
        <StyledRemoveButton
          aria-label={removeLabel}
          data-testid={dataTestId && `${dataTestId}-remove-button`}
          disabled={removeDisabled}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            onRemove();
          }}
        >
          <TypographyIconWrapper icon={Close} size="footnoteStrong" />
        </StyledRemoveButton>
      )}
    </StyledBadge>
  );

  if (tooltip) {
    return (
      <Tooltip delay message={tooltip} placement={tooltipPlacement}>
        {badge}
      </Tooltip>
    );
  }

  return <>{badge}</>;
};

export default forwardRef(Badge);
