import { useDialog } from "@react-aria/dialog";
import { useRef, useState } from "react";
import type { FormControlOption } from "../../types/design-system";
import Divider from "../Divider/Divider";
import RadioButton from "../RadioButton/RadioButton";
import DropdownChipDialogFooter from "./DropdownChipDialogFooter";
import RadioButtonGroupDialogList from "./RadioButtonGroupDialogList";
import { StyledDialog, StyledDialogBody } from "./styled";

interface RadioButtonGroupDialogProps<T extends string> {
  hasSelectAll?: boolean;
  items: FormControlOption<T>[];
  onChange: (value: T | null) => void;
  onClose: () => void;
  value: T | null;
}

const RadioButtonGroupDialog = <T extends string>({
  hasSelectAll,
  items,
  onChange: _onChange,
  onClose,
  value: _value,
}: RadioButtonGroupDialogProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps } = useDialog({}, ref);

  const [value, setValue] = useState<T | null>(_value);

  const onSelectAll = () => {
    setValue(null);
  };

  const onChange = (newValue: T) => {
    setValue(newValue);
  };

  const onCancel = () => {
    onClose();
  };

  const onApply = () => {
    onClose();
    _onChange(value);
  };

  return (
    <StyledDialog {...dialogProps} ref={ref}>
      <StyledDialogBody>
        {hasSelectAll && (
          <>
            <RadioButton checked={!value} label="All" onChange={onSelectAll} />
            <Divider />
          </>
        )}
        <RadioButtonGroupDialogList
          items={items}
          onChange={onChange}
          value={value}
        />
      </StyledDialogBody>
      <DropdownChipDialogFooter onApply={onApply} onCancel={onCancel} />
    </StyledDialog>
  );
};

export default RadioButtonGroupDialog;
