import styled, { css } from "styled-components";
import { ArrowOutward } from "../../icons";
import { Tooltip } from "../Tooltip/Tooltip";

interface StyledFieldGridLabelProps {
  align?: React.CSSProperties["alignSelf"];
  fullWidth?: boolean;
  justify?: React.CSSProperties["justifySelf"];
}

const StyledLabel = styled.dt<StyledFieldGridLabelProps>`
  ${(p) => p.theme.typography.variants.bodyDefaultStrong}

  ${(p) =>
    p.fullWidth &&
    css`
      grid-column: 1 / -1;
      padding-bottom: 0.25rem;
    `}

  ${(p) =>
    !!p.align &&
    css`
      align-self: ${p.align};
    `}
    ${(p) =>
    !!p.justify &&
    css`
      justify-self: ${p.justify};
    `}
`;

interface StyledFieldGridValueProps {
  fullWidth?: boolean;
}

const StyledValue = styled.dd<StyledFieldGridValueProps>`
  display: grid;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};

  ${(p) =>
    p.fullWidth &&
    css`
      grid-column: 1 / -1;
    `}
`;

type FieldGridItemProps = {
  children?: React.ReactNode;
  "data-testid"?: string;
  fullWidth?: boolean;
  label: React.ReactNode;
  labelAlign?: React.CSSProperties["alignSelf"];
  labelJustify?: React.CSSProperties["justifySelf"];
  tooltip?: React.ReactNode;
};

const FieldGridItem = ({
  children,
  "data-testid": dataTestId,
  fullWidth = false,
  label,
  labelAlign,
  labelJustify,
  tooltip,
}: FieldGridItemProps) => {
  let labelContent = (
    <StyledLabel
      align={labelAlign}
      fullWidth={fullWidth}
      justify={labelJustify}
      data-testid={dataTestId && `${dataTestId}-label`}
    >
      {label}
    </StyledLabel>
  );

  if (tooltip) {
    labelContent = <Tooltip message={tooltip}>{labelContent}</Tooltip>;
  }

  return (
    <>
      {labelContent}
      <StyledValue
        data-testid={dataTestId && `${dataTestId}-value`}
        fullWidth={fullWidth}
      >
        {children}
      </StyledValue>
    </>
  );
};

const StyledFieldGridAnchor = styled.a`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: inherit;

  &:hover {
    color: ${(p) => p.theme.colors.neutrals.text};
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

interface FieldGridAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  "data-testid"?: string;
  external?: boolean;
}

const FieldGridAnchor = ({
  children,
  "data-testid": dataTestId,
  external,
  ...props
}: FieldGridAnchorProps) => {
  if (external) {
    return (
      <StyledFieldGridAnchor
        {...props}
        data-testid={dataTestId && `${dataTestId}-external-anchor`}
      >
        {children}
        <ArrowOutward />
      </StyledFieldGridAnchor>
    );
  }
  return (
    <StyledFieldGridAnchor
      data-testid={dataTestId && `${dataTestId}-anchor`}
      {...props}
    >
      {children}
    </StyledFieldGridAnchor>
  );
};

const disabledStyles = css`
  ${StyledLabel}, ${StyledValue} {
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  }
`;

export const fieldGridLayouts = ["default", "compact"] as const;

export type FieldGridLayout = (typeof fieldGridLayouts)[number];

interface StyledFieldGridProps {
  $disabled?: boolean;
  layout: FieldGridLayout;
}

const StyledFieldGrid = styled.dl<StyledFieldGridProps>`
  display: grid;
  gap: 0.25rem 0.5rem;

  grid-template-columns: ${(p) =>
    p.layout === "compact"
      ? css`minmax(0, 1fr) minmax(0, 3fr)`
      : css`minmax(0, 2fr) minmax(0, 3fr)`};

  ${(p) => p.$disabled && disabledStyles};
`;

interface FieldGridProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  disabled?: boolean;
  layout?: FieldGridLayout;
}

const FieldGrid = ({
  children,
  "data-testid": dataTestId,
  disabled,
  layout = "default",
}: FieldGridProps) => {
  return (
    <StyledFieldGrid
      data-testid={dataTestId}
      $disabled={disabled}
      layout={layout}
    >
      {children}
    </StyledFieldGrid>
  );
};

export default Object.assign(FieldGrid, {
  Anchor: FieldGridAnchor,
  Item: FieldGridItem,
});
