import {
  Equal,
  KeyboardArrowDoubleDown,
  KeyboardArrowDoubleUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "../../icons";
import type { Severity } from "../../types/design-system";
import StaticIconWrapper, {
  type IconVariant,
  type StaticIconWrapperSize,
} from "../StaticIconWrapper/StaticIconWrapper";
import { Tooltip } from "../Tooltip/Tooltip";

const severityLabels: Record<Severity, string> = {
  "very-low": "Very low",
  high: "High",
  low: "Low",
  moderate: "Moderate",
  severe: "Severe",
};

export const severityIcons = {
  high: KeyboardArrowUp,
  low: KeyboardArrowDown,
  moderate: Equal,
  severe: KeyboardArrowDoubleUp,
  "very-low": KeyboardArrowDoubleDown,
} satisfies Record<Severity, ReactSVGComponent>;

const severityVariants: Record<Severity, IconVariant> = {
  high: "error",
  low: "info",
  moderate: "warning",
  severe: "error",
  "very-low": "info",
};

interface SeverityIconProps {
  "data-testid"?: string;
  disabled?: boolean;
  severity?: Severity;
  size?: StaticIconWrapperSize;
}

const SeverityIcon = ({
  "data-testid": dataTestId,
  disabled,
  severity,
  size,
}: SeverityIconProps) => {
  if (!severity) return null;

  const icon = severityIcons[severity];

  const variant = disabled ? "disabled" : severityVariants[severity];

  return (
    <Tooltip message={severityLabels[severity]}>
      <StaticIconWrapper
        data-testid={dataTestId}
        icon={icon}
        size={size}
        variant={variant}
      />
    </Tooltip>
  );
};

export default SeverityIcon;
