import Button from "../Button/Button";
import ModalDialog, { type ModalDialogProps } from "./ModalDialog";

export interface AlertModalProps
  extends Omit<ModalDialogProps, "onClose" | "footer"> {
  "data-testid"?: string;
  dismissLabel?: string;
  onDismiss: () => void;
}

const AlertModal = ({
  children,
  "data-testid": dataTestId,
  dismissLabel = "OK",
  onDismiss,
  ...props
}: AlertModalProps) => {
  return (
    <ModalDialog
      footer={
        <Button
          onClick={onDismiss}
          variant="ghost"
          data-testid={dataTestId && `${dataTestId}-dismiss-button`}
        >
          {dismissLabel}
        </Button>
      }
      onClose={onDismiss}
      {...props}
    >
      {children}
    </ModalDialog>
  );
};

export default AlertModal;
