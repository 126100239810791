import type { FormControlOption } from "../../types/design-system";
import RadioButton from "../RadioButton/RadioButton";
import { StyledFormControlList } from "./styled";

interface RadioButtonGroupDialogListProps<T extends string> {
  columns?: number;
  items: FormControlOption<T>[];
  onChange: (value: T) => void;
  value: T | null;
}

const RadioButtonGroupDialogList = <T extends string>({
  columns = 1,
  items,
  onChange: _onChange,
  value,
}: RadioButtonGroupDialogListProps<T>) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _onChange(event.currentTarget.value as T);
  };

  return (
    <StyledFormControlList columns={columns}>
      {items.map((item) => (
        <RadioButton
          checked={value === item.value}
          key={item.value}
          onChange={onChange}
          label={item.label}
          value={item.value}
        />
      ))}
    </StyledFormControlList>
  );
};

export default RadioButtonGroupDialogList;
