import React, { createContext, useContext, useMemo } from "react";

interface CheckboxGroupContextValue {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checkedItems: string[];
}

const CheckboxGroupContext = createContext<
  CheckboxGroupContextValue | undefined
>(undefined);

export interface CheckboxGroupProviderProps {
  checkedItems: string[];
  children?: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const CheckboxGroupProvider = ({
  checkedItems,
  children,
  onChange,
}: CheckboxGroupProviderProps) => {
  const checkboxGroupProviderValue = useMemo(
    () => ({ onChange, checkedItems }),
    [onChange, checkedItems],
  );

  return (
    <CheckboxGroupContext.Provider value={checkboxGroupProviderValue}>
      {children}
    </CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroupProvider;

export const useCheckboxGroup = () => {
  const context = useContext(CheckboxGroupContext);

  if (!context) {
    throw new Error(
      "useCheckboxGroup must be used inside a CheckboxGroupProvider",
    );
  }

  return context;
};
