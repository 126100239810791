import { useFocusRing } from "@react-aria/focus";
import { useSliderThumb } from "@react-aria/slider";
import { mergeProps } from "@react-aria/utils";
import type { SliderState } from "@react-stately/slider";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { focusStyles, mediumStyles, weakStyles } from "../Button/states";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";
import { Tooltip } from "../Tooltip/Tooltip";

export type SliderVariant = "default" | "strong";

interface StyledSliderThumbProps {
  isFocusVisible: boolean;
  variant: SliderVariant;
}

const variantStyles: Record<SliderVariant, ReturnType<typeof css>> = {
  default: css`
    width: 1rem;
    height: 1rem;
    background-color: ${(p) => p.theme.colors.neutrals.background};
    ${mediumStyles.border.base}

    &:hover {
      ${weakStyles.background.hover}
      ${mediumStyles.border.hover}
    }

    &:active {
      ${weakStyles.background.active}
      ${mediumStyles.border.active}
    }

    &:is([data-dragging="true"]) {
      ${weakStyles.background.active}
      ${mediumStyles.border.active}
    }
  `,
  strong: css`
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border: 4px solid ${(p) => p.theme.colors.neutrals.borderSelected};

    &:hover {
      ${weakStyles.background.hover}
    }

    &:active {
      ${weakStyles.background.active}
    }

    &:is([data-dragging="true"]) {
      ${weakStyles.background.active}
    }
  `,
};

export const StyledSliderThumb = styled.div<StyledSliderThumbProps>`
  outline: 0;
  box-shadow: ${(p) => p.theme.boxShadows.sm};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  cursor: pointer;
  top: 50%;
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    border-color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  ${(p) =>
    p.isFocusVisible &&
    css`
      ${focusStyles("ring")}
    `}

  ${(p) => variantStyles[p.variant]}
`;

interface SliderThumbProps {
  "aria-label"?: string;
  "data-testid"?: string;
  formatTooltip?: (value: number) => React.ReactNode;
  index: number;
  list?: string;
  state: SliderState;
  tooltip?: boolean;
  trackRef: React.RefObject<Element>;
  variant?: SliderVariant;
}

const SliderThumb = ({
  "aria-label": ariaLabel,
  "data-testid": dataTestId,
  formatTooltip,
  index,
  list,
  state,
  tooltip,
  trackRef,
  variant = "default",
}: SliderThumbProps) => {
  const inputRef = React.useRef(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { thumbProps, inputProps, isDragging } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state,
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const isTooltipVisible = isDragging || isFocusVisible || isHovered;

  const thumb = (
    <StyledSliderThumb
      data-testid={dataTestId}
      data-dragging={isDragging}
      isFocusVisible={isFocusVisible}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant={variant}
      {...thumbProps}
    >
      <StyledHiddenInput
        data-testid={dataTestId && `${dataTestId}-input`}
        ref={inputRef}
        list={list}
        aria-label={ariaLabel}
        {...mergeProps(inputProps, focusProps)}
      />
    </StyledSliderThumb>
  );

  if (!tooltip) return <>{thumb}</>;

  const value = state.getThumbValue(index);

  const message = formatTooltip ? formatTooltip(value) : value;

  return (
    <Tooltip
      visible={isTooltipVisible}
      message={<strong>{message}</strong>}
      offset={[0, 12]}
    >
      {thumb}
    </Tooltip>
  );
};

export default SliderThumb;
