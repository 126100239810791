import styled, { useTheme } from "styled-components";
import IconButton, {
  type IconButtonSize,
  type IconButtonVariant,
} from "../IconButton/IconButton";

interface StyledToggleIconButtonProps {
  iconOnFill: string;
}

const StyledToggleIconButton = styled(IconButton)<StyledToggleIconButtonProps>`
  &[data-selected="true"],
  &[data-selected="true"]:hover {
    color: ${(p) => p.iconOnFill};
  }
`;

export interface ToggleIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  "data-testid"?: string;
  iconOff: ReactSVGComponent;
  iconOn: ReactSVGComponent;
  iconOnFill?: string;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

const ToggleIconButton = ({
  "data-testid": dataTestId,
  iconOff,
  iconOn,
  label,
  onClick,
  selected,
  ...props
}: ToggleIconButtonProps) => {
  const theme = useTheme();
  const {
    iconOnFill = theme.colors.primary.icon,
    variant = "ghost",
    size = "sm",
  } = props;

  return (
    <StyledToggleIconButton
      {...props}
      label={label}
      icon={selected ? iconOn : iconOff}
      onClick={onClick}
      size={size}
      type="button"
      variant={variant}
      iconOnFill={iconOnFill}
      data-selected={selected}
      data-testid={dataTestId}
    />
  );
};

export default ToggleIconButton;
