import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import linkReset from "../../lib/styled/linkReset";
import media from "../../theme/media";
import { mediumStyles } from "../Button/states";
import { buttonSizeStyles, commonButtonStyles } from "../Button/styled";

export const StyledHeaderLinkTab = styled.a`
  ${linkReset}
  ${commonButtonStyles}
  padding: 0.5rem 0.75rem;
  ${(p) => p.theme.typography.variants.buttonDefault}

  justify-content: start;

  &[data-active="true"] {
    color: ${(p) => p.theme.colors.neutrals.text};
    ${mediumStyles.background.hover}
  }

  &:active {
    ${mediumStyles.background.active}
  }

  @media ${media.lg} {
    ${buttonSizeStyles.md}
    justify-content: center;
    background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    color: ${(p) => p.theme.colors.neutrals.textInverse};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.fireStatus.buttonBackgroundHover};
    }

    &[data-active="true"] {
      color: ${(p) => p.theme.colors.neutrals.textInverse};
      background-color: ${(p) => p.theme.colors.neutrals.textInverseDisabled};
    }

    &:active {
      background-color: ${(p) =>
        p.theme.colors.fireStatus.iconDisabledOnBackground};
    }
  }
`;

interface HeaderLinkTabProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
  href?: string;
  isActive?: boolean;
}

const HeaderLinkTab: ForwardRefRenderFunction<
  HTMLAnchorElement,
  HeaderLinkTabProps
> = (
  { children, href, isActive = false, ...props }: HeaderLinkTabProps,
  ref,
) => {
  return (
    <StyledHeaderLinkTab
      {...props}
      data-active={isActive}
      href={href}
      ref={ref}
      data-tab
    >
      {children}
    </StyledHeaderLinkTab>
  );
};

export default forwardRef(HeaderLinkTab);
