import styled from "styled-components";

const AdjustedIndicator = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  background-color: ${(p) => p.theme.colors.informative.icon};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
`;

export default AdjustedIndicator;
