import { forwardRef, type ForwardRefRenderFunction } from "react";
import type { FormControlOption } from "../../types/design-system";
import { StyledCheck, StyledDropdownOption } from "./styled";

interface DropdownOptionItemProps
  extends React.LiHTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
  disabled: boolean;
  /**
   * Used for removing the reserved space to align all option labels with the
   * check for a selected item when there is no checked item in the list at all
   */
  hasSelection: boolean;
  focused: boolean;
  selected: boolean;
  "data-testid"?: string;
}

const BaseDropdownOptionItem: ForwardRefRenderFunction<
  HTMLLIElement,
  DropdownOptionItemProps
> = (
  {
    children,
    disabled,
    focused,
    hasSelection,
    selected,
    ...props
  }: DropdownOptionItemProps,
  ref,
) => {
  return (
    <StyledDropdownOption
      data-testid={props["data-testid"]}
      data-disabled={disabled}
      data-focused={focused}
      data-selected={selected}
      ref={ref}
      {...props}
    >
      {hasSelection && <StyledCheck />}
      {children}
    </StyledDropdownOption>
  );
};

export const DropdownOptionItem = forwardRef(BaseDropdownOptionItem);

export interface RenderOptionProps {
  disabled: boolean;
  focused: boolean;
  hasSelection: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemProps: any;
  option: FormControlOption;
  selected: boolean;
}

export const defaultRenderOption = ({
  disabled,
  focused,
  hasSelection,
  itemProps,
  option,
  selected,
  ...props
}: RenderOptionProps) => {
  return (
    <DropdownOptionItem
      {...props}
      {...itemProps}
      disabled={disabled}
      focused={focused}
      hasSelection={hasSelection}
      selected={selected}
    >
      {option.label}
    </DropdownOptionItem>
  );
};
