import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import {
  OverlayContainer,
  useModal,
  useOverlay,
  usePreventScroll,
} from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { useTheme } from "styled-components";
import { Close } from "../../icons";
import underlayStyles from "../../lib/styled/underlayStyles";
import IconButton from "../IconButton/IconButton";
import { useNavDrawer } from "./NavDrawerProvider";

const StyledNavDrawer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1rem 1rem 1.5rem 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};

  &:focus-visible {
    outline: none;
  }
`;

const StyledUnderlay = styled.div`
  ${underlayStyles}
  z-index: ${(p) => p.theme.zIndexes.navDrawer};

  &.enter,
  &.appear {
    ${StyledNavDrawer} {
      transform: translateX(-100%);
    }
  }

  &.enter-active,
  &.appear-active {
    ${StyledNavDrawer} {
      transform: translateX(0);
      transition: transform
        ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
    }
  }

  &.exit {
    ${StyledNavDrawer} {
      transform: translateX(0);
    }
  }

  &.exit-active {
    ${StyledNavDrawer} {
      transform: translateX(-100%);
      transition: transform
        ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
    }
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;

  &:focus {
    outline: none;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  padding: 0 0 0 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.h3}
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

interface NavDrawerOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  "data-testid"?: string;
  overlayState: OverlayTriggerState;
  title: string;
  underlayRef?: React.Ref<HTMLDivElement>;
}

const NavDrawerOverlay = ({
  children,
  "data-testid": dataTestId,
  overlayState,
  title,
  underlayRef,
  ...props
}: NavDrawerOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: overlayState.isOpen,
      onClose: overlayState.close,
    },
    overlayRef,
  );

  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, overlayRef);
  usePreventScroll();

  return (
    <StyledUnderlay {...underlayProps} ref={underlayRef}>
      <FocusScope contain restoreFocus>
        <StyledNavDrawer
          {...props}
          {...modalProps}
          {...dialogProps}
          {...overlayProps}
          ref={overlayRef}
          data-testid={dataTestId}
        >
          <StyledContent>
            <StyledTitle>
              {title}
              <IconButton
                icon={Close}
                label="Close"
                onClick={overlayState.close}
                variant="inverse"
                data-testid={dataTestId && `${dataTestId}-close-button`}
              />
            </StyledTitle>
            {children}
          </StyledContent>
        </StyledNavDrawer>
      </FocusScope>
    </StyledUnderlay>
  );
};

export interface NavDrawerProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  title: string;
}

const NavDrawer = ({
  children,
  "data-testid": dataTestId,
  title,
}: NavDrawerProps) => {
  const { overlayProps, overlayTriggerState } = useNavDrawer();

  const theme = useTheme();
  const underlayRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={overlayTriggerState.isOpen}
      mountOnEnter
      nodeRef={underlayRef}
      timeout={{
        enter: theme.anim.duration.sm,
        exit: theme.anim.duration.sm,
      }}
      unmountOnExit
    >
      <OverlayContainer>
        <NavDrawerOverlay
          {...overlayProps}
          overlayState={overlayTriggerState}
          title={title}
          underlayRef={underlayRef}
          data-testid={dataTestId && `${dataTestId}-overlay`}
        >
          {children}
        </NavDrawerOverlay>
      </OverlayContainer>
    </CSSTransition>
  );
};

export default NavDrawer;
