import sumBy from "lodash/sumBy";
import pluralize from "pluralize";
import { useState } from "react";
import type { PopoverSize } from "../Popover/PopoverOverlay";
import DropdownChip from "./DropdownChip";
import TabbedCheckboxGroupDialog, {
  type CheckboxOptionSections,
  type ValuesFromSections,
} from "./TabbedCheckboxGroupDialog";

interface FormatLabelParams {
  itemType: string;
  label: string;
  selectedCount: number;
}

const formatLabel = ({ itemType, label, selectedCount }: FormatLabelParams) => {
  if (!selectedCount) {
    return label;
  }

  return `${selectedCount} ${pluralize(itemType, selectedCount)}`;
};

interface TabbedCheckboxGroupDropdownChipProps<
  S extends CheckboxOptionSections,
> {
  columns?: number;
  disabled?: boolean;
  itemType: string;
  label: string;
  onChange: (value: ValuesFromSections<S>) => void;
  sections: S;
  popoverSize?: PopoverSize;
  value: ValuesFromSections<S>;
  "data-testid"?: string;
}

const TabbedCheckboxGroupDropdownChip = <
  const S extends CheckboxOptionSections,
>({
  disabled,
  itemType,
  label,
  onChange,
  sections,
  popoverSize,
  value,
  "data-testid": dataTestId,
}: TabbedCheckboxGroupDropdownChipProps<S>) => {
  const checked = Object.values(value).some((v) => v.length);

  const formattedLabel = formatLabel({
    itemType,
    label,
    selectedCount: sumBy(Object.values(value), (v) => v.length),
  });

  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <DropdownChip
      checked={checked}
      disabled={disabled}
      label={formattedLabel}
      popoverSize={popoverSize}
      data-testid={dataTestId}
    >
      {(state) => (
        <TabbedCheckboxGroupDialog
          {...(popoverSize === "xl" && { columns: 2 })}
          defaultTabIndex={tabIndex}
          onChange={onChange}
          onClose={state.close}
          onTabSelect={(index) => setTabIndex(index)}
          sections={sections}
          value={value}
        />
      )}
    </DropdownChip>
  );
};

export default TabbedCheckboxGroupDropdownChip;
