import { css } from "styled-components";

const listStyles = css`
  ul {
    list-style: disc;
    padding-left: 1.125rem;
  }

  li {
    padding-left: 0.25rem;
  }
`;

export default listStyles;
