import { type Dispatch, type Reducer, useReducer } from "react";

export const BOTTOM_SHEET_POSITIONS = ["down", "docked", "up"] as const;
export type BottomSheetPosition = (typeof BOTTOM_SHEET_POSITIONS)[number];
export type BottomSheetMode = "default" | "dockable";

export interface BottomSheetState {
  mode: BottomSheetMode;
  position: BottomSheetPosition;
}

export const initialState: BottomSheetState = {
  mode: "default",
  position: "down",
};

export enum BottomSheetActionType {
  REVEAL = "REVEAL",
  SET_MODE = "SET_MODE",
  SET_POSITION = "SET_POSITION",
  SWIPE_UP = "SWIPE_UP",
  SWIPE_DOWN = "SWIPE_DOWN",
}

export type BottomSheetAction =
  | { mode?: BottomSheetMode; type: BottomSheetActionType.REVEAL }
  | {
      position: BottomSheetPosition;
      type: BottomSheetActionType.SET_POSITION;
    }
  | {
      type: BottomSheetActionType.SWIPE_DOWN;
    }
  | { type: BottomSheetActionType.SWIPE_UP }
  | { mode: BottomSheetMode; type: BottomSheetActionType.SET_MODE };

export type BottomSheetDispatch = Dispatch<BottomSheetAction>;

const sheetRevealedPositionsMap: Record<
  BottomSheetMode,
  readonly BottomSheetPosition[]
> = {
  default: ["up"],
  dockable: ["docked", "up"],
};

const sheetPositionOrdersMap: Record<
  BottomSheetMode,
  readonly BottomSheetPosition[]
> = {
  default: ["down", "up"],
  dockable: ["down", "docked", "up"],
};

export const reducer: Reducer<BottomSheetState, BottomSheetAction> = (
  state,
  action,
) => {
  const sheetPositionOrder = sheetPositionOrdersMap[state.mode];
  const currentPositionIndex = sheetPositionOrder.indexOf(state.position);

  switch (action.type) {
    case BottomSheetActionType.REVEAL: {
      const nextMode = action.mode ?? state.mode;
      const sheetRevealedPositions = sheetRevealedPositionsMap[nextMode];
      if (sheetRevealedPositions.includes(state.position))
        return {
          ...state,
          mode: nextMode,
        };
      return { ...state, mode: nextMode, position: sheetRevealedPositions[0] };
    }
    case BottomSheetActionType.SET_POSITION: {
      return { ...state, position: action.position };
    }
    case BottomSheetActionType.SWIPE_DOWN: {
      if (currentPositionIndex === 0) return state;

      const nextPosition = sheetPositionOrder[currentPositionIndex - 1];
      return { ...state, position: nextPosition };
    }
    case BottomSheetActionType.SWIPE_UP: {
      if (currentPositionIndex === sheetPositionOrder.length - 1) return state;

      const nextPosition = sheetPositionOrder[currentPositionIndex + 1];
      return { ...state, position: nextPosition };
    }
    case BottomSheetActionType.SET_MODE: {
      let nextPosition = state.position;
      if (action.mode !== "dockable" && nextPosition === "docked") {
        nextPosition = "up";
      }
      return { mode: action.mode, position: nextPosition };
    }
    default:
      return state;
  }
};

export interface UseBottomSheetResult {
  dispatch: BottomSheetDispatch;
  position: BottomSheetPosition;
}

const useBottomSheet = (): UseBottomSheetResult => {
  const [{ position }, dispatch] = useReducer<
    Reducer<BottomSheetState, BottomSheetAction>
  >(reducer, initialState);

  return { dispatch, position };
};

export default useBottomSheet;
