import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";

const StyledHeaderLinkTabs = styled.div`
  display: grid;
  gap: 0.125rem;
  padding: 0.25rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
`;

interface HeaderLinkTabPopoverProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  "data-testid"?: string;
}

const HeaderLinkTabPopover: ForwardRefRenderFunction<
  HTMLDivElement,
  HeaderLinkTabPopoverProps
> = (
  { children, "data-testid": dataTestId }: HeaderLinkTabPopoverProps,
  ref,
) => {
  return (
    <StyledHeaderLinkTabs data-testid={dataTestId} ref={ref}>
      {children}
    </StyledHeaderLinkTabs>
  );
};

export default forwardRef(HeaderLinkTabPopover);
