import type { FormControlOption } from "../../types/design-system";
import DropdownChip from "./DropdownChip";
import RadioButtonGroupDialog from "./RadioButtonGroupDialog";

interface FormatLabelParams {
  items: FormControlOption[];
  label: string;
  value: string | null;
}

const formatLabel = ({ items, label, value }: FormatLabelParams) => {
  if (!value) {
    return label;
  }

  const item = items.find((i) => i.value === value);

  return item?.label ?? value;
};

interface RadioButtonGroupDropdownChipProps<T extends string> {
  disabled?: boolean;
  hasSelectAll?: boolean;
  items: FormControlOption<T>[];
  label: string;
  onChange: (value: T | null) => void;
  value: T | null;
  "data-testid"?: string;
}

const RadioButtonGroupDropdownChip = <T extends string>({
  disabled,
  hasSelectAll,
  items,
  label,
  onChange,
  value,
  "data-testid": dataTestId,
}: RadioButtonGroupDropdownChipProps<T>) => {
  const checked = !!value;

  const formattedLabel = formatLabel({
    label,
    items,
    value,
  });

  return (
    <DropdownChip
      checked={checked}
      disabled={disabled}
      label={formattedLabel}
      data-testid={dataTestId}
    >
      {(state) => (
        <RadioButtonGroupDialog
          hasSelectAll={hasSelectAll}
          items={items}
          onChange={onChange}
          onClose={state.close}
          value={value}
        />
      )}
    </DropdownChip>
  );
};

export default RadioButtonGroupDropdownChip;
