import { useModalOverlay } from "@react-aria/overlays";
import noop from "lodash/noop";
import React, { useRef } from "react";
import {
  type ModalSize,
  StyledOverlay,
  StyledUnderlay,
  type UnderlayVariant,
} from "./styled";

export interface ModalProps {
  children?: React.ReactNode;
  isDismissable?: boolean;
  isKeyboardDismissDisabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  size: ModalSize;
  underlayRef?: React.RefObject<HTMLDivElement>;
  underlayVariant?: UnderlayVariant;
}

const Modal = ({
  children,
  onClose,
  isDismissable,
  isKeyboardDismissDisabled,
  isOpen,
  size,
  underlayRef,
  underlayVariant = "default",
}: ModalProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable, isKeyboardDismissDisabled },
    { close: onClose, isOpen, open: noop, setOpen: noop, toggle: noop },
    overlayRef,
  );

  const onUnderlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <StyledUnderlay
      {...underlayProps}
      onClick={onUnderlayClick}
      ref={underlayRef}
      variant={underlayVariant}
    >
      <StyledOverlay size={size}>
        <div {...modalProps} ref={overlayRef}>
          {children}
        </div>
      </StyledOverlay>
    </StyledUnderlay>
  );
};

export default Modal;
