import { createGlobalStyle } from "styled-components";

const GlobalApplicationStyles = createGlobalStyle`
  html, body {
    height: 100%;
    width: 100%;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

export default GlobalApplicationStyles;
