import { SkeletonTheme as ReactSkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "styled-components";

interface SkeletonThemeProps {
  children?: React.ReactNode;
}

export const SkeletonTheme = ({ children }: SkeletonThemeProps) => {
  const theme = useTheme();
  return (
    <ReactSkeletonTheme
      duration={0.75}
      highlightColor={theme.colors.neutrals.backgroundSkeletonHighlight}
      baseColor={theme.colors.neutrals.backgroundSkeleton}
    >
      {children}
    </ReactSkeletonTheme>
  );
};

export const MediumSkeletonTheme = ({ children }: SkeletonThemeProps) => {
  const theme = useTheme();
  return (
    <ReactSkeletonTheme
      duration={0.75}
      highlightColor={theme.colors.neutrals.backgroundMediumSkeletonHighlight}
      baseColor={theme.colors.neutrals.backgroundMediumSkeleton}
    >
      {children}
    </ReactSkeletonTheme>
  );
};

export const InverseSkeletonTheme = ({ children }: SkeletonThemeProps) => {
  const theme = useTheme();
  return (
    <ReactSkeletonTheme
      duration={0.75}
      highlightColor={theme.colors.neutrals.backgroundInverseSkeletonHighlight}
      baseColor={theme.colors.neutrals.backgroundInverseSkeleton}
    >
      {children}
    </ReactSkeletonTheme>
  );
};

export const SocialSkeletonTheme = ({ children }: SkeletonThemeProps) => {
  const theme = useTheme();
  return (
    <ReactSkeletonTheme
      duration={0.75}
      highlightColor={theme.colors.social.strongBackgroundSkeletonHighlight}
      baseColor={theme.colors.social.strongBackgroundSkeleton}
    >
      {children}
    </ReactSkeletonTheme>
  );
};
