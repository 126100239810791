import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { lightColorPropertyDeclarations } from "../../theme/colorsLight";
import media from "../../theme/media";

const GlobalStyles = createGlobalStyle`
  /* Eric Meyer CSS Reset --------------------------------------------------- */

  ${reset}

  /* Base ------------------------------------------------------------------- */

  body {
    background: ${(p) => p.theme.colors.neutrals.backgroundWeak};
    color: ${(p) => p.theme.colors.neutrals.text};
    font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }

  *, ::before, ::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    text-decoration: none;

    &:focus-visible {
      border-radius: ${(p) => p.theme.borderRadiuses.base}px;
      outline: 2px solid ${(p) => p.theme.colors.neutrals.borderFocus};
    }
  }

  td {
    vertical-align: middle;
  }

  svg {
    display: block;
    flex-shrink: 0;
  }

  strong {
    font-weight: ${(p) => p.theme.fontWeights.semibold};
  }

  /* Color CSS Properties --------------------------------------------------- */

  :root {
    ${lightColorPropertyDeclarations}

    @media ${media.xxl} {
      font-size: 120%;
    }

    --h1-font-size: 1.75rem;
    --h1-line-height: 2.5rem;
    --h1-text-transform: ${(p) => p.theme.fontCases.none};
    --h1-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --h1-font-style: ${(p) => p.theme.fontStyles.normal};
    --h1-font-weight: ${(p) => p.theme.fontWeights.bold};

    --h2-font-size: 1.5rem;
    --h2-line-height: 2rem;
    --h2-text-transform: ${(p) => p.theme.fontCases.none};
    --h2-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --h2-font-style: ${(p) => p.theme.fontStyles.normal};
    --h2-font-weight: ${(p) => p.theme.fontWeights.bold};

    --h3-font-size: 1.25rem;
    --h3-line-height: 1.75rem;
    --h3-text-transform: ${(p) => p.theme.fontCases.none};
    --h3-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --h3-font-style: ${(p) => p.theme.fontStyles.normal};
    --h3-font-weight: ${(p) => p.theme.fontWeights.bold};

    --subtitleLg-font-size: 1.125rem;
    --subtitleLg-line-height: 1.75rem;
    --subtitleLg-text-transform: ${(p) => p.theme.fontCases.none};
    --subtitleLg-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --subtitleLg-font-style: ${(p) => p.theme.fontStyles.normal};
    --subtitleLg-font-weight: ${(p) => p.theme.fontWeights.bold};

    --subtitleMd-font-size: 1rem;
    --subtitleMd-line-height: 1.5rem;
    --subtitleMd-text-transform: ${(p) => p.theme.fontCases.none};
    --subtitleMd-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --subtitleMd-font-style: ${(p) => p.theme.fontStyles.normal};
    --subtitleMd-font-weight: ${(p) => p.theme.fontWeights.bold};

    --subtitleSm-font-size: 0.875rem;
    --subtitleSm-line-height: 1.25rem;
    --subtitleSm-text-transform: ${(p) => p.theme.fontCases.none};
    --subtitleSm-letter-spacing: ${(p) => p.theme.fontTracking.medium};
    --subtitleSm-font-style: ${(p) => p.theme.fontStyles.normal};
    --subtitleSm-font-weight: ${(p) => p.theme.fontWeights.semibold};

    --bodyLg-font-size: 1rem;
    --bodyLg-line-height: 1.5rem;
    --bodyLg-text-transform: ${(p) => p.theme.fontCases.none};
    --bodyLg-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --bodyLg-font-style: ${(p) => p.theme.fontStyles.normal};
    --bodyLg-font-weight: ${(p) => p.theme.fontWeights.regular};

    --bodyLgStrong-font-size: 1rem;
    --bodyLgStrong-line-height: 1.5rem;
    --bodyLgStrong-text-transform: ${(p) => p.theme.fontCases.none};
    --bodyLgStrong-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --bodyLgStrong-font-style: ${(p) => p.theme.fontStyles.normal};
    --bodyLgStrong-font-weight: ${(p) => p.theme.fontWeights.medium};

    --bodyDefault-font-size: 0.875rem;
    --bodyDefault-line-height: 1.25rem;
    --bodyDefault-text-transform: ${(p) => p.theme.fontCases.none};
    --bodyDefault-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --bodyDefault-font-style: ${(p) => p.theme.fontStyles.normal};
    --bodyDefault-font-weight: ${(p) => p.theme.fontWeights.regular};

    --bodyDefaultStrong-font-size: 0.875rem;
    --bodyDefaultStrong-line-height: 1.25rem;
    --bodyDefaultStrong-text-transform: ${(p) => p.theme.fontCases.none};
    --bodyDefaultStrong-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --bodyDefaultStrong-font-style: ${(p) => p.theme.fontStyles.normal};
    --bodyDefaultStrong-font-weight: ${(p) => p.theme.fontWeights.medium};

    --caption-font-size: 0.625rem;
    --caption-line-height: 1rem;
    --caption-text-transform: ${(p) => p.theme.fontCases.none};
    --caption-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --caption-font-style: ${(p) => p.theme.fontStyles.normal};
    --caption-font-weight: ${(p) => p.theme.fontWeights.regular};

    --captionStrong-font-size: 0.625rem;
    --captionStrong-line-height: 1rem;
    --captionStrong-text-transform: ${(p) => p.theme.fontCases.none};
    --captionStrong-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --captionStrong-font-style: ${(p) => p.theme.fontStyles.normal};
    --captionStrong-font-weight: ${(p) => p.theme.fontWeights.medium};

    --eyebrowLg-font-size: 0.8125rem;
    --eyebrowLg-line-height: 1.25rem;
    --eyebrowLg-text-transform: ${(p) => p.theme.fontCases.uppercase};
    --eyebrowLg-letter-spacing: ${(p) => p.theme.fontTracking.wide};
    --eyebrowLg-font-style: ${(p) => p.theme.fontStyles.normal};
    --eyebrowLg-font-weight: ${(p) => p.theme.fontWeights.bold};

    --eyebrowDefault-font-size: 0.75rem;
    --eyebrowDefault-line-height: 1rem;
    --eyebrowDefault-text-transform: ${(p) => p.theme.fontCases.uppercase};
    --eyebrowDefault-letter-spacing: ${(p) => p.theme.fontTracking.wide};
    --eyebrowDefault-font-style: ${(p) => p.theme.fontStyles.normal};
    --eyebrowDefault-font-weight: ${(p) => p.theme.fontWeights.bold};

    --footnote-font-size: 0.75rem;
    --footnote-line-height: 1rem;
    --footnote-text-transform: ${(p) => p.theme.fontCases.none};
    --footnote-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --footnote-font-style: ${(p) => p.theme.fontStyles.normal};
    --footnote-font-weight: ${(p) => p.theme.fontWeights.regular};

    --footnoteStrong-font-size: 0.75rem;
    --footnoteStrong-line-height: 1rem;
    --footnoteStrong-text-transform: ${(p) => p.theme.fontCases.none};
    --footnoteStrong-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --footnoteStrong-font-style: ${(p) => p.theme.fontStyles.normal};
    --footnoteStrong-font-weight: ${(p) => p.theme.fontWeights.medium};

    --groupLabel-font-size: 1rem;
    --groupLabel-line-height: 1.5rem;
    --groupLabel-text-transform: ${(p) => p.theme.fontCases.none};
    --groupLabel-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --groupLabel-font-style: ${(p) => p.theme.fontStyles.normal};
    --groupLabel-font-weight: ${(p) => p.theme.fontWeights.semibold};

    --label-font-size: 0.875rem;
    --label-line-height: 1.25rem;
    --label-text-transform: ${(p) => p.theme.fontCases.none};
    --label-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --label-font-style: ${(p) => p.theme.fontStyles.normal};
    --label-font-weight: ${(p) => p.theme.fontWeights.medium};

    --buttonLg-font-size: 1rem;
    --buttonLg-line-height: 1.5rem;
    --buttonLg-text-transform: ${(p) => p.theme.fontCases.none};
    --buttonLg-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --buttonLg-font-style: ${(p) => p.theme.fontStyles.normal};
    --buttonLg-font-weight: ${(p) => p.theme.fontWeights.semibold};

    --buttonDefault-font-size: 0.875rem;
    --buttonDefault-line-height: 1.25rem;
    --buttonDefault-text-transform: ${(p) => p.theme.fontCases.none};
    --buttonDefault-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --buttonDefault-font-style: ${(p) => p.theme.fontStyles.normal};
    --buttonDefault-font-weight: ${(p) => p.theme.fontWeights.semibold};

    --buttonSm-font-size: 0.75rem;
    --buttonSm-line-height: 1rem;
    --buttonSm-text-transform: ${(p) => p.theme.fontCases.none};
    --buttonSm-letter-spacing: ${(p) => p.theme.fontTracking.normal};
    --buttonSm-font-style: ${(p) => p.theme.fontStyles.normal};
    --buttonSm-font-weight: ${(p) => p.theme.fontWeights.semibold};
  }
`;

export default GlobalStyles;
