import React, { useCallback, useState } from "react";
import { useSwipeable, type SwipeCallback } from "react-swipeable";
import styled, { css } from "styled-components";
import buttonReset from "../../lib/styled/buttonReset";
import { focusStyles, weakStyles } from "../Button/states";
import {
  BOTTOM_SHEET_TRANSFORM_POSITIONS,
  LARGE_SWIPE_DELTA_Y_THRESHOLD,
} from "./constants";
import {
  BottomSheetActionType,
  type BottomSheetPosition,
  type UseBottomSheetResult,
} from "./useBottomSheet";

interface StyledBottomSheetProps {
  position: BottomSheetPosition;
}

const getPositionalStyles = (position: BottomSheetPosition) => {
  if (position === "up") {
    return css`
      overflow: scroll;
      transform: translateY(${BOTTOM_SHEET_TRANSFORM_POSITIONS.up});
    `;
  }

  return css`
    transform: translateY(
      calc(100% - ${BOTTOM_SHEET_TRANSFORM_POSITIONS[position]}px)
    );
    overflow: hidden;
  `;
};

export const StyledBottomSheet = styled.div<StyledBottomSheetProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  transition: transform
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  border-top-left-radius: ${(p) => p.theme.borderRadiuses["4xl"]}px;
  border-top-right-radius: ${(p) => p.theme.borderRadiuses["4xl"]}px;

  ${(p) => getPositionalStyles(p.position)}
`;

const StyledHandle = styled.button`
  ${buttonReset}
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: 100%;
  height: ${(p) => p.theme.borderRadiuses["4xl"]}px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  border-top-left-radius: ${(p) => p.theme.borderRadiuses["4xl"]}px;
  border-top-right-radius: ${(p) => p.theme.borderRadiuses["4xl"]}px;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 1rem;
    display: block;
    height: 5px;
    width: 6rem;
    background-color: ${(p) => p.theme.colors.neutrals.backgroundMediumHover};
    border-radius: ${(p) => p.theme.borderRadiuses.sm}px;
    transform: translateX(-50%);
  }

  &:hover {
    ${weakStyles.background.hover}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

export interface BottomSheetProps extends UseBottomSheetResult {
  children?: React.ReactNode;
  "data-testid"?: string;
  onTransitionEnd?: (position: BottomSheetPosition) => void;
}

const swipeUpSheetPositions: BottomSheetPosition[] = ["docked"];
const swipeDownSheetPositions: BottomSheetPosition[] = ["up", "docked"];

const BottomSheet = ({
  children,
  "data-testid": dataTestId,
  dispatch,
  onTransitionEnd: _onTransitionEnd,
  position,
}: BottomSheetProps) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState<boolean>(true);

  const onSwipedUp = useCallback<SwipeCallback>(() => {
    dispatch({ type: BottomSheetActionType.SWIPE_UP });
  }, [dispatch]);

  const onSwipedDown = useCallback<SwipeCallback>(
    (event) => {
      if (position === "up" && !isScrolledToTop) return;

      if (event.deltaY > LARGE_SWIPE_DELTA_Y_THRESHOLD) {
        dispatch({
          position: "down",
          type: BottomSheetActionType.SET_POSITION,
        });
        return;
      }

      dispatch({ type: BottomSheetActionType.SWIPE_DOWN });
    },
    [dispatch, isScrolledToTop, position],
  );

  // For `preventDefaultMoveTouchEvent` to take effect -- which disables a scroll
  // event and defers the gesture to our swipe handlers -- the relevant swipe
  // handler must not be present on the object at all. Therefore we check whether
  // the gesture should do anything for the current sheet position, and only if
  // so do we apply the object property. If the listeners were always applied,
  // scrolling the sheet contents when in position "up" would not be possible.
  const handlers = useSwipeable({
    ...(swipeUpSheetPositions.includes(position) && { onSwipedUp }),
    ...(swipeDownSheetPositions.includes(position) &&
      isScrolledToTop && { onSwipedDown }),
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  // If the user has opened the bottom sheet, and scrolled down, we don't want a
  // scroll back up (which involves the finger swiping down on touch screens) to
  // trigger the sheet closing. To avoid this, we track the scroll position of
  // the container and store it in state
  const onScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (event.currentTarget.scrollTop === 0 && !isScrolledToTop) {
        setIsScrolledToTop(true);
      } else if (event.currentTarget.scrollTop !== 0 && isScrolledToTop) {
        setIsScrolledToTop(false);
      }
    },
    [isScrolledToTop],
  );

  const onTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
    if (!_onTransitionEnd) return;

    if (event.target === event.currentTarget) {
      _onTransitionEnd(position);
    }
  };

  const onHandleClick = () => {
    dispatch({
      position: "down",
      type: BottomSheetActionType.SET_POSITION,
    });
  };

  return (
    <StyledBottomSheet
      {...handlers}
      data-testid={dataTestId}
      onScroll={onScroll}
      onTransitionEnd={onTransitionEnd}
      position={position}
    >
      <StyledHandle
        aria-label="Close bottom sheet"
        data-testid={dataTestId && `${dataTestId}-handle`}
        onClick={onHandleClick}
        type="button"
      />
      {children}
    </StyledBottomSheet>
  );
};

export default BottomSheet;
