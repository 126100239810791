import styled from "styled-components";
import type { Severity } from "../../types/design-system";
import Alert from "../Alert/Alert";
import Divider from "../Divider/Divider";
import SeverityCardHeader from "./SeverityCardHeader";
import SeverityCardMetric from "./SeverityCardMetric";
import { getSeverityBackgroundColor, getSeverityBorderColor } from "./utils";

interface StyledSeverityCardProps {
  severity?: Severity;
}

const StyledSeverityCard = styled.div<StyledSeverityCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  min-height: 170px;
  border: 1px solid
    ${(p) => getSeverityBorderColor({ severity: p.severity, theme: p.theme })};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  box-shadow: ${(p) => p.theme.boxShadows.cardWeak};
  background-color: ${(p) => p.theme.colors.neutrals.surface};
`;

interface StyledContentProps {
  hasWarning?: boolean;
}

/**
 * Container around the header and body
 */
const StyledContent = styled.div<StyledContentProps>`
  height: 100%;
  padding: ${(p) => (p.hasWarning ? "12px" : "16px")} 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

interface StyledWarningProps {
  severity?: Severity;
}

const StyledWarning = styled.div<StyledWarningProps>`
  padding: 10px 12px;
  background-color: ${(p) =>
    getSeverityBackgroundColor({ severity: p.severity, theme: p.theme })};
  border-bottom: 1px solid
    ${(p) => getSeverityBorderColor({ severity: p.severity, theme: p.theme })};
  border-top-left-radius: ${(p) => p.theme.borderRadiuses.base}px;
  border-top-right-radius: ${(p) => p.theme.borderRadiuses.base}px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${(p) => p.theme.typography.variants.footnoteStrong}
`;

const StyledBody = styled.div`
  display: grid;
  gap: 2px;
`;

const StyledMetrics = styled.div`
  display: grid;
  gap: 12px;
`;

const StyledDescription = styled.p`
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const StyledFooter = styled.div`
  display: grid;
  padding: 0 16px 16px;
  gap: 16px;
`;

const StyledFooterList = styled.div`
  display: grid;
  gap: 4px;
`;

export interface SeverityCardProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  description?: string;
  footer?: React.ReactNode;
  isError?: boolean;
  severity?: Severity;
  supportingMetric?: React.ReactNode;
  supportingIcon?: ReactSVGComponent;
  supportingIconOrientation?: number;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  tooltip?: React.ReactNode;
  variable: string;
  warning?: string;
  gridArea?: string;
}

export type SeverityCardSupportProps = Pick<
  SeverityCardProps,
  "supportingIcon" | "supportingIconOrientation" | "supportingMetric"
>;

const SeverityCard = ({
  children,
  "data-testid": dataTestId,
  description,
  footer,
  isError = false,
  severity,
  subtitle,
  supportingMetric,
  supportingIcon,
  supportingIconOrientation,
  title,
  tooltip,
  variable,
  warning,
  gridArea = "initial",
}: SeverityCardProps) => {
  return (
    <StyledSeverityCard
      data-testid={dataTestId}
      severity={severity}
      style={{
        gridArea,
      }}
    >
      <div>
        {warning && (
          <StyledWarning
            data-testid={`${dataTestId}-header`}
            severity={severity}
            title={warning}
          >
            {warning}
          </StyledWarning>
        )}
        <StyledContent
          data-testid={`${dataTestId}-title`}
          hasWarning={!!warning}
        >
          <SeverityCardHeader severity={severity} tooltip={tooltip}>
            {variable}
          </SeverityCardHeader>
          <StyledBody>
            {isError ? (
              <Alert variant="error" title="Weather data unavailable">
                Unable to provide a forecast for {variable}
              </Alert>
            ) : (
              <>
                <StyledMetrics>
                  <SeverityCardMetric
                    subtitle={subtitle}
                    supportingIcon={supportingIcon}
                    supportingIconOrientation={supportingIconOrientation}
                    supportingMetric={supportingMetric}
                  >
                    {title}
                  </SeverityCardMetric>
                  {children}
                </StyledMetrics>
                {description && (
                  <StyledDescription>{description}</StyledDescription>
                )}
              </>
            )}
          </StyledBody>
        </StyledContent>
      </div>
      {!isError && footer && (
        <StyledFooter>
          <Divider />
          <StyledFooterList data-testid={`${dataTestId}-footer`}>
            {footer}
          </StyledFooterList>
        </StyledFooter>
      )}
    </StyledSeverityCard>
  );
};

export default SeverityCard;
