import {
  useSingleSelectListState,
  type SingleSelectListProps,
} from "@react-stately/list";
import type { Node } from "@react-types/shared";
import styled from "styled-components";
import useIsMinWidth from "../../hooks/useIsMinWidth";
import HeaderLinkTab from "./HeaderLinkTab";
import HeaderLinkTabOverlay from "./HeaderLinkTabOverlay";

export const StyledHeaderLinkTabList = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1 0;
`;

export interface HeaderLinkTabItem {
  href: string;
  id: string;
  title: string;
}

export type HeaderLinkTabRenderFunction<T extends HeaderLinkTabItem> = (
  item: Node<T>,
  isActive: boolean,
) => React.ReactNode;

const defaultRenderTab: HeaderLinkTabRenderFunction<HeaderLinkTabItem> = (
  item,
  isActive,
) => (
  <HeaderLinkTab isActive={isActive} href={item?.value?.href} key={item.key}>
    {item.rendered}
  </HeaderLinkTab>
);

export interface HeaderLinkTabListProps<T extends HeaderLinkTabItem>
  extends SingleSelectListProps<T> {
  "data-testid"?: string;
  isTabActive: (item: T) => boolean;
  renderTab?: HeaderLinkTabRenderFunction<T>;
}

const HeaderLinkTabList = <T extends HeaderLinkTabItem>({
  "data-testid": dataTestId,
  isTabActive,
  renderTab = defaultRenderTab,
  ...props
}: HeaderLinkTabListProps<T>) => {
  const state = useSingleSelectListState(props);
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const activeItem = [...state.collection].find(
    (item) => item.value && isTabActive(item.value),
  );

  const tabs = [...state.collection].map((item) =>
    renderTab(item, item.key === activeItem?.key),
  );

  return (
    <StyledHeaderLinkTabList data-testid={dataTestId}>
      {!isTabletLandscapeAndAbove ? (
        <HeaderLinkTabOverlay
          activeItem={activeItem}
          tabs={tabs}
          data-testid={dataTestId && `${dataTestId}-overlay`}
        />
      ) : (
        tabs
      )}
    </StyledHeaderLinkTabList>
  );
};

export default HeaderLinkTabList;
