import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import InputContainer, {
  commonInputStyles,
  type CommonInputStylesProps,
  type InputContainerProps,
} from "../InputContainer/InputContainer";

export const StyledInput = styled.input<CommonInputStylesProps>`
  ${commonInputStyles}

  &input[type="text"] {
    appearance: none;
  }
`;

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size" | "children"
> &
  Omit<InputContainerProps, "hasClearButton"> & {
    isClearable?: boolean;
  };

const TextInput: ForwardRefRenderFunction<HTMLInputElement, TextInputProps> = (
  {
    autoComplete = "off",
    className,
    "data-testid": dataTestId,
    disabled,
    iconEnd: IconEnd,
    iconStart: IconStart,
    isClearable,
    onClearClick,
    size,
    validationStatus,
    ...htmlProps
  }: TextInputProps,
  ref,
) => {
  return (
    <InputContainer
      className={className}
      data-testid={dataTestId && `${dataTestId}-input-container`}
      disabled={disabled}
      isClearable={isClearable && !!htmlProps.value}
      iconEnd={IconEnd}
      iconStart={IconStart}
      onClearClick={onClearClick}
      size={size}
      validationStatus={validationStatus}
    >
      <StyledInput
        {...htmlProps}
        autoComplete={autoComplete}
        disabled={disabled}
        hasIconEnd={!!IconEnd}
        hasIconStart={!!IconStart}
        ref={ref}
        validationStatus={validationStatus}
        data-testid={dataTestId && `${dataTestId}-input`}
      />
    </InputContainer>
  );
};

export default forwardRef(TextInput);
