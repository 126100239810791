import { useButton } from "@react-aria/button";
import { useOverlayTrigger } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import type { Node } from "@react-types/shared";
import React, { useEffect, useRef } from "react";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";
import useUpdatingRef from "../../hooks/useUpdatingRef";
import { ArrowDropDown } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { focusStyles } from "../Button/states";
import Popover from "../Popover/Popover";
import type { HeaderLinkTabItem } from "./HeaderLinkTabList";
import HeaderLinkTabPopover from "./HeaderLinkTabPopover";

const StyledHeaderLinkTabOverlay = styled.div`
  width: 100%;
`;

const StyledTrigger = styled.button`
  ${buttonReset}
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0.875rem;
  ${(p) => p.theme.typography.variants.buttonDefault}
  background-color: ${(p) => p.theme.colors.neutrals.textInverseDisabled};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.fireStatus.buttonBackgroundHover};
  }

  &:active {
    background-color: ${(p) =>
      p.theme.colors.fireStatus.iconDisabledOnBackground};
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface HeaderLinkTabOverlayProps {
  activeItem?: Node<HeaderLinkTabItem>;
  "data-testid"?: string;
  tabs: React.ReactNode;
}

const HeaderLinkTabOverlay = ({
  activeItem,
  "data-testid": dataTestId,
  tabs,
}: HeaderLinkTabOverlayProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const state = useOverlayTriggerState({});

  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    triggerRef,
  );

  const { buttonProps } = useButton(triggerProps, triggerRef);

  const onCloseRef = useUpdatingRef(state.close);

  const activeItemKey = activeItem?.key;

  useEffect(() => {
    onCloseRef.current();
  }, [activeItemKey, onCloseRef]);

  const { observe, width } = useDimensions();

  return (
    <>
      <StyledHeaderLinkTabOverlay ref={observe}>
        <StyledTrigger
          {...buttonProps}
          data-testid={dataTestId && `${dataTestId}-trigger`}
          ref={triggerRef}
          type="button"
        >
          {activeItem?.rendered ?? "None selected"}
          <ArrowDropDown />
        </StyledTrigger>
      </StyledHeaderLinkTabOverlay>
      <Popover
        {...overlayProps}
        state={state}
        triggerRef={triggerRef}
        width={width}
      >
        <HeaderLinkTabPopover {...overlayProps} data-testid={dataTestId}>
          {tabs}
        </HeaderLinkTabPopover>
      </Popover>
    </>
  );
};

export default HeaderLinkTabOverlay;
