import styled, { css } from "styled-components";
import type { TypographySize } from "../../theme";

export type StatusIndicatorSize = "default" | "eyebrow";

const statusIndicatorTypographySizes: Record<
  StatusIndicatorSize,
  TypographySize
> = {
  default: "bodyDefault",
  eyebrow: "eyebrowDefault",
};

interface StyledStatusIndicatorProps {
  size: StatusIndicatorSize;
}

const StyledStatusIndicator = styled.div<StyledStatusIndicatorProps>`
  display: flex;
  gap: 0.375rem;
  align-items: center;
  ${(p) => p.theme.typography.variants[statusIndicatorTypographySizes[p.size]]}
  ${(p) =>
    p.size === "eyebrow" &&
    css`
      color: ${p.theme.colors.neutrals.textWeak};
    `}

  svg {
    height: ${(p) =>
      p.theme.typography.variants[statusIndicatorTypographySizes[p.size]][
        "line-height"
      ]};
    width: auto;
  }
`;

interface StatusIndicatorProps {
  children?: React.ReactNode;
  "data-testid"?: string;
  icon: ReactSVGComponent | null;
  size?: StatusIndicatorSize;
}

const StatusIndicator = ({
  children,
  "data-testid": dataTestId,
  icon: Icon,
  size = "default",
}: StatusIndicatorProps) => {
  return (
    <StyledStatusIndicator data-testid={dataTestId} size={size}>
      {Icon && <Icon />}
      {children}
    </StyledStatusIndicator>
  );
};

export default StatusIndicator;
