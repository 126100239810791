import { useMergedRefs } from "@kablamo/kerosene-ui";
import { useFocusRing } from "@react-aria/focus";
import { useSwitch } from "@react-aria/switch";
import { mergeProps } from "@react-aria/utils";
import { useToggleState, type ToggleProps } from "@react-stately/toggle";
import { forwardRef, useRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { focusStyles, weakStyles } from "../Button/states";
import StyledHiddenInput from "../StyledHiddenInput/StyledHiddenInput";

type SwitchSize = "sm" | "md";

interface StyledLabelProps {
  size: SwitchSize;
}

const StyledLabel = styled.label<StyledLabelProps>`
  position: relative;
  display: inline-block;
  ${(p) => {
    switch (p.size) {
      case "sm": {
        return css`
          width: 1.75rem;
          height: 1rem;

          --slider-size: 0.75rem;
        `;
      }
      case "md": {
        return css`
          width: 2.25rem;
          height: 1.25rem;

          --slider-size: 1rem;
        `;
      }
    }
  }}
`;

interface StyledSliderProps {
  isDisabled?: boolean;
  isFocusVisible: boolean;
}

const StyledSlider = styled.span<StyledSliderProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundExtraStrong};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  transition: background-color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &::before {
    position: absolute;
    content: "";
    height: var(--slider-size);
    width: var(--slider-size);
    left: 2px;
    bottom: 2px;
    background-color: ${(p) => p.theme.colors.neutrals.background};
    box-shadow: ${(p) => p.theme.boxShadows.sm};
    border-radius: 50%;
    transition: transform
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  ${StyledHiddenInput}:not(:disabled) + &:hover::before {
    ${weakStyles.background.hover}
  }

  ${(p) =>
    p.isFocusVisible &&
    css`
      ${focusStyles("ring")}
    `}

  ${StyledHiddenInput}:checked + & {
    background-color: ${(p) => p.theme.colors.neutrals.borderSelected};
  }

  ${StyledHiddenInput}:disabled + & {
    background-color: ${(p) =>
      p.theme.colors.neutrals.backgroundExtraStrongDisabled};
    cursor: not-allowed;
  }

  ${StyledHiddenInput}:checked:disabled + & {
    background-color: ${(p) =>
      p.theme.colors.neutrals.backgroundSelectedDisabled};
  }

  ${StyledHiddenInput}:checked + &::before {
    transform: translateX(var(--slider-size));
  }
`;

export type SwitchProps = ToggleProps & {
  id?: string;
  size?: SwitchSize;
  "data-testid"?: string;
} & ({ "aria-label": string } | { "aria-labelledby": string });

const Switch: ForwardRefRenderFunction<HTMLInputElement, SwitchProps> = (
  { size = "md", ...props }: SwitchProps,
  ref,
) => {
  const dataTestId = props["data-testid"];
  const inputRef = useRef(null);

  const state = useToggleState(props);
  const { inputProps } = useSwitch(props, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const setRef = useMergedRefs(ref, inputRef);

  return (
    <StyledLabel size={size}>
      <StyledHiddenInput {...mergeProps(inputProps, focusProps)} ref={setRef} />
      <StyledSlider
        isDisabled={props.isDisabled}
        isFocusVisible={isFocusVisible}
        data-testid={dataTestId && `${dataTestId}-slider`}
      />
    </StyledLabel>
  );
};

export default forwardRef(Switch);
