import { css } from "styled-components";
import { weakStyles } from "../Button/states";

export type TabListVariant = "default" | "transparent";

interface TabListStyleProps {
  variant?: TabListVariant;
}

export const tabListStyles = css<TabListStyleProps>`
  display: flex;
  padding: 0.1875rem;
  gap: 0.25rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  ${(p) =>
    p.variant === "transparent"
      ? css`
          padding: 0.125rem 0.125rem 0.375rem;
          background-color: transparent;
        `
      : css`
          ${weakStyles.background.base}
          ${weakStyles.border.base}
        `}
`;
