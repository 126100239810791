import { badgeStyles, type BadgeStyle } from "@app/design-system";
import { useKonamiCode } from "@kablamo/kerosene-ui";
import React, { createContext, useContext } from "react";
import { useBoolean } from "usehooks-ts";
import { boolean, object, string } from "yup";
import DeveloperOptionsModalForm from "./DeveloperOptionsModalForm";

const fireSizePlacements = ["withTime", "withCounts"] as const;
export type ResponseSuggestionFireSizePlacement =
  (typeof fireSizePlacements)[number];

const fireSizeKpiTreatments = ["badge", "icon"] as const;
export type ResponseSuggestionFireSizeKpiTreatment =
  (typeof fireSizeKpiTreatments)[number];
export interface DeveloperOptions {
  options: {
    badgeStyle: BadgeStyle;
    isEnvironmentIndicatorEnabled: boolean;
    isMapRailEnabled: boolean;
    riskResponse: {
      fireSizeKpiTreatment: ResponseSuggestionFireSizeKpiTreatment;
      fireSizePlacement: ResponseSuggestionFireSizePlacement;
      isOverdueEnabled: boolean;
      isExpiredSuggestionsEnabled: boolean;
    };
  };
}

const developerOptionsSchema = object<DeveloperOptions>().shape({
  options: object({
    badgeStyle: string().oneOf(badgeStyles).default("classic"),
    isEnvironmentIndicatorEnabled: boolean().default(true),
    isMapRailEnabled: boolean().default(true),
    riskResponse: object({
      fireSizeKpiTreatment: string()
        .oneOf(fireSizeKpiTreatments)
        .default("icon"),
      fireSizePlacement: string().oneOf(fireSizePlacements).default("withTime"),
      isOverdueEnabled: boolean().default(false),
      isExpiredSuggestionsEnabled: boolean().default(false),
    }),
  }),
});

export const isDeveloperOptions = (
  value: unknown,
): value is DeveloperOptions => {
  try {
    developerOptionsSchema.validateSync(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const defaultDeveloperOptions = developerOptionsSchema.cast({});

export type DeveloperOptionsContextValue = DeveloperOptions;

const DeveloperOptionsContext = createContext<
  DeveloperOptionsContextValue | undefined
>(undefined);

interface DeveloperOptionsProviderProps {
  children?: React.ReactNode;
  isNonProdEnvironment: boolean;
  onChange: (value: DeveloperOptionsContextValue) => void;
  value: DeveloperOptionsContextValue;
}

const DeveloperOptionsProvider = ({
  children,
  isNonProdEnvironment,
  onChange,
  value,
}: DeveloperOptionsProviderProps) => {
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);

  useKonamiCode(["Alt", "d"], () => {
    if (isNonProdEnvironment) {
      open();
    }
  });

  return (
    <DeveloperOptionsContext.Provider value={value}>
      <DeveloperOptionsModalForm
        developerOptions={value}
        isOpen={isOpen}
        onClose={close}
        onSubmit={(values) => {
          onChange({
            options: {
              badgeStyle: values.badgeStyle,
              isEnvironmentIndicatorEnabled:
                values.isEnvironmentIndicatorEnabled,
              isMapRailEnabled: values.isMapRailEnabled,
              riskResponse: {
                fireSizeKpiTreatment: values.riskResponse.fireSizeKpiTreatment,
                fireSizePlacement: values.riskResponse.fireSizePlacement,
                isExpiredSuggestionsEnabled:
                  values.riskResponse.isExpiredSuggestionsEnabled,
                isOverdueEnabled: values.riskResponse.isOverdueEnabled,
              },
            },
          });
          close();
        }}
      />
      {children}
    </DeveloperOptionsContext.Provider>
  );
};

export default DeveloperOptionsProvider;

export const useDeveloperOptions = () => {
  const context = useContext(DeveloperOptionsContext);

  if (!context) {
    throw new Error(
      "useDeveloperOptions must be used inside a DeveloperOptionsProvider",
    );
  }

  return context;
};
