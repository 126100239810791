import styled from "styled-components";
import { weakStyles } from "../Button/states";

const StyledModalHighlightSlot = styled.div`
  display: grid;
  max-width: 48rem;
  gap: 16px;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  ${weakStyles.border.base}
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  box-shadow: ${(p) => p.theme.boxShadows.cardExtraWeak};
  padding: 0.75rem;
`;

interface ModalHighlightSlotProps {
  children: React.ReactNode;
  "data-testid"?: string;
}

const ModalHighlightSlot = ({
  children,
  "data-testid": dataTestId,
}: ModalHighlightSlotProps) => {
  return (
    <StyledModalHighlightSlot data-testid={dataTestId}>
      {children}
    </StyledModalHighlightSlot>
  );
};

export default ModalHighlightSlot;
