import { useDialog } from "@react-aria/dialog";
import { useRef, useState } from "react";
import styled from "styled-components";
import type { FormControlOption } from "../../types/design-system";
import { Tab, TabList, TabPanel, Tabs } from "../Tabs/Tabs";
import CheckboxGroupDialogList from "./CheckboxGroupDialogList";
import DropdownChipDialogFooter from "./DropdownChipDialogFooter";

const StyledCheckboxGroupDialog = styled.div`
  outline: none;
`;

const StyledTabList = styled(TabList)`
  margin: -0.25rem -0.25rem 0;
`;

const StyledBody = styled.div`
  position: relative;
  padding: 0.75rem 0.75rem 0;
`;

const StyledList = styled.div`
  display: grid;
  padding: 0.75rem 0 0;
  gap: 0.75rem;
`;

export type CheckboxOptionSections = {
  [key: string]: {
    label: string;
    items: FormControlOption<string>[];
  };
};

export type ValuesFromSections<S extends CheckboxOptionSections> = {
  [K in keyof S]: S[K]["items"][number]["value"][];
};

type TabbedCheckboxGroupDialogProps<S extends CheckboxOptionSections> = {
  columns?: number;
  defaultTabIndex?: number;
  sections: S;
  onChange: (value: ValuesFromSections<S>) => void;
  onClose: () => void;
  onTabSelect: (index: number) => void;
  value: ValuesFromSections<S>;
};

const TabbedCheckboxGroupDialog = <const S extends CheckboxOptionSections>({
  columns,
  defaultTabIndex,
  onChange: _onChange,
  onClose,
  onTabSelect,
  sections,
  value: _value,
}: TabbedCheckboxGroupDialogProps<S>) => {
  const ref = useRef<HTMLDivElement>(null);

  const { dialogProps } = useDialog({}, ref);

  const [value, setValue] = useState<ValuesFromSections<S>>(_value);

  const onCancel = () => {
    onClose();
  };

  const onApply = () => {
    onClose();
    _onChange(value);
  };

  return (
    <StyledCheckboxGroupDialog {...dialogProps} ref={ref}>
      <StyledBody>
        <Tabs defaultIndex={defaultTabIndex} onSelect={onTabSelect}>
          <StyledTabList>
            {Object.entries(sections).map(([key, section]) => (
              <Tab flex key={key}>
                {section.label}
              </Tab>
            ))}
          </StyledTabList>
          {Object.entries(sections).map(([key, section]) => {
            return (
              <TabPanel key={key}>
                <StyledList>
                  <CheckboxGroupDialogList
                    columns={columns}
                    items={section.items}
                    key={key}
                    onChange={(newValue) => {
                      setValue((prev) => ({
                        ...prev,
                        [key]: newValue,
                      }));
                    }}
                    value={value[key]}
                  />
                </StyledList>
              </TabPanel>
            );
          })}
        </Tabs>
      </StyledBody>
      <DropdownChipDialogFooter onApply={onApply} onCancel={onCancel} />
    </StyledCheckboxGroupDialog>
  );
};

export default TabbedCheckboxGroupDialog;
