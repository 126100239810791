import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import {
  commonInputStyles,
  type CommonInputStylesProps,
  type ValidationStatus,
} from "../InputContainer/InputContainer";

const StyledTextArea = styled.textarea<CommonInputStylesProps>`
  ${commonInputStyles}
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-family: inherit;
  ${(p) => p.theme.typography.variants.bodyDefault}
  resize: none;
`;

export type TextAreaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    "data-testid"?: string;
    validationStatus?: ValidationStatus;
  };

const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  { "data-testid": dataTestId, validationStatus, ...htmlProps }: TextAreaProps,
  ref,
) => {
  return (
    <StyledTextArea
      {...htmlProps}
      hasIconEnd={false}
      hasIconStart={false}
      ref={ref}
      validationStatus={validationStatus}
      data-testid={dataTestId}
    />
  );
};

export default forwardRef(TextArea);
