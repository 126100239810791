import styled, { css } from "styled-components";
import type { Size } from "../../types/design-system";
import Skeleton from "../Skeleton/Skeleton";
import type { ButtonSize } from "./styled";

type SkeletonButtonSize = Extract<Size, "sm" | "md">;

const sizeStyles: Record<SkeletonButtonSize, ReturnType<typeof css>> = {
  sm: css`
    font-size: 2rem;
  `,
  md: css`
    font-size: 2.5rem;
  `,
};

interface StyledSkeletonButtonProps {
  size: SkeletonButtonSize;
}

const StyledSkeletonButton = styled.div<StyledSkeletonButtonProps>`
  ${(p) => sizeStyles[p.size]}
`;

interface SkeletonButtonProps {
  "data-testid"?: string;
  size?: ButtonSize;
  width?: number;
}

const SkeletonButton = ({
  "data-testid": dataTestId,
  size = "md",
  width,
}: SkeletonButtonProps) => {
  return (
    <StyledSkeletonButton data-testid={dataTestId} size={size}>
      <Skeleton width={width} />
    </StyledSkeletonButton>
  );
};

export default SkeletonButton;
