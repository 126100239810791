import styled from "styled-components";
import { Info } from "../../icons";
import type { Severity } from "../../types/design-system";
import SeverityIcon from "../SeverityIcon/SeverityIcon";
import { Tooltip } from "../Tooltip/Tooltip";
import TypographyIconWrapper from "../TypographyIconWrapper/TypographyIconWrapper";

const StyledSeverityCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
`;

const StyledSeverityCardVariable = styled.div`
  display: flex;
  gap: 0.25rem;
  ${(p) => p.theme.typography.variants.eyebrowDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

interface SeverityCardVariableProps {
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
}

/**
 * Renders an eyebrow label optionally wrapped with tooltip. If a tooltip is
 * provided, an info icon will also be displayed as hover affordance.
 */
export const SeverityCardVariable = ({
  children,
  tooltip,
}: SeverityCardVariableProps) => {
  if (tooltip) {
    return (
      <Tooltip message={tooltip}>
        <StyledSeverityCardVariable>
          {children}
          <TypographyIconWrapper
            data-testid="info-icon"
            icon={Info}
            size="eyebrowDefault"
            variant="disabled"
          />
        </StyledSeverityCardVariable>
      </Tooltip>
    );
  }

  return <StyledSeverityCardVariable>{children}</StyledSeverityCardVariable>;
};

export interface SeverityCardHeaderProps {
  children?: React.ReactNode;
  severity?: Severity;
  tooltip?: React.ReactNode;
}

const SeverityCardHeader = ({
  children,
  severity,
  tooltip,
}: SeverityCardHeaderProps) => {
  return (
    <StyledSeverityCardHeader>
      <SeverityCardVariable tooltip={tooltip}>{children}</SeverityCardVariable>
      {severity && (
        <SeverityIcon
          data-testid="variable-severity-icon"
          severity={severity}
          size="sm"
        />
      )}
    </StyledSeverityCardHeader>
  );
};

export default SeverityCardHeader;
