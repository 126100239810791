import { css } from "styled-components";
import type { Breakpoint } from "./constants";

const media: Record<Breakpoint, ReturnType<typeof css>> = {
  sm: css`
    (min-width: ${(p) => p.theme.breakpoints.sm}px)
  `,
  md: css`
    (min-width: ${(p) => p.theme.breakpoints.md}px)
  `,
  lg: css`
    (min-width: ${(p) => p.theme.breakpoints.lg}px)
  `,
  xl: css`
    (min-width: ${(p) => p.theme.breakpoints.xl}px)
  `,
  xxl: css`
    (min-width: ${(p) =>
      p.theme.isLeanBack ? p.theme.breakpoints.lg : 9999}px)
  `,
};

export default media;
