import { mergeRefs } from "@kablamo/kerosene-ui";
import { FocusScope } from "@react-aria/focus";
import { OverlayContainer, useOverlayPosition } from "@react-aria/overlays";
import type { Placement } from "@react-types/overlays";
import { useCombobox, useMultipleSelection } from "downshift";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import some from "lodash/some";
import React, {
  forwardRef,
  type ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from "react";
import useDimensions from "react-cool-dimensions";
import type { FieldError as HookFormFieldError } from "react-hook-form";
import { CSSTransition } from "react-transition-group";
import { useTheme } from "../../../theme";
import type { FormControlOption } from "../../../types/design-system";
import RemovableChip from "../../Chip/RemovableChip";
import Field from "../../Field/Field";
import type { ValidationStatus } from "../../InputContainer/InputContainer";
import TextInput from "../../TextInput/TextInput";
import type { ComboboxOptionFilter } from "../Combobox/Combobox";
import {
  DropdownOptionItem,
  defaultRenderOption,
  type RenderOptionProps,
} from "../DropdownOptionItem";
import { StyledDropdown, StyledMenu, StyledOptionChipGroup } from "../styled";

export interface MultiComboboxProps {
  autoFocus?: boolean;
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  error?: HookFormFieldError | string;
  filterOptions?: ComboboxOptionFilter;
  icon?: ReactSVGComponent;
  id?: string;
  isLoading?: boolean;
  label: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange?: (value: FormControlOption[]) => void;
  options: FormControlOption[];
  placeholder?: string;
  placement?: Placement;
  renderOption?: (props: RenderOptionProps) => React.ReactNode;
  validationStatus?: ValidationStatus;
  value?: FormControlOption[];
}

const defaultFilterOptions = (
  options: FormControlOption[],
  inputValue: string,
) => {
  return options.filter((option) =>
    option.label.toLowerCase().includes(inputValue),
  );
};

const MultiCombobox: ForwardRefRenderFunction<
  HTMLInputElement,
  MultiComboboxProps
> = (
  {
    autoFocus,
    constrain,
    "data-testid": dataTestId,
    disabled,
    error,
    filterOptions = defaultFilterOptions,
    icon,
    id,
    isLoading,
    label,
    onBlur,
    onChange,
    options,
    placeholder,
    placement = "bottom left",
    renderOption = defaultRenderOption,
    validationStatus,
    value = [],
  }: MultiComboboxProps,
  _ref,
) => {
  const theme = useTheme();

  const targetRef = useRef<HTMLInputElement>(null);
  const ref = mergeRefs(_ref, targetRef);

  const overlayRef = useRef<HTMLUListElement>(null);

  const [inputValue, setInputValue] = useState("");

  const items = filterOptions(options, inputValue?.toLowerCase() ?? "");
  const selectedItems = value.map((item) => item);
  const filteredItems = differenceWith(items, selectedItems, isEqual);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
  } = useMultipleSelection({
    selectedItems: value,
    onStateChange: ({ selectedItems: newSelectedItems, type }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (!newSelectedItems) return;
          onChange?.(newSelectedItems);
          break;
        default:
          break;
      }
    },
    onSelectedItemsChange: ({ selectedItems: newSelectedItems }) => {
      if (!newSelectedItems) return;
      setInputValue("");
      onChange?.(newSelectedItems);
    },
  });

  const itemToString = (item: FormControlOption | null) =>
    item ? item.label : "";

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    id,
    items: filteredItems,
    itemToString,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem && {
              isOpen: true,
              highlightedIndex: 0,
            }),
          };
        case useCombobox.stateChangeTypes.ItemClick: {
          const newSelectedItem = changes.selectedItem;
          if (newSelectedItem) {
            if (!some(value, newSelectedItem)) {
              addSelectedItem(newSelectedItem);
            } else {
              removeSelectedItem(newSelectedItem);
            }
          }
          return {
            ...changes,
            isOpen: true,
          };
        }
        default:
          return changes;
      }
    },
    onStateChange: ({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            if (!some(value, newSelectedItem)) {
              addSelectedItem(newSelectedItem);
            } else {
              removeSelectedItem(newSelectedItem);
            }
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue ?? "");
          break;
        default:
          break;
      }
    },
  });

  const { overlayProps: positionProps, updatePosition } = useOverlayPosition({
    containerPadding: 24,
    isOpen,
    offset: 8,
    overlayRef,
    placement,
    targetRef,
    shouldFlip: false,
  });

  useEffect(() => {
    updatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length ? value : null, updatePosition]);

  let renderedItems: React.ReactNode;
  if (isLoading) {
    renderedItems = (
      <DropdownOptionItem
        disabled
        focused={false}
        hasSelection={false}
        selected={false}
      >
        Loading options...
      </DropdownOptionItem>
    );
  } else if (filteredItems.length) {
    renderedItems = filteredItems.map((option, index) => (
      <React.Fragment key={`${option.value}`}>
        {renderOption({
          ...(dataTestId && {
            "data-testid": `${dataTestId}-${option.value}`,
          }),
          disabled: !!option.disabled,
          focused: highlightedIndex === index,
          hasSelection: !!selectedItems.length,
          itemProps: getItemProps({
            item: option,
            index,
          }),
          option,
          selected: value.some((v) => option.value === v.value),
        })}
      </React.Fragment>
    ));
  } else {
    renderedItems = (
      <DropdownOptionItem
        disabled
        focused={false}
        hasSelection={false}
        selected={false}
      >
        No options
      </DropdownOptionItem>
    );
  }

  const { observe: dropdownRef, width } = useDimensions();

  return (
    <Field
      {...getLabelProps()}
      constrain={constrain}
      error={error}
      label={label}
    >
      {selectedItems.length > 0 && (
        <StyledOptionChipGroup>
          {value.map((selectedItem, index) => {
            return (
              <RemovableChip
                {...getSelectedItemProps({ index, selectedItem })}
                data-testid={
                  dataTestId && `${dataTestId}-${selectedItem.value}-chip`
                }
                disabled={disabled}
                key={selectedItem.value}
                onRemove={(event) => {
                  event.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
              >
                {selectedItem.label}
              </RemovableChip>
            );
          })}
        </StyledOptionChipGroup>
      )}

      <StyledDropdown
        isOpen={isOpen}
        data-testid={dataTestId}
        ref={dropdownRef}
      >
        <TextInput
          autoFocus={autoFocus}
          disabled={disabled}
          iconStart={icon}
          placeholder={placeholder}
          validationStatus={validationStatus}
          data-testid={dataTestId && `${dataTestId}-text-input`}
          {...getInputProps(
            getDropdownProps({ preventKeyAction: isOpen, ref }),
          )}
        />
        <OverlayContainer>
          <FocusScope>
            <CSSTransition
              in={isOpen}
              nodeRef={overlayRef}
              timeout={theme.anim.duration.sm}
            >
              <StyledMenu
                {...positionProps}
                {...getMenuProps({ onBlur, ref: overlayRef })}
                aria-hidden={!isOpen}
                data-testid={dataTestId && `${dataTestId}-menu`}
                isOpen={isOpen}
                width={width}
              >
                {renderedItems}
              </StyledMenu>
            </CSSTransition>
          </FocusScope>
        </OverlayContainer>
      </StyledDropdown>
    </Field>
  );
};

export default forwardRef(MultiCombobox);
