import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import StaticIconWrapper from "../StaticIconWrapper/StaticIconWrapper";

interface StyledNavDrawerMenuItemProps {
  isActive?: boolean;
  isDisabled?: boolean;
}

const activeStyles = css`
  background-color: ${(p) => p.theme.colors.neutrals.textInverseDisabled};

  &:hover {
    background-color: ${(p) => p.theme.colors.neutrals.textInverseDisabled};
  }
`;

const StyledNavDrawerMenuItem = styled.a<StyledNavDrawerMenuItemProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  ${(p) => p.theme.typography.variants.subtitleMd}
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
  border-radius: ${(p) => p.theme.borderRadiuses.md}px;

  ${(p) =>
    p.isDisabled
      ? css`
          cursor: not-allowed;
          color: ${p.theme.colors.neutrals.textInverseDisabled};
          background-color: ${p.theme.colors.fireStatus.buttonBackgroundHover};
        `
      : css`
          &:hover {
            background-color: ${p.theme.colors.fireStatus
              .buttonBackgroundHover};
          }

          &:active {
            background-color: ${p.theme.colors.fireStatus
              .iconDisabledOnBackground};
          }

          &:focus-visible {
            outline: 2px solid
              ${p.theme.colors.fireStatus.borderOnBackgroundActive};
            outline-offset: 2px;
          }
        `}

  ${(p) => p.isActive && activeStyles}
`;

interface NavDrawerItemProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  "data-testid"?: string;
  icon?: ReactSVGComponent;
  isActive?: boolean;
  isDisabled?: boolean;
}

const NavDrawerMenuItem: ForwardRefRenderFunction<
  HTMLAnchorElement,
  NavDrawerItemProps
> = (
  {
    children,
    "data-testid": dataTestId,
    icon,
    isActive,
    isDisabled,
    onClick,
    ...props
  }: NavDrawerItemProps,
  ref,
) => {
  return (
    <StyledNavDrawerMenuItem
      {...props}
      data-testid={dataTestId}
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={onClick}
      ref={ref}
    >
      {icon && <StaticIconWrapper icon={icon} size="sm" />}
      {children}
    </StyledNavDrawerMenuItem>
  );
};

export default forwardRef(NavDrawerMenuItem);
