import styled from "styled-components";

const StyledSidebar = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  border-right: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  width: 20rem;
`;

interface SidebarProps {
  children?: React.ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  return <StyledSidebar>{children}</StyledSidebar>;
};

export default Sidebar;
