import styled from "styled-components";
import { AttachFile } from "../../icons";
import buttonReset from "../../lib/styled/buttonReset";
import { focusStyles, mediumStyles } from "../Button/states";
import Field from "../Field/Field";
import FileDisplay, { StyledFileDisplay } from "./FileDisplay";

const StyledButtonFileDisplay = styled.button`
  ${buttonReset}
  max-width: 100%;
  overflow: hidden;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  &:hover ${StyledFileDisplay} {
    ${mediumStyles.background.hover}
  }

  &:active ${StyledFileDisplay} {
    ${mediumStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

interface ButtonFileDisplayProps {
  constrain?: boolean;
  "data-testid"?: string;
  disabled?: boolean;
  filename: string;
  icon?: ReactSVGComponent;
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ButtonFileDisplay = ({
  constrain,
  "data-testid": dataTestId,
  disabled,
  filename,
  icon = AttachFile,
  label,
  onClick,
}: ButtonFileDisplayProps) => {
  return (
    <Field constrain={constrain} label={label}>
      <StyledButtonFileDisplay
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
        title={filename}
        type="button"
      >
        <FileDisplay icon={icon}>{filename}</FileDisplay>
      </StyledButtonFileDisplay>
    </Field>
  );
};

export default ButtonFileDisplay;
